import React from "react";
import AdvanceSearch from "../pages/sub/advanceSearch/AdvanceSearch";
import { MdSearch } from "react-icons/md";
import { IMAGES } from "../stores";
import { FiClock } from "react-icons/fi";
import { RiMapPinLine } from "react-icons/ri";
import { BsStarFill, BsStar } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Candidates = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="font-Helvetica  bg-slate-50">
        <div className="font-Helvetica  relative container mx-auto w-full lg:px-20 md:px-10 px-0 pt-28">
          <div className="font-Helvetica  lg:flex md:flex lg:items-center md:items-center md:space-x-2 bg-white lg:space-x-0 lg:space-y-0 md:space-y-0 space-y-2 justify-around border-b p-2 ">
            <div className="font-Helvetica  ">
              <p className="font-Helvetica  font-QuickStand font-semibold text-legavacBlue lg:text-3xl md:text-2xl text-2xl text-start">
                Candidates
              </p>
              <p className="font-Helvetica  text-slate-400 text-xs text-center">
                you can search your Candidates here ...!!
              </p>
            </div>

            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-3 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-2 pl-10 rounded border border-legavacYellow focus:border-legavacYellow focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica pt-5 pb-10">
            <div className="font-Helvetica  grid lg:grid-cols-8 gap-5 items-start">
              <div className="font-Helvetica  lg:block md:hidden hidden col-span-2 w-full bg-white border shadow shadow-gray-200 px-5">
                <AdvanceSearch />
              </div>
              <div className="font-Helvetica  col-span-6">
                {/* <AdvanceJobSection /> */}
                <div className="grid grid-cols-3 gap-2">
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 15 Days
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 1 Month
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 1 Week
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 1 Year
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 2 Weeks
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm p-5">
                    <div className="flex justify-center w-full">
                      <div className="">
                        <img
                          src={IMAGES.Profile}
                          alt=""
                          className="w-28 h-28 border p-1 rounded-full drop-shadow-lg flex justify-center mx-auto"
                        />
                        <div className="py-5 grid place-content-center">
                          <p className="text-red-600 font-medium text-center uppercase text-lg mb-1">
                            Dipak Patil
                          </p>
                          <p className="font-medium text-center font-semibold text-lg">
                            CAE Engineer
                          </p>
                          <p className="text-gray-600 text-sm text-center font-semibold">
                            Experience: 2 Year
                          </p>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Availability : Immediately
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-3">
                            <div className="mr-2">
                              <RiMapPinLine
                                size={15}
                                className="text-gray-600"
                              />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Pune, Maharashtra
                            </p>
                          </div>
                          <div className="flex space-x-1 justify-center">
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStarFill
                              size={15}
                              className="text-legavacYellow"
                            />
                            <BsStar size={15} className="text-legavacYellow" />
                          </div>
                          <div>
                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Hourly
                            </p>

                            <p className="text-center font-medium text-sm">
                              ₹ 1000/- Monthly
                            </p>
                          </div>
                          <div className="flex justify-center items-center mb-1">
                            <div className="mr-2">
                              <FiClock size={15} className="text-gray-600" />
                            </div>
                            <p className="text-gray-600 font-medium text-center text-sm">
                              Available For : 15 Days
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="bg-legavacBlue py-2 w-full rounded-full text-white font-semibold"
                            onClick={() => navigate("/app/candidates_details")}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Candidates;
