import React from "react";
import { IMAGES } from "../../../stores";

function RecruitingWorkflow() {
  return (
    <>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-0 relative lg:pt-28 pt-20">
        <div className="font-Helvetica  lg:mb-28 md:mb-28 mb-24">
          <p className="font-Helvetica  text-3xl  font-QuickStand font-bold text-center pb-3">
            {" "}
            Recruitment Platform{" "}
          </p>

          <p className="font-Helvetica  text-xl  font-QuickStand font-bold text-center pb-3">
            Ensuring All Our Clients e on the Right Side of the Law
          </p>

          <hr />
        </div>
        <div className="font-Helvetica   lg:bg-slate-100 px-5 pb-5 pt-24">
          <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 place-items-start gap-10">
            <div className="font-Helvetica  grid place-items-center relative ">
              <div className="font-Helvetica   absolute lg:-top-40 md:-top-40 -top-32 p-2.5 rounded-full border-2 border-legavacBlue">
                <div className="font-Helvetica  border border-[#001ba1]  w-24 h-24 z-20 bg-[#001ba1] rounded-full p-4 ">
                  <img
                    src={IMAGES.managementRecruit}
                    alt="managemnet recruiter"
                  />
                </div>
              </div>
              <div className="font-Helvetica  text-center text-sm ">
                <p className="font-Helvetica">
                  Dedicated compliance team to effectively handle
                  client-specific contract requirements.
                </p>
              </div>
            </div>
            <div className="font-Helvetica  grid place-items-center relative lg:pt-0 md:pt-0 pt-28">
              <div className="font-Helvetica   absolute   lg:-top-40  md:-top-40 -top-5 p-2.5 rounded-full border-2 border-[#eb8a00]">
                <div className="font-Helvetica  border border-[#eb8a00]  w-24 h-24 z-20 bg-[#eb8a00] rounded-full p-4 ">
                  <img src={IMAGES.planning} alt="managemnet recruiter" />
                </div>
              </div>
              <div className="font-Helvetica  text-center text-sm">
                <p className="font-Helvetica">
                  Workflow-based applications to support Payroll and Employee
                  Benefits
                </p>
              </div>
            </div>
            <div className="font-Helvetica  grid place-items-center relative lg:pt-0 md:pt-0 pt-28">
              {" "}
              <div className="font-Helvetica   absolute lg:-top-40 md:-top-40 -top-5 p-2.5 rounded-full border-2 border-legavacBlue">
                <div className="font-Helvetica  border border-[#001ba1]  w-24 h-24 z-20 bg-[#001ba1] rounded-full p-4 ">
                  <img src={IMAGES.legaldocument} alt="managemnet recruiter" />
                </div>
              </div>
              <div className="font-Helvetica  text-center text-sm">
                <p className="font-Helvetica">
                  Compliant with the existing labor & compliance laws as
                  applicable across states Minimum Wages, Contract Labour Act
                  (Regulation & Abolition), Labour Welfare Fund, Workmen
                  Compensation, Gratuity Act, Maternity, Equal Remuneration,
                  Provident Fund, Employee State Insurance, Professional and
                  Income Taxes
                </p>
              </div>
            </div>
            <div className="font-Helvetica  grid place-items-center relative lg:pt-0 md:pt-0 pt-28">
              {" "}
              <div className="font-Helvetica   absolute lg:-top-40 md:-top-40 -top-5 p-2.5 rounded-full border-2 border-[#eb8a00]">
                <div className="font-Helvetica  border border-[#eb8a00]  w-24 h-24 z-20 bg-[#eb8a00] rounded-full p-4 ">
                  <img src={IMAGES.checkList} alt="managemnet recruiter" />
                </div>
              </div>
              <div className="font-Helvetica  text-center text-sm">
                <p className="font-Helvetica">
                  Consistent in attaining Statutory Audit rating of above 95%
                </p>
              </div>
            </div>
            <div className="font-Helvetica  grid place-items-center relative lg:pt-0 md:pt-0 pt-28">
              {" "}
              <div className="font-Helvetica   absolute lg:-top-40 md:-top-40 -top-5 p-2.5 rounded-full border-2 border-legavacBlue">
                <div className="font-Helvetica  border border-[#001ba1]  w-24 h-24 z-20 bg-[#001ba1] rounded-full p-4 ">
                  <img src={IMAGES.qualityControl} alt="managemnet recruiter" />
                </div>
              </div>
              <div className="font-Helvetica  text-center text-sm">
                <p className="font-Helvetica">
                  Participating in periodic inspections conducted by respective
                  Government Departments
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitingWorkflow;
