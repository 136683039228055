import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateEducationDetails,
  getInstituteName,
  getMasterEducation,
  saveCandidateEducation,
} from "../../../../config/api";
import Toster from "../../../../stores/Toster";

function EducationDetails() {
  const [education, setEducation] = useState([]);
  const [institute, setInstitute] = useState([]);
 
  const [qualification, setQualification] = useState([]);
  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    qualification: "",
    institute: "",
    passingYear: "",
    percentage: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const funShow = () => {
    setShow(true);
  };

  useEffect(() => {
   
    GetMasterEducation(0);
    GetInstituteName(0);
    GetCandidateEducationDetails(localStorage.getItem("userId"));
  }, [education.id]);

  const GetMasterEducation = async (id) => {
    await getMasterEducation(id).then((res) => {
      console.log("getMasterEducation",res);
     setQualification(res.data)
    });
  };

  const GetInstituteName = async (id) => {
    await getInstituteName(id).then((res) => {
      console.log("GetInstituteName",res);
      setInstitute(res.data)
    });
  };

  const GetCandidateEducationDetails = async (id) => {
    await getCandidateEducationDetails(id).then((res) => {
      console.log(res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(false);
        setEducation(res.data);
      } else {
        setHide(true);
        setEducation(res.data);
      }
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.qualification) {
      IsValid = false;
      errors["qualification"] = "required";
    } else {
      errors["qualification"] = "";
    }
    if (!state.institute) {
      IsValid = false;
      errors["institute"] = "required";
    } else {
      errors["institute"] = "";
    }
    if (!state.passingYear) {
      IsValid = false;
      errors["passingYear"] = "required";
    } else {
      errors["passingYear"] = "";
    }
    if (!state.percentage) {
      IsValid = false;
      errors["percentage"] = "required";
    } else {
      errors["percentage"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const [list, setList] = useState([]); //toaster
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "danger") {
      ToastProperties = {
        id: list.length + 1,
        title: "Error",
        description: "Please enter all fields",
        icon: "MdOutlineErrorOutline",
        backgroundColor: "#fef2f2",
        borderColor: "#dc2626",
        color: "#dc2626",
      };
      setList([...list, ToastProperties]);
    }
  };
  const handleSubmit = async () => {
    debugger;
    if (validate()) {
      // setEducation(state);
      await saveCandidateEducation(state)
        .then((res) => {
          console.log("candidate edu--->", res);
          GetCandidateEducationDetails(localStorage.getItem("userId"));
          setShow(false);
          alert("done");
          setState({
            id: "0",
            CandidateId: state.CandidateId,
            qualification: "",
            institute: "",
            passingYear: "",
            percentage: "",
            CreatedBy: state.CreatedBy,
          });
        })
        .catch((error) => {
          console.log("edu errror->", error);
        });
    } else {
      showToaster("danger");
    }
  };
  // CandidateEducation

  const handleDelete = async (id) => {
    debugger;
    await deleteRecord({
      tableName: "CandidateEducation",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        GetCandidateEducationDetails(localStorage.getItem("userId"));
        alert("record deleted");
      }
    });
  };

  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Education Details
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Qualification:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    name="qualification"
                    id="qualification"
                    onChange={handleChange}
                    value={state.qualification}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option>-- Select Qualification --</option>
                    {qualification?.map((d, idx) => (
                      <option key={idx} className="font-Helvetica  text-sm">
                        {d.education}
                      </option>
                    ))}
                  </select>
                  {state.errors ? (
                    <p className="text-sm text-red-600">
                      {state.errors.qualification}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Institute:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    name="institute"
                    id="institute"
                    onChange={handleChange}
                    value={state.institute}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option>-- Select Institute --</option>
                    {institute?.map((i, idx) => (
                      <option key={idx} className="font-Helvetica  text-sm">
                        {i.instituteName}
                      </option>
                    ))}
                  </select>
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.institute}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Year of Passing:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="passingYear"
                    id="passingYear"
                    onChange={handleChange}
                    value={state.passingYear}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.passingYear}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Percentage / CGPA:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="percentage"
                    id="percentage"
                    onChange={handleChange}
                    value={state.percentage}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.percentage}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {education &&
              education?.map((e, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div>
                        <div className="font-Helvetica  flex justify-between">
                          <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                            {e.qualification}
                          </p>
                        </div>
                        <p className="font-Helvetica  text-sm">{e.institute}</p>
                        <p className="font-Helvetica  text-sm text-slate-500">
                          {e.passingYear}
                        </p>
                        <p className="font-Helvetica  text-sm text-slate-500">
                          {e.percentage}
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          size={20}
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          onClick={() => {
                            setShow(true);
                            const editData = education.filter(
                              (data) => data.id === e.id
                            );
                            console.log(editData[0]);
                            setState(editData[0]);
                          }}
                        />
                        <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                          onClick={() => {
                            const DeleteData = education.filter(
                              (data) => data.id === e.id
                            );
                            handleDelete(DeleteData[0].id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
      <Toster toastList={list} setList={setList} />
    </>
  );
}

export default EducationDetails;
