import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCandidate, updateCandidateDetails } from "../../../config/api";

function MyProfile() {
  const navigate = useNavigate();

  const[locatstorageData,setLocatstorageData] = useState(localStorage.getItem("userId"))

 
  // const data = JSON.parse(localStorage.getItem("userState"));

  // console.log(data);
  const [stateData, setStateData] = useState({
    id: locatstorageData,
    city: "",
    country: "",
    description: "",
    district: "",
    dob: "",
    email: "",
    firstName: "Dipak ",
    fullAddress: "pune",
    lastName: "Patil",
    mobileNumber: "",
    password: "",
    postalPin: "",
    role: "candidate",
    state: "",
    whatsppAlert: "yes",
    createdBy: locatstorageData,
    errors: [],
  });

  // const [stateDataEdit, setStateDataEdit] = useState({
  //   id: localStorage.getItem("userId"),
  //   city: "",
  //   country: "",
  //   description: "",
  //   district: "",
  //   dob: "",
  //   email: "",
  //   firstName: "",
  //   fullAddress: "",
  //   lastName: "",
  //   mobileNumber: "",
  //   password: "",
  //   postalPin: "",
  //   role: "candidate",
  //   state: "",
  //   whatsppAlert: "yes",
  //   createdBy: localStorage.getItem("userId"),
  //   errors: [],
  // });

  const [showInfo, setShowInfo] = useState(true); // information in grid
  const [showForm, setShowForm] = useState(false); // to edit info

  useEffect(() => {
    GetCandidateDetails(localStorage.getItem("userId"));
  }, []);

  const GetCandidateDetails = async (id) => {
    debugger;
    await getCandidate(id).then((res) => {
      console.log("GetCandidateDetails", res.data[0]);
      setStateData(res.data[0]);
      //setStateDataEdit(res.data[0]);
    });
  };

  useEffect(() => {
    console.log("stataedit data", stateData);
  }, [stateData]);
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    //const regex_email = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regex_email =/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const mobileNumber = /^[0-9]/;
    if (!stateData.firstName) {
      IsValid = false;
      errors.firstName = "Required";
    } else if (stateData.firstName.length < 2) {
      IsValid = false;
      errors.firstName = "Must be 15 characters ";
    } else if (!stateData.firstName.match(regex_name)) {
      IsValid = false;
      errors.firstName = "please enter valid name";
    }

    if (!stateData.lastName) {
      IsValid = false;
      errors.lastName = "Required";
    } else if (stateData.lastName.length < 2) {
      IsValid = false;
      errors.lastName = "please enter valid name";
    }

    if (!stateData.mobileNumber) {
      IsValid = false;
      errors.mobileNumber = "Required";
    } else if (stateData.mobileNumber.length < 10) {
      IsValid = false;
      errors.mobileNumber = "Must be 10 digits";
    } else if (!stateData.mobileNumber.match(mobileNumber)) {
      IsValid = false;
      errors.mobileNumber = "please enter valid mobileNumber number";
    }

    if (!stateData.email) {
      IsValid = false;
      errors.email = "Required";
    } else if (!stateData.email.match(regex_email)) {
      console.log("stateData.email", stateData.email);
      IsValid = false;
      errors.email = "please enter valid email id";
    }
    setStateData({
      ...stateData,
      errors: errors,
    });
    console.log("v5");
    return IsValid;
  };

  const handleChangeEdit = (e) => {
    debugger;
    setStateData({ ...stateData, [e.target.name]: e.target.value});
    // stateData((prev) => {
    //   return {
    //     ...prev,
    //     [e.target.name]: e.target.value,
    //     errors: { ...stateData.errors, [e.target.name]: "" },
    //   };
    // });
  };

  const handleEdit = async () => {
    setShowInfo(false);
    setShowForm(true);
    GetCandidateDetails(localStorage.getItem("userId"));
  };
  const handleSubmit = async () => {
    if (validate()) {
      console.log("data--------------->", stateData);
      await updateCandidateDetails(stateData)
        .then((res) => {
          console.log("updateCandidateDetails", res);
          GetCandidateDetails(res.data[0].id);
          setShowInfo(true);
          setShowForm(false);
          alert("done");
        })
        .catch((error) => {});
    } else {
      alert("please enter all fields");
    }
  };

  return (
    <>
      <div className="font-Helvetica  space-y-5">
        <div className="font-Helvetica  flex justify-between border-b pb-5">
          <p className="font-Helvetica lg:text-xl md:text-xl text-md font-semibold tracking-wide uppercase">
            Basic Information
          </p>
          <div className="font-Helvetica  flex items-center">
            <div className="font-Helvetica  p-1 w-4 h-4 rotate-45 translate-x-2 bg-legavacBlue"></div>
            <button
              className="font-Helvetica  z-10 bg-legavacBlue py-1 px-2 text-white font-medium text-xs"
              onClick={() => navigate("/")}
            >
              Back
            </button>
          </div>
        </div>
        {showForm  ? (
          <div>
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  First Name:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    value={(stateData && stateData.firstName) || ""}
                    name="firstName"
                    onChange={handleChangeEdit}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                  />
                  {stateData &&
                  stateData.errors &&
                  stateData.errors.firstName ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {stateData.errors.firstName}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Last Name:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    name="lastName"
                    onChange={handleChangeEdit}
                    value={(stateData && stateData.lastName) || ""}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                  />
                  {stateData &&
                  stateData.errors &&
                  stateData.errors.lastName ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {stateData.errors.lastName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Date Of Birth:
                </p>
                <div>
                  <input
                    type="date"
                    placeholder="Enter date of birth"
                    name="dob"
                    onChange={handleChangeEdit}
                    value={stateData.dob}
                    // value={(new Date(stateData.dob).getFullYear()) + "-" + (new Date(stateData.dob).getMonth() + 1) + "-"+(new Date(stateData.dob).getDate()).toString() || ""}
                    className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  grid grid-cols-1 gap-5 pt-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Description:
                </p>
                <textarea
                  rows={3}
                  maxLength={400}
                  name="description"
                  id="description"
                  onChange={handleChangeEdit}
                  value={(stateData && stateData.description) || ""}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
            </div>

            <div className="font-Helvetica  ">
              <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 pt-5">
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    mobileNumber no.:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter mobile number"
                      name="mobileNumber"
                      onChange={handleChangeEdit}
                      value={
                        (stateData && stateData.mobileNumber) || ""
                      }
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {stateData &&
                    stateData.errors &&
                    stateData.errors.mobileNumber ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {stateData.errors.mobileNumber}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    email Id:
                    <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                      *
                    </span>
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter email id"
                      name="email"
                      id="email"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.email) || ""}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                    {stateData &&
                    stateData.errors &&
                    stateData.errors.email ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {stateData.errors.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Country:
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter country"
                      name="country"
                      id="country"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.country) || ""}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    State:
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter State"
                      name="state"
                      id="state"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.state) || ""}
                      className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
                <div className="font-Helvetica space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    District:
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter district"
                      name="district"
                      id="district"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.district) || ""}
                      className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    City:
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter city"
                      name="city"
                      id="city"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.city) || ""}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Postal pin:
                  </p>
                  <input
                    type="text"
                    placeholder="Enter pin code"
                    name="postalPin"
                    id="postalPin"
                    onChange={handleChangeEdit}
                    value={(stateData && stateData.postalPin) || ""}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Full Address:
                  </p>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter your fullAddress"
                      name="fullAddress"
                      id="fullAddress"
                      onChange={handleChangeEdit}
                      value={(stateData && stateData.fullAddress) || ""}
                      className="placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2  w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between pt-8">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  py-2 px-5 text-sm rounded bg-legavacBlue text-white uppercase focus:outline-none hover:outline-none"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* edit */}
        {showInfo ? (
          <div>
            <div className="space-y-5 w-full">
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-5">
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Full Name</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.firstName} {stateData.lastName}{" "}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Email ID</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.email}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Mobile Number</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.mobileNumber}
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-5">
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Full Address</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9 overflow-y-scroll">
                    {stateData.fullAddress}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Country</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.country}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">City</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.city}
                  </p>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 gap-5">
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">District</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.district}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">State</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.state}
                  </p>
                </div>
                <div>
                  <p className="text-legavacBlue text-sm font-semibold mb-1">Date Of Birth</p>
                  <p className="text-sm border border-slate-300 rounded p-2 w-full h-9">
                    {stateData.dob}
                  </p>
                </div>
              </div>
              <div className="col-span-3">
                <p className="text-legavacBlue text-sm font-semibold mb-1">Description</p>
                <p className="text-sm border border-slate-300 rounded p-2 w-full h-24">
                  {stateData.description}
                </p>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between pt-8">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  py-2 px-5 text-sm rounded bg-green-600 text-white uppercase"
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default MyProfile;
