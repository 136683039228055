import React from "react";
import { MdClose } from "react-icons/md";
import { IMAGES } from "../../stores";

function Notifications() {
  
  const notification = [
    {
      name: "Lorem Ipsum",
      designation: "Developer",
      date: "2 days ago",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "3 days ago",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "We Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
    {
      name: "Lorem Ipsum",
      designation: "Developer",
      date: "29 Jan 2023",
    },
  ];
  return (
    <>
      <div className="font-Helvetica relative rounded-t-lg bg-white drop-shadow overflow-y-auto">
        <div className="font-Helvetica  sticky top-0">
          <div
            className="font-Helvetica  bg-white absolute px-5 h-16 z-50 top-0 w-full flex justify-between 
            items-center border-b"
          >
            <p className="font-Helvetica  text-xl font-semibold text-legavacBlue">
              Notifications 
            </p>
            <MdClose
              size={15}
              className="font-Helvetica  text-slate-500"
              data-te-offcanvas-dismiss
            />
          </div>
        </div>
        <div className="font-Helvetica  p-5 divide-y pt-16 ">
          {/* {notification?.map((n, idx) => (
            <div className="font-Helvetica  flex justify-between items-center py-2">
              <div className="font-Helvetica  flex space-x-2 items-start">
                <div className="font-Helvetica  rounded-full border border-slate-400">
                  <IoMdNotificationsOutline
                    size={30}
                    className="font-Helvetica  text-slate-700"
                  />
                </div>

                <div key={idx}>
                  <p className="font-Helvetica  text-sm font-semibold">
                    {n.position} <span className="font-Helvetica  text-slate-500">at</span>
                  </p>
                  <p className="font-Helvetica  text-sm">{n.company}</p>
                </div>
              </div>
                <TbDotsVertical/>
            </div>
          ))} */}
             {notification?.map((note, idx) => (
                  <div className="py-2" key={idx}>
                    <div className="flex justify-between items-start">
                      <div className="flex space-x-3 items-center ">
                          <img
                            src={IMAGES.Profile}
                            alt=""
                            className="w-10 h-10 rounded-full border"
                          />
                        <div>
                          <p className="font-Helvetica text-sm font-semibold">
                            {note.name}
                          </p>
                          <p className="font-Helvetica text-xs">
                            {note.designation}
                          </p>
                        </div>
                      </div>
                      <div className="">
                        <p className="text-xs text-slate-500">{note.date}</p>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}

export default Notifications;
