import React, { useEffect } from "react";
import { useState } from "react";
import { MdClose, MdDelete } from "react-icons/md";
import { getCandidateJobDetails } from "../../../config/api";
import ViewFullPage from "../../main/ViewFullPage";

function SavedJobs() {
  //getCandidateJobDetails
  const [state, setState] = useState({
    CandidateId: localStorage.getItem("userId"),
    IsSaved: "true",
    IsApplied: "false",
  });

  const [savedJobs, setSavedJobs] = useState([]);
  useEffect(() => {
    GetCandidateJobDetails(state);
  }, []);

  const GetCandidateJobDetails = async (state) => {
    await getCandidateJobDetails(state).then((res) => {
      console.log("job details------>", res.data);
      setSavedJobs(res.data);
    });
  };
  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Saved Jobs
          </p>
        </div>
        <div className="font-Helvetica  pt-8">
          <div className="font-Helvetica  flex flex-col">
            <div className="font-Helvetica  overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="font-Helvetica  py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="font-Helvetica  overflow-hidden">
                  <table className="font-Helvetica  min-w-full">
                    <thead className="font-Helvetica  bg-blue-100">
                      <tr>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Jobs
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="font-Helvetica  text-sm font-semibold tracking-wide text-gray-900 px-6 py-4 text-left"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {savedJobs &&
                        savedJobs.map((j, idx) => (
                          <tr className="font-Helvetica" key={idx}>
                            <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                              {j.jobTitle}
                            </td>
                            <td className="font-Helvetica whitespace-nowrap px-6 py-2 text-slate-700 cursor-pointer hover:underline text-blue-700"
                             data-te-offcanvas-toggle
                             data-te-target="#offcanvasBottom2"
                             aria-controls="offcanvasBottom2"
                             onClick={() => {
                               const editData = savedJobs.filter(
                                 (data) => data.id === j.id
                               );
                               setState(editData[0]);
                             }}>
                              {j.companyName}
                            </td>
                            <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                              {j.createdDate}
                            </td>
                            <td className="font-Helvetica  whitespace-nowrap px-6 py-2 flex space-x-3">
                              {/* <MdEdit
                                className="font-Helvetica  text-green-600 cursor-pointer"
                                size={20}
                                onClick={() => {
                                  setShow(true);
                                  const editData = addSkills.filter(
                                    (data) => data.id === s.id
                                  );
                                  console.log(editData[0]);
                                  setState(editData[0]);
                                }}
                              /> */}
                              <MdDelete
                                className="font-Helvetica  text-red-500 cursor-pointer"
                                size={20}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        className="font-Helvetica  fixed bottom-0 flex flex-col max-w-full lg:bg-gray-50
         md:bg-gray-50 bg-white invisible bg-clip-padding shadow-sm outline-none transition
          duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-full max-h-full z-[1045] 
          translate-y-full [&[data-te-offcanvas-show]]:transform-none"
        tabIndex="-1"
        id="offcanvasBottom2"
        aria-labelledby="offcanvasBottomLabel"
        data-te-offcanvas-init
      >
        <div className="font-Helvetica  offcanvas-header flex items-center justify-end p-5 px-20">
          <button
            type="button"
            className="font-Helvetica  btn-close box-content w-9 h-9 p-2 -my-5 -mr-2 text-white border-none rounded-none focus:shadow-none focus:outline-none hover:opacity-75 hover:no-underline"
            data-te-offcanvas-dismiss
          >
            <MdClose size={30} className="bg-red-500" />
          </button>
        </div>
        <div className="font-Helvetica  offcanvas-body flex-grow lg:p-4 overflow-y-auto small">
          <ViewFullPage state={[state]} />
        </div>
      </div>
    </>
  );
}

export default SavedJobs;
