import React from "react";
import { MdOutlineLocationOn, MdOutlineRemoveRedEye } from "react-icons/md";
import { VscBriefcase } from "react-icons/vsc";
import { FiSend } from "react-icons/fi";
import { BiRupee } from "react-icons/bi";

function Highlights() {
  // console.log("highlights------->", props);
  return (
    <>
      <div className="font-Helvetica  space-y-1">
        <div className="font-Helvetica  flex space-x-2 items-center">
          <MdOutlineLocationOn
            size={20}
            className="font-Helvetica  text-slate-500"
          />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">
              {/* {props.city} */} Pune
            </p>
            <p className="font-Helvetica  text-slate-500 text-xs">India</p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-center">
          <VscBriefcase size={20} className="font-Helvetica  text-slate-500" />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">
              {/* {props.exp} */}
                1-4 (experience)
            </p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-5">
          <div className="font-Helvetica  flex space-x-2 items-center">
            <MdOutlineRemoveRedEye
              size={20}
              className="font-Helvetica  text-slate-500"
            />
            <p className="font-Helvetica  text-slate-500 text-xs">200</p>
          </div>
          <div className="font-Helvetica  flex space-x-2 items-center">
            <FiSend
              size={18}
              className="font-Helvetica  text-slate-500 rotate-45"
            />
            <p className="font-Helvetica  text-slate-500 text-xs">
              {/* {props.appliedJob}  */}
              200
            </p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-center">
          <BiRupee size={18} className="font-Helvetica  text-slate-500" />
          <p className="font-Helvetica  text-slate-500 text-xs">
            {/* {props.salary} */}
             3L-5L
          </p>
        </div>
      </div>
    </>
  );
}

export default Highlights;
