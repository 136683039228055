import React from "react";
import { IMAGES } from "../../../stores";

function VendorManagement() {
  return (
    <>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-0 relative lg:pt-28 pt-20">
        <div className="font-Helvetica  mb-10">
          <p className="font-Helvetica  text-3xl font-QuickStand font-bold text-center pb-3">
            Vendor Management PlatForm
          </p>

          <hr />
        </div>
        <div className="font-Helvetica  grid  place-items-center">
          <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-32 gap-y-10 px-5">
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg">
              <div className="font-Helvetica  bg-[#001ba1] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#001ba1] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                SELECT
              </p>
              <div className="font-Helvetica  border border-[#001ba1]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.user} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">offer</li>
                  <li className="font-Helvetica">Assignment Creation</li>
                  <li className="font-Helvetica">HR Record Creation</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#eb8a00] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#eb8a00] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                INVOICE & OFFBOARDING
              </p>
              <div className="font-Helvetica  border border-[#eb8a00]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.invoice} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Automated Invoicing </li>
                  <li className="font-Helvetica">Supplier Payments</li>
                  <li className="font-Helvetica">Dynamic Reporting</li>
                </ul>
              </div>
            </div>

            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#001ba1] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#001ba1] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                REQUEST
              </p>
              <div className="font-Helvetica  border border-[#001ba1]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.request} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Request & Approve Resource</li>
                  <li className="font-Helvetica">Bill Rate & Salary Ranges</li>
                  <li className="font-Helvetica">Job Templates</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#eb8a00] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#eb8a00] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                ONBOARD
              </p>
              <div className="font-Helvetica  border border-[#eb8a00]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.takecare} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm ">
                  <li className="font-Helvetica"> Background Check/Drug Screen</li>
                  <li className="font-Helvetica">System Access and Equipment</li>
                  <li className="font-Helvetica">Candidate Services & Training</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#001ba1] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#001ba1] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                QUARTERLY REVIEW
              </p>
              <div className="font-Helvetica  border border-[#001ba1]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.reviews} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Performance Reporting</li>
                  <li className="font-Helvetica">Benchmarking</li>
                  <li className="font-Helvetica">Measured Guidance</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#eb8a00] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#eb8a00] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                SOURCE
              </p>
              <div className="font-Helvetica  border border-[#eb8a00] w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.dataSource} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Shortlist</li>
                  <li className="font-Helvetica">Interview</li>
                  <li className="font-Helvetica">Evaluate</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#001ba1] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#001ba1] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                MANAGE & REGULATE
              </p>
              <div className="font-Helvetica  border border-[#001ba1]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.managementVendor} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Timecard Approvals </li>
                  <li className="font-Helvetica">Headcount Records</li>
                  <li className="font-Helvetica">Reporting</li>
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  relative grid  bg-slate-50 border w-[250px]  shadow-lg  ">
              <div className="font-Helvetica  bg-[#eb8a00] w-5 h-5 absolute top-[30px] -z-10 -left-[11px] rotate-45"></div>
              <p className="font-Helvetica  bg-[#eb8a00] w-72 z-10  font-semibold text-white text-center absolute -left-4 p-2">
                MEASURE & IMPROVE
              </p>
              <div className="font-Helvetica  border border-[#eb8a00]  w-16 h-16 z-20 bg-white rounded-full absolute p-3 left-[232px] -top-3">
                <img src={IMAGES.improve} alt="" />
              </div>
              <div className="font-Helvetica  pt-20 pb-10 ">
                <ul className="font-Helvetica  list-disc px-10 text-sm">
                  <li className="font-Helvetica">Review Effectiveness</li>
                  <li className="font-Helvetica">Monitor Industry Trends</li>
                  <li className="font-Helvetica">Recommend Changes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorManagement;
