import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { verifyOtp } from "../../config/api";

function VerificationEmail(props) {
  const { email } = props;
  console.log("emial verify", email);
  
  const [otp, setOtp] = useState({
    otp_1: "",
    otp_2: "",
    otp_3: "",
    otp_4: "",
    otp_5: "",
    otp_6: "",
    error: [],
  });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }

  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(interval);
  //       } else {
  //         setSeconds(60);
  //         setMinutes(minutes - 1);
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [seconds]);

  // const resendOTP = () => {
  //   setMinutes(0);
  //   setSeconds(60);
  // };

  const handleChange = (e) => {
    setOtp({ ...otp, [e.target.name]: [e.target.value] });
    setOtp((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        error: { ...otp.error, [e.target.name]: "" },
      };
    });
  };

  const validate = () => {
    let IsValid = true;
    const errors = {};
    if (!otp.otp_1) {
      IsValid = false;
      errors["otp_1"] = "please proper otp";
    } else {
      errors["otp_1"] = "";
    }
    if (!otp.otp_2) {
      IsValid = false;
      errors["otp_2"] = "please proper otp";
    } else {
      errors["otp_2"] = "";
    }
    if (!otp.otp_3) {
      IsValid = false;
      errors["otp_3"] = "please proper otp";
    } else {
      errors["otp_3"] = "";
    }
    if (!otp.otp_4) {
      IsValid = false;
      errors["otp_4"] = "please proper otp";
    } else {
      errors["otp_4"] = "";
    }
    if (!otp.otp_5) {
      IsValid = false;
      errors["otp_5"] = "please proper otp";
    } else {
      errors["otp_5"] = "";
    }
    if (!otp.otp_6) {
      IsValid = false;
      errors["otp_6"] = "please proper otp";
    } else {
      errors["otp_6"] = "";
    }
    setOtp({
      ...otp,
      error: errors,
    });

    return IsValid;
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger;
    if (validate()) {
      var _otp = otp.otp_1 + otp.otp_2 + otp.otp_3 + otp.otp_4 + otp.otp_5 + otp.otp_6;
      await verifyOtp({
        email: email,
        otp:_otp,
      }).then((res) => {
        console.log(res);
        if(res.data === "Account verification completed."){
          navigate("/login");
          document.location.reload(true);
        } else{
          alert("please enter valid otp");
        }
      
      });
      alert("done");
      props.onClose();
     
    } else {
      // props.onClose();
      alert("please enter proper otp sent on email");
    }
  };
  const keyPressed = (e) => {
    console.log(e);
  };


  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  function handleKeyUp(e, ref) {
    const maxLength = e.target.maxLength;
    const length = e.target.value.length;

    if (length === maxLength) {
      ref.current.focus();
    }
  }
  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto mt-20 ${
        props.isOpen ? "" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-green-500 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={props.onClose}
              className="text-white font-bold text-lg focus:outline-none"
            >
              X
            </button>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="text-lg mb-6 text-center">
              <div className="font-Helvetica  p-5">
                <p className="font-Helvetica  pb-5 text-xl text-center text-legavacBlue font-semibold">
                  Verification Code
                </p>
                <p className="font-Helvetica  text-sm text-center">
                  Please enter the verification code sent to
                </p>
                <p className="font-Helvetica  font-semibold text-sm text-center">
                  {props.email}
                </p>
                <div className="font-Helvetica  flex space-x-1 justify-center pt-5">
                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_1}
                    name="otp_1"
                    id="otp_1"
                    tabIndex="1"
                    maxLength="1"
                    onKeyPress={(e) => keyPressed(e)}
                    onChange={handleChange}
                    ref={input1Ref}
                    onKeyUp={(e) => handleKeyUp(e, input2Ref)}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_1}
                    </p>
                  ) : null}

                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_2}
                    name="otp_2"
                    id="otp_2"
                    tabIndex="2"
                    maxLength="1"
                    onChange={handleChange}
                   ref={input2Ref}
                   onKeyUp={(e) => handleKeyUp(e, input3Ref)}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_2}
                    </p>
                  ) : null}

                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_3}
                    name="otp_3"
                    id="otp_3"
                    tabIndex="3"
                    maxLength="1"
                    onChange={handleChange}
                   ref={input3Ref}
                   onKeyUp={(e) => handleKeyUp(e, input4Ref)}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_3}
                    </p>
                  ) : null}

                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_4}
                    name="otp_4"
                    id="otp_4"
                    tabIndex="4"
                    maxLength="1"
                    onChange={handleChange}
                    ref={input4Ref}
                    onKeyUp={(e) => handleKeyUp(e, input5Ref)}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_4}
                    </p>
                  ) : null}

                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_5}
                    name="otp_5"
                    id="otp_5"
                    tabIndex="5"
                    maxLength="1"
                    onChange={handleChange}
                    ref={input5Ref}
                    onKeyUp={(e) => handleKeyUp(e, input6Ref)}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_5}
                    </p>
                  ) : null}

                  <input
                    className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
                    value={otp.otp_6}
                    name="otp_6"
                    id="otp_6"
                    tabIndex="6"
                    maxLength="1"
                    onChange={handleChange}
                    ref={input6Ref}
                  />
                  {otp.error ? (
                    <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                      {otp.error.otp_6}
                    </p>
                  ) : null}
                </div>
                {otp.error ? (
                  <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
                    {otp.error.otp_1}
                  </p>
                ) : null}

                {/* <div className="flex  justify-center font-Helvetica   space-x-2 pt-5">
                  {seconds > 0 || minutes > 0 ? (
                    <p className="text-sm">
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p className="text-sm">Didn't receive code?</p>
                  )}

                  <button
                    className="disabled:opacity-25 text-sm"
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color:
                        seconds > 0 || minutes > 0
                          ? `className= "text-legavacBlue"`
                          : `className= "text-legavacYellow"`,
                    }}
                    onClick={resendOTP}
                  >
                    {" "}
                    Resend OTP
                  </button>
                </div> */}
                <div className="font-Helvetica  grid place-items-center pt-5">
                  {otp.otp_1 && otp.otp_2 && otp.otp_3 && otp.otp_4 ? (
                    <button
                      className="font-Helvetica  py-2 px-5 bg-legavacBlue rounded text-white text-sm font-medium cursor-pointer"
                      onClick={handleSubmit}
                    >
                      Verify OTP
                    </button>
                  ) : (
                    <button
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="font-Helvetica  py-2 px-5 bg-slate-400 rounded text-white text-sm font-medium  cursor-not-allowed"
                    >
                      Please enetr OTP
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerificationEmail;

// import React,{useEffect,useState} from "react";

// function VerificationEmail(props) {

//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(60);

//   const [otp, setOtp] = useState({
//     otp_1: "",
//     otp_2: "",
//     otp_3: "",
//     otp_4: "",
//     otp_5: "",
//     error: [],
//   });

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       }

//       if (seconds === 0) {
//         if (minutes === 0) {
//           clearInterval(interval);
//         } else {
//           setSeconds(60);
//           setMinutes(minutes - 1);
//         }
//       }
//     }, 1000);

//     return () => {
//       clearInterval(interval);
//     };
//   }, [seconds]);

//   const resendOTP = () => {
//     setMinutes(0);
//     setSeconds(60);
//   };

//   const handleChange = (e) => {
//     setOtp({ ...otp, [e.target.name]: [e.target.value] });
//     setOtp((prev) => {
//       return {
//         ...prev,
//         [e.target.name]: e.target.value,
//         error: { ...otp.error, [e.target.name]: "" },
//       };
//     });
//   };
// const handleSubmit=()=>{

// }
//   return (
//     <>
//       <div className="px-4 py-5 sm:p-6">
//         <div className="text-lg mb-6 text-center">
//           <div className="font-Helvetica  p-5">
//             <p className="font-Helvetica  pb-5 text-xl text-center text-legavacBlue font-semibold">
//               Verification Code
//             </p>
//             <p className="font-Helvetica  text-sm text-center">
//               Please enter the verification code sent to
//             </p>
//             <p className="font-Helvetica  font-semibold text-sm text-center">
//               {props.email}
//             </p>
//             <div className="font-Helvetica  flex space-x-2 justify-center pt-5">
//               <input
//                 className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
//                 value={otp.otp_1}
//                 name="otp_1"
//                 id="otp_1"
//                 onChange={handleChange}

//               />

//               <input
//                 className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
//                 value={otp.otp_2}
//                 name="otp_2"
//                 id="otp_2"
//                 onChange={handleChange}
//               />
//               <input
//                 className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
//                 value={otp.otp_3}
//                 name="otp_3"
//                 id="otp_3"
//                 onChange={handleChange}
//               />
//               <input
//                 className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
//                 value={otp.otp_4}
//                 name="otp_4"
//                 id="otp_4"
//                 onChange={handleChange}
//               />
//               <input
//                 className="font-Helvetica  p-2 w-10 h-10 border border-legavacBlue rounded-lg hover:outline-none focus:outline-none"
//                 value={otp.otp_5}
//                 name="otp_5"
//                 id="otp_5"
//                 onChange={handleChange}
//               />
//             </div>
//             {otp.error ? (
//               <p className="font-Helvetica  text-red-500 text-center text-xs pt-1">
//                 {otp.error.otp_1}
//               </p>
//             ) : null}
//             <div></div>

//             <div className="flex  justify-center font-Helvetica   space-x-2 pt-5">
//               {seconds > 0 || minutes > 0 ? (
//                 <p>
//                   Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
//                   {seconds < 10 ? `0${seconds}` : seconds}
//                 </p>
//               ) : (
//                 <p>Didn't recieve code?</p>
//               )}

//               <button
//                 className="disabled:opacity-25 text-sm"
//                 disabled={seconds > 0 || minutes > 0}
//                 style={{
//                   color:
//                     seconds > 0 || minutes > 0
//                       ? `className= "text-legavacBlue"`
//                       : `className= "text-legavacYellow"`,
//                 }}
//                 onClick={resendOTP}
//               >
//                 {" "}
//                 Resend OTP
//               </button>
//             </div>
//             <div className="font-Helvetica  grid place-items-center pt-5">
//               {!otp.otp_1 &&
//               !otp.otp_2 &&
//               !otp.otp_3 &&
//               !otp.otp_4 &&
//               !otp.otp_5 ? (
//                 <button className="font-Helvetica  py-2 px-5 bg-red-500 rounded text-white text-sm font-medium cursor-not-allowed">
//                   Verify Email
//                 </button>
//               ) : (
//                 <button
//                   data-te-ripple-init
//                   data-te-ripple-color="light"
//                   className="font-Helvetica  py-2 px-5 bg-legavacBlue rounded text-white text-sm font-medium cursor-pointer"
//                   onClick={handleSubmit}
//                   data-te-toggle="modal"
//                   data-te-offcanvas-toggle
//                   data-te-target="#offcanvasExample2"
//                   aria-controls="offcanvasExample2"
//                   // style={{
//                   //   cursor:
//                   //     otp.otp_1 &&
//                   //     otp.otp_2 &&
//                   //     otp.otp_3 &&
//                   //     otp.otp_4 &&
//                   //     otp.otp_5
//                   //       ? "pointer"
//                   //       : "not-allowed",
//                   // }}
//                 >
//                   Verify Email eeee
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default VerificationEmail;
