import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { VscLocation } from "react-icons/vsc";
import ViewFullPage from "../../main/ViewFullPage";
import { getCandidateJobDetails } from "../../../config/api";
import { SiMinds } from "react-icons/si";

function AppliedJobs() {
  const [state, setState] = useState({
    CandidateId: localStorage.getItem("userId"),
    IsSaved: "false",
    IsApplied: "true",
  });

  const [appliedJobs, setAppliedJobs] = useState([]);
  useEffect(() => {
    GetCandidateJobDetails(state);
  }, []);

  const GetCandidateJobDetails = async (state) => {
    debugger
    await getCandidateJobDetails(state).then((res) => {
      console.log("applied job details------>", res.data);
      setAppliedJobs(res.data);
    });
  };
  const timeLineBalls = (n, onClick, current) =>
    Array(n)
      .fill(0)
      .map((i, index) => (
        <div
          key={index}
          className={`timeline__ball ${current >= index ? "active" : null}`}
          onClick={() => onClick(index)}
        >
          {index + 1}
        </div>
      ));

  const App = () => {
    const [width, setWidth] = useState(0);

    const intermediaryBalls = 1;
    const calculatedWidth = (width / (intermediaryBalls + 1)) * 100;
    return (
      <>
        <div className="font-Helvetica  timeline">
          <div
            className="font-Helvetica  timeline__progress"
            style={{ width: `${calculatedWidth}%` }}
          />
          {timeLineBalls(intermediaryBalls + 2, setWidth, width)}
        </div>
        <div className="font-Helvetica  grid grid-cols-3 gap-5 pt-6">
          <p className="font-Helvetica  text-xs text-center">View Resume</p>
          <p className="font-Helvetica  text-xs text-center">
            Application View
          </p>
          <p className="font-Helvetica  text-xs text-center">
            Waiting for Recruiter Action
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Jobs Found
          </p>
        </div>
        <div className="font-Helvetica  pt-8 space-y-5">
          {appliedJobs?.map((j, idx) => (
            <div
              className="font-Helvetica  border hover:border-legavacBlue p-5"
              key={idx}
            >
              <div className="font-Helvetica  space-y-3">
                <div className="font-Helvetica  lg:flex md:flex lg:justify-between md:justify-between items-center">
                  <p className="font-Helvetica  text-xl font-semibold tracking-wider">
                    {j.jobTitle}
                  </p>
                  <p
                    className="font-Helvetica  text-sm font-medium text-green-600 cursor-pointer"
                    data-te-toggle="modal"
                    data-te-target="#exampleModalCenter"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    Application Status
                  </p>
                </div>
                <div className="font-Helvetica  lg:flex md:flex lg:space-x-5 md:space-x-5 space-x-0 items-center lg:divide-x md:divide-x">
                  <div>
                    <p className="font-Helvetica  text-sm text-legavacBlue lg:text-center md:text-center">
                      {j.companyName}
                    </p>
                  </div>
                  <div className="font-Helvetica  flex space-x-1 items-center lg:pl-3">
                    <VscLocation
                      size={20}
                      className="font-Helvetica  text-legavacBlue"
                    />
                    <p className="font-Helvetica  text-sm text-legavacBlue">
                      {j.city}
                    </p>
                    ,
                    <p className="font-Helvetica  text-sm text-legavacBlue">
                      {j.country}
                    </p>
                  </div>
                  <div className="font-Helvetica  lg:pl-3">
                    <p className="font-Helvetica  text-sm text-legavacBlue lg:text-center md:text-center">
                      ₹ {j.salary}
                    </p>
                  </div>
                </div>
                <div className="font-Helvetica  flex space-x-2 items-start">
                  <SiMinds
                    size={20}
                    className="font-Helvetica  text-slate-500"
                  />
                  <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-3 gap-2">
                    {j.skill?.split(",").map((s, idx) => (
                      <p
                        className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
                        key={idx}
                      >
                        {s}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="font-Helvetica  lg:bg-gradient-to-r lg:from-blue-100  p-2 lg:flex md:flex lg:justify-between md:justify-between items-center">
                  <div className="font-Helvetica  flex space-x-2">
                    <p className="font-Helvetica  text-sm">Posted:</p>
                    <p className="font-Helvetica  text-sm text-legavacBlue">
                      {j.createdDate}
                    </p>
                  </div>
                  <div className="lg:pt-0 md:pt-0 pt-3">
                    <button
                      type="button"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      data-te-offcanvas-toggle
                      data-te-target="#offcanvasBottom2"
                      aria-controls="offcanvasBottom2"
                      className="font-Helvetica  bg-legavacBlue rounded text-sm text-white px-3 py-2"
                      onClick={() => {
                        const editData = appliedJobs.filter(
                          (data) => data.id === j.id
                        );
                        setState(editData[0]);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="font-Helvetica  fixed bottom-0 flex flex-col max-w-full lg:bg-gray-50
         md:bg-gray-50 bg-white invisible bg-clip-padding shadow-sm outline-none transition
          duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-full max-h-full z-[1045] 
          translate-y-full [&[data-te-offcanvas-show]]:transform-none"
                tabIndex="-1"
                id="offcanvasBottom2"
                aria-labelledby="offcanvasBottomLabel"
                data-te-offcanvas-init
              >
                <div className="font-Helvetica  offcanvas-header flex items-center justify-end p-4">
                  <button
                    type="button"
                    className="font-Helvetica  btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-te-offcanvas-dismiss
                  >
                    <MdClose />
                  </button>
                </div>
                <div className="font-Helvetica  offcanvas-body flex-grow lg:p-4 overflow-y-auto small">
                  <ViewFullPage state={[state]} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        data-te-modal-init
        className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="exampleModalCenter"
        tabIndex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="p-10 pointer-events-auto relative flex w-full flex-col rounded border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
            <div>
              <App />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default AppliedJobs;
