import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateProjectDetails,
  saveCandidateProject,
} from "../../../../config/api";

function Projects() {
  const [projects, SetProjects] = useState([]);

  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    projectTitle: "",
    projectClient: "",
    projectStatus: "",
    startDate: "",
    endDate: "",
    descriptionProject: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const funShow = () => {
    setShow(true);
  };

  const [finished, setFinished] = useState(false);
  const [showFinished, setShowFinished] = useState(false);
  const funProject = (e) => {
    if (e.target.value === "inProgress") {
      alert(e.target.value);
      setShowFinished(true);
      setFinished(false);
    } else if (e.target.value === "finished") {
      alert(e.target.value);
      setShowFinished(true);
      setFinished(true);
    }
  };

  // getCandidateProjectDetails

  useEffect(() => {
    //getCandidateWorkExperience
    GetCandidateProjectDetails(localStorage.getItem("userId"));
  }, [projects.id]);

  const GetCandidateProjectDetails = async (id) => {
    await getCandidateProjectDetails(id)
      .then((res) => {
        console.log("project->", res);
        if (res.data.length === 0) {
          setShow(true);
          setHide(false);
          SetProjects(res.data);
        } else {
          setHide(true);
          SetProjects(res.data);
        }

         if (res.data && res.data.length !== 0 || res.data[0] && res.data[0].projectStatus === "inProgress") {
          // alert(e.target.value);
          setShowFinished(true);
          setFinished(false);
        } else if (res.data && res.data.length !== 0 || res.data[0] && res.data[0].projectStatus === "finished") {
          // alert(e.target.value);
          setShowFinished(true);
          setFinished(true);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const [selectedOption, setSelectedOption] = useState("inProgress");
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setSelectedOption(e.target.value);
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const handleSubmit = async () => {
    
    await saveCandidateProject(state)
      .then((res) => {
        console.log("project--->", res);
        GetCandidateProjectDetails(localStorage.getItem("userId"));
        setShow(false);
        alert("done");
        setState({
          id: "0",
          CandidateId: state.CandidateId,
          projectTitle: "",
          projectClient: "",
          projectStatus: selectedOption,
          startDate: "",
          endDate: "",
          descriptionProject: "",
          CreatedBy: state.CreatedBy,
        });
      })
      .catch((error) => {
        console.log("error project -->", error);
      });
  };
  const handleDelete = async (id) => {
    
    await deleteRecord({
      tableName: "CandidateProjects",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        GetCandidateProjectDetails(localStorage.getItem("userId"));
        alert("record deleted");
      }
    });
  };
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Projects
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div>
            <div className="font-Helvetica  space-y-5">
              <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Title:
                  </p>
                  <input
                    type="text"
                    name="projectTitle"
                    id="projectTitle"
                    onChange={handleChange}
                    value={state.projectTitle}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>

                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Client:
                  </p>
                  <input
                    type="text"
                    name="projectClient"
                    id="projectClient"
                    onChange={handleChange}
                    value={state.projectClient}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
              </div>
              <div>
                <div className="font-Helvetica  col-span-2 space-y-2">
                  <div className="font-Helvetica  flex space-x-10 items-center">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Project Status:
                    </p>
                    <div className="flex lg:space-x-10 md:space-x-10 space-x-7">
                      <div className="font-Helvetica  ">
                        <input
                          className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04] "
                          type="radio"
                          name="projectStatus"
                          id="radioDefault01"
                          onClick={funProject}
                          onChange={handleChange}
                          checked={state.projectStatus === "inProgress"}
                          value="inProgress"
                        />
                        <label
                          className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                          htmlFor="radioDefault01"
                        >
                          In Progress
                        </label>
                      </div>
                      <div className="font-Helvetica  ">
                        <input
                          className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04]"
                          type="radio"
                          name="projectStatus"
                          id="radioDefault02"
                          onClick={funProject}
                          onChange={handleChange}
                          checked={state.projectStatus === "finished"}
                          value="finished"
                        />
                        <label
                          className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                          htmlFor="radioDefault02"
                        >
                          Finished
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showFinished ? (
                <div className="font-Helvetica  grid grid-cols-2 gap-5">
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Starting Date:
                    </p>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      onChange={handleChange}
                      value={state.startDate}
                      className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                    />
                  </div>
                  {finished ? (
                    <>
                      <div className="font-Helvetica  space-y-2">
                        <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                          End Date :
                        </p>
                        <input
                          type="date"
                          name="endDate"
                          id="endDate"
                          onChange={handleChange}
                          value={state.endDate}
                          className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="font-Helvetica  grid grid-cols-1 gap-5 pt-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Description:
                </p>
                <textarea
                  name="descriptionProject"
                  id="descriptionProject"
                  onChange={handleChange}
                  value={state.descriptionProject}
                  rows={3}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                {state.errors ? (
                  <p className="font-Helvetica  text-sm text-red-600">
                    {state.errors.descriptionProject}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between pt-5">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {projects &&
              projects?.map((p, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div className="">
                        <div className="font-Helvetica ">
                          <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                            {p.projectTitle}
                          </p>
                        </div>
                        <p className="font-Helvetica  text-sm font-semibold">
                          {p.projectClient}
                        </p>
                        <div className="font-Helvetica  flex space-x-1">
                          <p className="font-Helvetica  text-sm text-slate-500">
                            {p.startDate}
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-500">
                            -
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-500">
                            {p.endDate}
                          </p>
                        </div>
                        <p className="font-Helvetica  text-sm ">
                          {p.descriptionProject}
                        </p>
                      </div>{" "}
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          size={20}
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          onClick={() => {
                            setShow(true);
                            const editData = projects.filter(
                              (data) => data.id === p.id
                            );
                            console.log(editData[0]);
                            setState(editData[0]);
                          }}
                        />
                        <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                          onClick={() => {
                            const DeleteData = projects.filter(
                              (data) => data.id === p.id
                            );
                            handleDelete(DeleteData[0].id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Projects;