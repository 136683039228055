import React, { useEffect, useState } from "react";
import { IMAGES } from "../stores";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { getTestimonials } from "../config/api";

function Testimonials() {
  const testimonialData = [
    {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "john deo",
    },
    {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "john deo",
    },
    {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "john deo",
    },
    {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      name: "john deo",
    },
  ];

  // const [testimonialData, setTestimonialData] = useState([]);
  // useEffect(() => {

  //   GetTestimonials(0);
  // }, [testimonialData.testimonial]);

  // const GetTestimonials = async (id) => {
  //   await getTestimonials(id).then((res) => {
  //     console.log("get Testimonial--->", res);
  //     setTestimonialData(res.data);
  //   });
  // };

  return (
    <>
      <div className="font-Helvetica  container mx-auto w-full lg:px-20 md:px-10 px-0 py-10">
        <div className="font-Helvetica  pb-10">
          <p className="font-Helvetica  text-3xl font-semibold text-center">
            Testimonials
          </p>
        </div>

        <div
          id="carouselExampleControls"
          className="font-Helvetica  relative"
          data-te-carousel-init
          data-te-carousel-slide
        >
          <div className="font-Helvetica  relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            {testimonialData?.map((t, idx) =>
              idx === 0 ? (
                <div
                  className="font-Helvetica  relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                  data-te-carousel-active
                >
                  <div
                    className="font-Helvetica  relative grid lg:grid-cols-5 md:grid-cols-1 grid-cols-1"
                    key={idx}
                  >
                    <div className="font-Helvetica  grid place-items-center bg-slate-200 lg:p-10 md:p-10 p-5 ">
                      <img
                        src={IMAGES.Profile}
                        alt=""
                        className="font-Helvetica  lg:absolute lg:top-16 lg:left-32 lg:w-48 lg:h-48 md:w-48 md:h-48 w-48 h-48 border-[2px] border-legavacYellow rounded-full"
                      />
                    </div>
                    <div className="font-Helvetica  lg:col-span-4 bg-legavacBlue md:px-20">
                      <div className="font-Helvetica  px-5 pt-5">
                        <FaQuoteLeft
                          size={40}
                          className="font-Helvetica  text-legavacYellow opacity-70"
                        />
                      </div>
                      <div className="font-Helvetica  px-8 py-10">
                        <p className="font-Helvetica  text-white pb-5">
                          {t.description}
                        </p>
                        <p className="font-Helvetica  border-[2px] rounded border-white"></p>
                        <div className="font-Helvetica  pt-5 grid place-content-end">
                          <p className="font-Helvetica  text-white text-2xl">
                            {t.name}
                          </p>
                          {/* <p className="font-Helvetica  text-white opacity-80">
                          Advocate
                        </p> */}
                        </div>
                        <div className="font-Helvetica  grid place-items-end pt-5">
                          <FaQuoteRight
                            size={40}
                            className="font-Helvetica  text-legavacYellow opacity-70 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="font-Helvetica  relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                  data-te-carousel-item
                >
                  <div className="font-Helvetica  relative grid lg:grid-cols-5 md:grid-cols-1 grid-cols-1">
                    <div className="font-Helvetica  grid place-items-center bg-slate-200 lg:p-10 md:p-10 p-5 ">
                      <img
                        src={IMAGES.Profile}
                        alt=""
                        className="font-Helvetica  lg:absolute lg:top-16 lg:left-32 lg:w-48 lg:h-48 md:w-48 md:h-48 w-48 h-48 border-[2px] border-legavacYellow rounded-full"
                      />
                    </div>
                    <div className="font-Helvetica  lg:col-span-4 bg-legavacBlue md:px-20">
                      <div className="font-Helvetica  px-5 pt-5">
                        <FaQuoteLeft
                          size={40}
                          className="font-Helvetica  text-legavacYellow opacity-70"
                        />
                      </div>
                      <div className="font-Helvetica  px-8 py-10">
                        <p className="font-Helvetica  text-white pb-5">
                          {t.description}
                        </p>
                        <p className="font-Helvetica  border-[2px] rounded border-white"></p>
                        <div className="font-Helvetica  pt-5 grid place-content-end">
                          <p className="font-Helvetica  text-white text-2xl">
                            {t.name}
                          </p>
                          {/* <p className="font-Helvetica  text-white opacity-80">Advocate</p> */}
                        </div>
                        <div className="font-Helvetica  grid place-items-end pt-5">
                          <FaQuoteRight
                            size={40}
                            className="font-Helvetica  text-legavacYellow opacity-70 "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            {/* <div
              className="font-Helvetica  relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
            >
              <div className="font-Helvetica  relative grid lg:grid-cols-5 md:grid-cols-1 grid-cols-1">
                <div className="font-Helvetica  grid place-items-center bg-slate-200 lg:p-10 md:p-10 p-5 ">
                  <img
                    src={IMAGES.Profile}
                    alt=""
                    className="font-Helvetica  lg:absolute lg:top-20 lg:left-32 lg:w-48 lg:h-48 md:w-48 md:h-48 w-48 h-48 border-[2px] border-legavacYellow rounded-full"
                  />
                </div>
                <div className="font-Helvetica  lg:col-span-4 bg-legavacBlue md:px-20">
                  <div className="font-Helvetica  px-5 pt-5">
                    <FaQuoteLeft
                      size={40}
                      className="font-Helvetica  text-legavacYellow opacity-70"
                    />
                  </div>
                  <div className="font-Helvetica  px-8 py-10">
                    <p className="font-Helvetica  text-white pb-5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s
                    </p>
                    <p className="font-Helvetica  border-[2px] rounded border-white"></p>
                    <div className="font-Helvetica  pt-5 grid place-content-end">
                      <p className="font-Helvetica  text-white text-2xl">Annabellae Joe</p>
                      <p className="font-Helvetica  text-white opacity-80">Advocate</p>
                    </div>
                    <div className="font-Helvetica  grid place-items-end pt-5">
                      <FaQuoteRight
                        size={40}
                        className="font-Helvetica  text-legavacYellow opacity-70 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>  */}
          </div>
          <button
            className="font-Helvetica  z-[1] w-[15%] bg-none text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:opacity-90 focus:text-white focus:opacity-90 motion-reduce:transition-none
            md:hidden hidden absolute top-0 bottom-0 lg:flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline lg:-left-10 md:-left-5"
            type="button"
            data-te-target="#carouselExampleControls"
            data-te-slide="prev"
          >
            <span className="font-Helvetica  inline-block bg-no-repeat rounded-full border-[2px] border-legavacBlue p-2">
              <MdOutlineArrowBackIosNew
                size={20}
                className="font-Helvetica  text-legavacBlue"
              />
            </span>
            <span className="font-Helvetica  !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>
          <button
            className="font-Helvetica  z-[1] w-[15%] bg-none text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 focus:text-white focus:opacity-90 motion-reduce:transition-none
            md:hidden hidden absolute top-0 bottom-0 lg:flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline -right-10"
            type="button"
            data-te-target="#carouselExampleControls"
            data-te-slide="next"
          >
            <span className="font-Helvetica  inline-block  bg-no-repeat  rounded-full border-[2px] border-white p-2">
              <MdOutlineArrowForwardIos
                size={20}
                className="font-Helvetica  text-white"
              />
            </span>
            <span className="font-Helvetica  !absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
