import React, { useEffect, useState } from "react";
import { changePassword, getCandidate } from "../../../config/api";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const password = {
    id: localStorage.getItem("userId"),
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    CreatedBy: localStorage.getItem("userId"),
    error: [],
  };
 const navigate =  useNavigate();
  const [state, setState] = useState(password);

  useEffect(() => {
    GetOldPassWord(localStorage.getItem("userId"));
  }, []);

  const [matchPwd, setMatchPwd] = useState();
  const GetOldPassWord = async (id) => {
    await getCandidate(id).then((res) => {
      setMatchPwd(res.data[0].password);
      console.log("get password-->", res.data[0].password, matchPwd);
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let error = {};
  const validate = () => {
    let IsValid = true;
    if (!state.oldPassword) {
      IsValid = false;
      error["oldPassword"] = "This is required field";
    } else {
      error["oldPassword"] = "";
    }
    if (!state.newPassword) {
      IsValid = false;
      error["newPassword"] = "This is required field";
    } else {
      error["newPassword"] = "";
    }
    if (!state.confirmPassword) {
      IsValid = false;
      error["confirmPassword"] = "This is required field";
    } else {
      error["confirmPassword"] = "";
    }

    setState({
      ...state,
      error: error,
    });
    return IsValid;
  };

  const update = async () => {
    debugger
    if (validate()) {
      if (state.oldPassword === matchPwd) {
        if (state.newPassword === state.confirmPassword) {
          await changePassword(state)
            .then((res) => {
              if (res.data[0].id !== "0") {
                alert("Update Successfully");
                GetOldPassWord(localStorage.getItem("userId"));
                setState({
                  id: localStorage.getItem("userId"),
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  CreatedBy: localStorage.getItem("userId"),
                });
                navigate('/login');
              }
            })
            .catch((error) => {
              alert("error-->", error);
            });
        } else {
          alert("new and confirm password should be same");
        }
      } else {
        alert("your old password is not match");
      }
    }
  };

  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <p className="font-Helvetica  text-xl font-semibold tracking-wide uppercase">
            Change Password
          </p>
        </div>

        <div className="font-Helvetica  pt-8 space-y-5">
          <div className="font-Helvetica  grid grid-cols-1">
            <div className="font-Helvetica  space-y-2">
              <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                Old Password :
              </p>
              <input
                type="text"
                placeholder="Enter old password"
                id="oldPassword"
                name="oldPassword"
                value={state.oldPassword}
                onChange={handleChange}
                className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
              {state.error ? (
                <p className="text-sm text-red-600">
                  {state.error.oldPassword}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
            <div className="font-Helvetica  space-y-2">
              <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                New Password :
              </p>
              <input
                type="text"
                placeholder="Enter new password"
                id="newPassword"
                name="newPassword"
                value={state.newPassword}
                onChange={handleChange}
                className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
              {state.error ? (
                <p className="text-sm text-red-600">
                  {state.error.newPassword}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="font-Helvetica  space-y-2">
              <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                Confirm Password :
              </p>
              <input
                type="text"
                placeholder="Enter confirm password"
                id="confirmPassword"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleChange}
                className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
              {state.error ? (
                <p className="text-sm text-red-600">
                  {state.error.confirmPassword}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="font-Helvetica  flex justify-between pt-5">
            <div className="font-Helvetica  ">
              <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  text-sm bg-legavacBlue font-medium uppercase rounded text-white py-2 px-5 tracking-wide cursor-pointer transition duration-150 ease-in-out"
                onClick={update}
              >
                Update
              </button>
            </div>
            {/* <div className="font-Helvetica  ">
              <button
                type="reset"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  text-sm bg-legavacYellow font-medium uppercase rounded text-white py-2 px-5 tracking-wide cursor-pointer transition duration-150 ease-in-out"
                onClick={() => {
                  setState({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  });
                }}
              >
                Reset
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
