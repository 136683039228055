import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateSocialDetails,
  saveCandidateSocial,
} from "../../../../config/api";

function SocialLinks() {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  const [link, setLink] = useState([]);
  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    socialProfile: "",
    socialLink: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  //saveCandidateSocial
  // getCandidateSocialDetails

  useEffect(() => {
    GetCandidateSocialDetails(localStorage.getItem("userId"));
  }, [link.id]);

  const funShow = () => {
    setShow(true);
  };

  const GetCandidateSocialDetails = async (id) => {
    await getCandidateSocialDetails(id).then((res) => {
      console.log("get social link data---.", res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(false);
        setLink(res.data);
      } else {
        setHide(true);
        setShow(false);
        setLink(res.data);
      }
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.socialProfile) {
      IsValid = false;
      errors["socialProfile"] = "required";
    } else {
      errors["socialProfile"] = "";
    }
    if (!state.socialLink) {
      IsValid = false;
      errors["socialLink"] = "required";
    } else {
      errors["socialLink"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    

    await saveCandidateSocial(state)
      .then((res) => {
        console.log("social-->", res);
        GetCandidateSocialDetails(localStorage.getItem("userId"));
        setShow(false);
        alert("done");
        setState({
          id: "0",
          CandidateId: state.CandidateId,
          socialProfile: "",
          socialLink: "",
          CreatedBy: state.CreatedBy,
        });
      })
      .catch((error) => {
        alert("error" + error);
      });
  };
  const handleDelete = async (id) => {
    
    await deleteRecord({
      tableName: "CandidateSocial",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        alert("record deleted");
      }
      GetCandidateSocialDetails(localStorage.getItem("userId"));
    });
  };
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Social Links
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Social Profile:
                </p>
                <input
                  type="url"
                  name="socialProfile"
                  id="socialProfile"
                  onChange={handleChange}
                  value={state.socialProfile}
                  placeholder="fb,inst,etc profile url"
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                {state.errors ? (
                  <p className="text-sm text-red-600">
                    {state.errors.socialProfile}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Social Link:
                </p>
                <input
                  type="url"
                  name="socialLink"
                  id="socialLink"
                  onChange={handleChange}
                  value={state.socialLink}
                  placeholder="github,linkedIn,etc url"
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
                {state.errors ? (
                  <p className="text-sm text-red-600">
                    {state.errors.socialLink}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
              {/* <button
                type="button"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacYellow py-2 px-5 rounded text-sm text-white uppercase"
              >
                Delete
              </button> */}
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {link.length &&
              link?.map((l, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div>
                      <div className="font-Helvetica  flex justify-between">
                        <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                          {l.socialProfile}
                        </p>
                      </div>
                      <a
                        href={l.socialLink}
                        target="_blank"
                        className="font-Helvetica  text-sm underline text-blue-500 cursor-pointer"
                      >
                        {l.socialLink}
                      </a>
                      </div>
                      <div className="font-Helvetica  flex space-x-2">
                    <MdEdit
                      size={20}
                      className="font-Helvetica  text-green-600 cursor-pointer"
                      onClick={() => {
                        setShow(true);
                        const editData = link.filter(
                          (data) => data.id === l.id
                        );
                        console.log(editData[0]);
                        setState(editData[0]);
                      }}
                    />
                    <MdDelete
                      size={20}
                      className="font-Helvetica  text-red-600 cursor-pointer"
                      onClick={() => {
                        const DeleteData = link.filter(
                          (data) => data.id === l.id
                        );
                        handleDelete(DeleteData[0].id);
                      }}
                    />
                  </div>
                    </div>
                  </div>
                 
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SocialLinks;
