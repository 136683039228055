import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateWorkExperience,
  saveCandidateWorkExperience,
} from "../../../../config/api";

function WorkExp() {
  const [addExp, setAddExp] = useState([]);
  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    organization: "",
    designation: "",
    currentlyWorking: "",
    fromDate: "",
    toDate: "",
    noticePeriod: "",
    descriptionWork: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  const [experience, setExperience] = useState(false);
  const [yes, setYes] = useState(false);

  const funWorkExp = (e) => {
    debugger;
    alert(e.target.value);
    if (e.target.value === "yes") {
      setState({ ...state, [e.target.name]: e.target.value });
      setExperience(true);
      setYes(true);
    } else if (e.target.value === "no") {
      setState({ ...state, [e.target.name]: e.target.value });
      setExperience(true);
      setYes(false);
    }
  };

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const funShow = () => {
    setShow(true);
  };

  useEffect(() => {
    //getCandidateWorkExperience
    GetCandidateWorkExperience(localStorage.getItem("userId"));
  }, [addExp.id]);

  const GetCandidateWorkExperience = async (id) => {
    await getCandidateWorkExperience(id).then((res) => {
      console.log(res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(false);
        setAddExp(res.data);
      } else {
        setHide(true);
        setAddExp(res.data);
      }
    });
  };

  const [selectedOption, setSelectedOption] = useState("yes");

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setSelectedOption(e.target.value);
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.organization) {
      IsValid = false;
      errors["organization"] = "required";
    } else {
      errors["organization"] = "";
    }
    if (!state.designation) {
      IsValid = false;
      errors["designation"] = "required";
    } else {
      errors["designation"] = "";
    }
    if (!state.currentlyWorking) {
      IsValid = false;
      errors["currentlyWorking"] = "please select option";
    } else {
      errors["currentlyWorking"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    console.log("handle submit", state);
    if (validate()) {
      await saveCandidateWorkExperience(state)
        .then((res) => {
          GetCandidateWorkExperience(localStorage.getItem("userId"));
          setShow(false);
          console.log("experices--->", res);
          alert("done");
          setState({
            id: "0",
            CandidateId: state.CandidateId,
            organization: "",
            designation: "",
            currentlyWorking: "",
            fromDate: "",
            toDate: "",
            noticePeriod: "",
            descriptionWork: "",
            CreatedBy: state.CreatedBy,
          });
        })
        .catch((error) => {
          console.log("work exp error------>", error);
        });
    } else {
      alert("Please select all options");
    }
  };

  const handleDelete = async (id) => {
    await deleteRecord({
      tableName: "WorkExperience",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        alert("record deleted");
        GetCandidateWorkExperience(localStorage.getItem("userId"));
      }
    });
  };
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Work Experience
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Organization:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="organization"
                    id="organization"
                    onChange={handleChange}
                    value={state.organization}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
                {state.errors ? (
                  <p className="font-Helvetica  text-sm text-red-600">
                    {state.errors.organization}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Designation:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <input
                    type="text"
                    name="designation"
                    id="designation"
                    onChange={handleChange}
                    value={state.designation}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
                {state.errors ? (
                  <p className="font-Helvetica  text-sm text-red-600">
                    {state.errors.designation}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <div className="font-Helvetica  col-span-2 space-y-2">
                <div className="font-Helvetica  flex space-x-10 items-center">
                  <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                    Current Working:
                  </p>
                  <div className="font-Helvetica  ">
                    <input
                      className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04] "
                      type="radio"
                      name="currentlyWorking"
                      id="radioDefault01"
                      onClick={funWorkExp}
                      checked={state.currentlyWorking === "yes"}
                      onChange={handleChange}
                      value="yes"
                    />
                    <label
                      className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                      htmlFor="radioDefault01"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="font-Helvetica">
                    <input
                      className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04]"
                      type="radio"
                      name="currentlyWorking"
                      id="radioDefault02"
                      checked={state.currentlyWorking === "no"}
                      onChange={handleChange}
                      onClick={funWorkExp}
                      value="no"
                    />
                    <label
                      className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                      htmlFor="radioDefault02"
                    >
                      No
                    </label>
                  </div>
                </div>
                {state.errors ? (
                  <p className="font-Helvetica  text-sm text-red-600">
                    {state.errors.currentlyWorking}
                  </p>
                ) : (
                  ""
                )}
              </div>
              {experience ? (
                <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      From:
                      <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                        *
                      </span>
                    </p>
                    <div>
                      <input
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        onChange={handleChange}
                        value={state.fromDate}
                        className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                      />
                    </div>
                  </div>
                  <div className="font-Helvetica  space-y-2">
                    <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                      Till:
                      <span className="font-Helvetica  text-pink-600 text-xl pt-5"></span>
                    </p>
                    <div>
                      <input
                        type={yes ? "text" : "date"}
                        placeholder="Present"
                        disabled={yes}
                        value={yes ? (state.toDate = "") : state.toDate}
                        onChange={handleChange}
                        name="toDate"
                        id="toDate"
                        className="font-Helvetica  placeholder:text-sm text-sm placeholder:text-slate-600 border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                      />
                    </div>
                  </div>
                  {yes ? (
                    <div className="font-Helvetica  space-y-2">
                      <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                        Notice Period:
                        <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                          *
                        </span>
                      </p>
                      <div>
                        <select
                          name="noticePeriod"
                          id="noticePeriod"
                          onChange={handleChange}
                          value={state.noticePeriod}
                          className="font-Helvetica  border border-slate-200 text-sm drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                        >
                          <option>--select--</option>
                          <option>Immediately</option>
                          <option>15 Days</option>
                          <option>30 Days</option>
                          <option>45 Days</option>
                          <option>2 Months</option>
                          <option>3 Months</option>
                          <option>6 Months</option>
                        </select>
                      </div>
                      {state.errors ? (
                        <p className="font-Helvetica  text-sm text-red-600">
                          {state.errors.noticePeriod}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="font-Helvetica  grid grid-cols-1 gap-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Description:
                </p>
                <div>
                  <textarea
                    rows={3}
                    name="descriptionWork"
                    id="descriptionWork"
                    onChange={handleChange}
                    value={state.descriptionWork}
                    className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {addExp &&
              addExp?.map((exp, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div>
                        <div className="font-Helvetica  flex justify-between">
                          <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                            {exp.designation}
                          </p>
                        </div>
                        <p className="font-Helvetica  text-sm font-semibold">
                          {exp.organization}
                        </p>
                        <div className="font-Helvetica  flex space-x-1">
                          <p className="font-Helvetica  text-sm text-slate-500">
                            {exp.fromDate}
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-500">
                            -
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-500">
                            {exp.toDate}
                          </p>
                        </div>
                        {!yes ? (
                          <p className="font-Helvetica  text-sm ">
                            {exp.noticePeriod}
                          </p>
                        ) : (
                          ""
                        )}

                        <p className="font-Helvetica  text-sm ">
                          {exp.descriptionWork === "null"
                            ? " "
                            : exp.descriptionWork}
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          size={20}
                          onClick={(e) => {
                            setShow(true);
                            const editData = addExp.filter(
                              (data) => data.id === exp.id
                            );
                            console.log(editData[0]);
                            setState(editData[0]);
                          }}
                        />
                        <MdDelete
                          className="font-Helvetica  text-red-500 cursor-pointer"
                          size={20}
                          onClick={() => {
                            const DeleteData = addExp.filter(
                              (data) => data.id === exp.id
                            );
                            handleDelete(DeleteData[0].id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default WorkExp;
