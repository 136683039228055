import React from "react";
import { IMAGES } from "../../stores";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
function About() {
  const TeamMembers = [
    {
      name: "John Deo",
      designation: "CFD simulation engineer",
    },
    {
      name: "John Deo",
      designation: "Biw product design engineer",
    },
    {
      name: "John Deo",
      designation: "Design engineer",
    },
    {
      name: "John Deo",
      designation: "System engineer",
    },
  ];
  return (
    <>
      <div>
        <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-0">
          <div className="font-Helvetica  grid lg:grid-cols-6  md:grid-cols-6  grid-cols-1  pt-20 gap-x-0 gap-y-10">
            <div className="font-Helvetica   col-span-6 lg:grid pt-10">
              <p className="font-Helvetica text-legavacYellow  text-xl font-medium  text-center">
                Our Company
              </p>
              <p className="font-Helvetica  text-legavacBlue text-2xl font-medium mb-2 text-center">
                Meet Our Team
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <div className="grid grid-cols-4 gap-5 pt-10 place-items-center">
                {TeamMembers.map((t, idx) => (
                  <div className="flex justify-center items-center">
                    <div className="w-full">
                      <div className="w-full flex justify-center items-center py-3">
                        <img
                          src={IMAGES.Profile}
                          className="w-20 h-20  rounded-full"
                        />
                      </div>
                      <p className="text-center text-xl ">{t.name}</p>
                      <p className="text-center   text-legavacYellow text-sm pb-2">
                        {t.designation}
                      </p>
                      <div className="flex justify-center items-center">
                        <div className="font-Helvetica  flex space-x-3 lg:justify-start md:justify-center justify-center">
                          <div className="font-Helvetica  border border-legavacBlue rounded-full p-1.5 cursor-pointer">
                            <FaFacebookF
                              color="blue"
                              size={15}
                              className="font-Helvetica  "
                            />
                          </div>
                          <div className="font-Helvetica  border border-legavacBlue rounded-full p-1.5 cursor-pointer">
                            <FaLinkedinIn
                              color="blue"
                              size={15}
                              className="font-Helvetica  "
                            />
                          </div>
                          <div className="font-Helvetica  border border-legavacBlue rounded-full p-1.5 cursor-pointer">
                            <FaInstagram
                              color="blue"
                              size={15}
                              className="font-Helvetica  "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* // why choose us */}
            <div className="font-Helvetica  lg:col-span-6 md:col-span-6">
              <p className="font-Helvetica text-center  text-2xl font-medium mb-3">
                Our Core Values
              </p>

              <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 text-white">
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.logistics} alt="" />
                  <p className="font-Helvetica  p-5">Be Empathetic</p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.accuracy} alt="" />
                  <p className="font-Helvetica  p-5"> Focus On Growth</p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.management} alt="" />
                  <p className="font-Helvetica  p-5">Be Candid</p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.teamwork} alt="" />
                  <p className="font-Helvetica  p-5">Focus on the customer</p>
                </div>
                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.vacancy} alt="" />
                  <p className="font-Helvetica  p-5">Take charge</p>
                </div>

                <div className="font-Helvetica   flex  text-black items-center">
                  <img src={IMAGES.job} alt="" />
                  <p className="font-Helvetica  p-5">Respect Data</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
