import React from "react";

const Pricing = () => {
  return (
    <>
      <div className="pt-20 px-20">
        <div className="py-10">
        <h3 className="text-4xl font-semibold text-center">Plans</h3>
        </div>
        <div className="grid grid-cols-3 gap-5 ">
          <div className="p-10 border border-legavacYellow rounded shadow-lg ">
            <p className="text-legavacYellow text-3xl text-center font-semibold">
              Basic Plan
            </p>

            <div className="pt-5">
              <p className="text-legavacBlue text-4xl text-center font-semibold py-2">
                ₹ 0
              </p>
              <p className="text-gray-600 text-sm text-center font-semibold">
                Lorem Ispum Lorem Ispum Lorem Ispum Lorem IspumLorem IspumLorem
                IspumLorem IspumLorem Ispum
              </p>
            </div>
            <div className="pt-5 w-full flex justify-center">
              <button className="font-Helvetica tracking-wider bg-legavacBlue px-5 py-2 rounded text-sm text-white font-medium transition duration-150 ease-in-out">
                Buy Now
              </button>
            </div>
          </div>
          <div className="p-10 border border-legavacBlue rounded shadow-lg ">
            <p className="text-legavacYellow text-3xl text-center font-semibold">
              Standard Plan
            </p>

            <div className="pt-5">
              <p className="text-legavacBlue text-4xl text-center font-semibold py-2">
                ₹ 5,000
              </p>
              <p className="text-gray-600 text-sm text-center font-semibold">
                Lorem Ispum Lorem Ispum Lorem Ispum Lorem IspumLorem IspumLorem
                IspumLorem IspumLorem Ispum
              </p>
            </div>
            <div className="pt-5 w-full flex justify-center">
              <button className="font-Helvetica tracking-wider bg-legavacBlue px-5 py-2 rounded text-sm text-white font-medium transition duration-150 ease-in-out">
                Buy Now
              </button>
            </div>
          </div>
          <div className="p-10 border border-rose-500 rounded shadow-lg ">
            <p className="text-legavacYellow text-3xl text-center font-semibold">
              Premium Plan
            </p>

            <div className="pt-5">
              <p className="text-legavacBlue text-4xl text-center font-semibold py-2">
                ₹ 20,000
              </p>
              <p className="text-gray-600 text-sm text-center font-semibold">
                Lorem Ispum Lorem Ispum Lorem Ispum Lorem IspumLorem IspumLorem
                IspumLorem IspumLorem Ispum
              </p>
            </div>
            <div className="pt-5 w-full flex justify-center">
              <button className="font-Helvetica tracking-wider bg-legavacBlue px-5 py-2 rounded text-sm text-white font-medium transition duration-150 ease-in-out">
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
