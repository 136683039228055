import React from "react";
import { IMAGES } from "../../../stores";

function RecruitmentPlatform() {
  return (
    <>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-0 relative lg:pt-28 pt-20">
        <div className="font-Helvetica  mb-10">
          <p className="font-Helvetica  text-3xl  font-QuickStand font-bold text-center pb-3">
            Recruitment Platform{" "}
          </p>

          <hr />
        </div>

        <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 place-items-start gap-x-10 gap-y-10">
          <div className="font-Helvetica  bg-slate-50  h-[280px]  shadow-lg rounded-lg">
            <div className="font-Helvetica   relative py-7 bg-[#001ba1] rounded-t-lg">
              <div className="font-Helvetica  border border-[#001ba1]  w-20 h-20 z-20 bg-[#001ba1] rounded-full absolute p-3 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.AI} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica"> AI & ML Powered Sourcing & Recommendations</p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50  h-[280px]  shadow-lg rounded-lg">
            <div className="font-Helvetica   relative py-7  bg-[#eb8a00] rounded-t-lg">
              <div className="font-Helvetica  border border-[#eb8a00]  w-20 h-20 z-20 bg-[#eb8a00] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.process} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica">
                {" "}
                Recruitment Workflow for Every Stage from Demand Management to
                Onboarding
              </p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50  h-[280px]  shadow-lg rounded-lg">
            <div className="font-Helvetica   relative py-7  bg-[#001ba1] rounded-t-lg">
              <div className="font-Helvetica  border border-[#001ba1]  w-20 h-20 z-20 bg-[#001ba1] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.link} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica">
                {" "}
                Centralized Platform for All Stakeholders - Hiring Managers, TA
                team, Candidates and Vendors
              </p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50 h-[280px]  shadow-lg rounded-lg ">
            <div className="font-Helvetica   relative py-7  bg-[#eb8a00] rounded-t-lg">
              <div className="font-Helvetica  border border-[#eb8a00]  w-20 h-20 z-20 bg-[#eb8a00] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.monitor} alt="" />
              </div>
            </div>
            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica"> Realtime Reports and Dashboards</p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50 h-[280px]  shadow-lg rounded-lg ">
            <div className="font-Helvetica   relative py-7  bg-[#001ba1] rounded-t-lg">
              <div className="font-Helvetica  border border-[#001ba1]  w-20 h-20 z-20 bg-[#001ba1] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.database} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica">Seamless Data Integration with Other Systems</p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50 h-[280px]  shadow-lg rounded-lg ">
            <div className="font-Helvetica   relative py-7  bg-[#eb8a00] rounded-t-lg">
              <div className="font-Helvetica  border border-[#eb8a00]  w-20 h-20 z-20 bg-[#eb8a00] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.notification} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica">Automated Responses, Alerts, SMS, Mailers</p>
            </div>
          </div>
          <div className="font-Helvetica  bg-slate-50 h-[280px]  shadow-lg rounded-lg ">
            <div className="font-Helvetica   relative py-7  bg-[#001ba1] rounded-t-lg">
              <div className="font-Helvetica  border border-[#001ba1]  w-20 h-20 z-20 bg-[#001ba1] rounded-full absolute p-3.5 -bottom-7  left-0 right-0 mx-auto ">
                <img src={IMAGES.webDesign} alt="" />
              </div>
            </div>

            <div className="font-Helvetica  py-20 px-10 text-sm">
              <p className="font-Helvetica">Career Site, Integrated Job Portals & Vendor Portal</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitmentPlatform;
