import React from "react";
import { IMAGES } from "../../stores";
import { MdCall ,MdLocationPin} from "react-icons/md";

function ContactUs() {

  return (
    <>
      <div>
        <div className="font-Helvetica  relative">
          {/* <div className="font-Helvetica  col-span-2 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60">
            <img src={IMAGES.contactUsBg} alt="" className="font-Helvetica  w-full md:min-h-screen h-[1000px]" />
          </div> */}
          
            <div className="font-Helvetica md:flex  pt-24">
              <div className="font-Helvetica bg-legavacYellow md:w-1/2 md:p-20">
                <div className="font-Helvetica  text-white md:text-4xl text-2xl font-bold tracking-wider mb-10 ">
                  <p>CONTACT US</p>
                </div>
                <div className="font-Helvetica  mb-10">
                  <div className="font-Helvetica  flex mb-5  space-x-2">
                    <MdCall color="#fcc512" size={24} />

                    <p className="font-Helvetica  text-white text-lg font-bold tracking-widest">
                      Call Us
                    </p>
                  </div>
                  <p className="font-Helvetica  text-white text-md tracking-widest">
                  +91-7020338633
                  </p>
                </div>
                <div className="font-Helvetica  mb-10">
                  <div className="font-Helvetica  flex mb-5  space-x-2">
                    <MdLocationPin color="#fcc512" size={24} />
                    <p className="font-Helvetica  text-white text-lg font-bold tracking-widest">
                      Location
                    </p>
                  </div>  
                  <p className="font-Helvetica  text-md tracking-wider text-white">
                  Kundan Bhavan, Kundan Nagar, Mumbai-Pune Road, Dapodi, Pune 411012 Maharashtra (IN)
                  </p>
                </div>
              </div>
              <div className="font-Helvetica bg-legavacBlue md:w-1/2  md:p-20">
                <div className="font-Helvetica  grid md:grid-cols-2 gap-5">
                  <div className="font-Helvetica  md:mb-5 mb-2">
                    <label
                      htmlFor="email"
                      className="font-Helvetica  block mb-1 text-xl text-white font-medium text-left"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="font-Helvetica   bg-transparent border-b-white border-b-2   p-2 w-full  block outline:none focus:outline-none text-white"
                      placeholder="Enter Email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="font-Helvetica  md:mb-5 mb-2">
                    <label
                      htmlFor="email"
                      className="font-Helvetica  block mb-1 text-xl text-white font-medium text-left"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="font-Helvetica   bg-transparent  border-b-2   p-2 w-full  block outline:none focus:outline-none text-white"
                      placeholder="Enter Email"
                      name="email"
                      required
                    />
                  </div>
                </div>
                <div className="font-Helvetica  grid grid-cols-1">
                  <div className="font-Helvetica  md:mb-5 mb-2">
                    <label
                      htmlFor="address"
                      className="font-Helvetica  block mb-1 text-xl text-white font-medium text-left"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      className="font-Helvetica   bg-transparent  border-b-2   p-2 w-full  block outline:none focus:outline-none  text-white"
                      placeholder="Enter address"
                      name="address"
                      required
                    />
                  </div>
                </div>
                <div className="font-Helvetica  grid grid-cols-1">
                  <div className="font-Helvetica  md:mb-5 mb-2">
                    <label
                      htmlFor="message"
                      className="font-Helvetica  block mb-1 text-xl text-white font-medium text-left"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      className="font-Helvetica   bg-transparent border-b-le border-b-2   p-2 w-full  block outline:none focus:outline-none text-white"
                      placeholder="Enter message"
                      name="message"
                      required
                    />
                  </div>
                </div>
                
                <div className="font-Helvetica  grid grid-cols-1 place-items-center pt-5">
                  <button
                    type="button"
                   data-te-ripple-init
                  data-te-ripple-color="light"
                    className="font-Helvetica  bg-legavacYellow font-semibold rounded text-white py-1 px-10 uppercase cursor-pointer transition duration-150 ease-in-out"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
         
        </div>
      </div>
    </>
  );
}

export default ContactUs;
