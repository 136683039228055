import React from "react";
import { IMAGES } from "../../../stores";

function ChannelSourcing() {
 
  return (
    <div>
      <div className="font-Helvetica  container mx-auto lg:px-20 md:px-10 px-0 relative lg:pt-28 pt-20">
        <div className="font-Helvetica  mb-10">
          <p className="font-Helvetica  text-3xl  font-QuickStand font-bold text-center pb-3">
            Muilt - Channel Sourcing
          </p>
          <p className="font-Helvetica   text-2xl font-QuickStand font-semibold text-center pb-3">
            Candidate Sourcing Channel
          </p>
          <hr />
        </div>
        <div className="font-Helvetica  grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2  gap-5">
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.marketResearch} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>

              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">01</p>
              <p className="font-Helvetica  font-semibold">MARKET RESEARCH</p>
            </div>
          </div>
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.presentations} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>
              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">02</p>
              <p className="font-Helvetica  "><span className="font-Helvetica  font-semibold ">JOB BOARDS</span> Traditional & New-Age</p>
            </div>
          </div>
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.socialmedia} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>
              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">03</p>
              <p className="font-Helvetica">
                <span className="font-Helvetica  font-semibold">SOCIAL MEDIA (SEARCH)</span>
                <p className="font-Helvetica">Traditional & New-Age</p>
              </p>
            </div>
          </div>
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.cv} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>
              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">04</p>
              <p className="font-Helvetica">
                <span className="font-Helvetica  font-semibold">RESUME DATABASE</span> Internal
                Database CRM & ATS
              </p>
            </div>
          </div>
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.networking} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>
              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">05</p>
              <p className="font-Helvetica">
                <span className="font-Helvetica  font-semibold">NETWORKING Referrals</span>
                <p className="font-Helvetica"> Industry Sponsorship, LinkedIn Groups</p>
                etc.
              </p>
            </div>
          </div>
          <div>
            <div className="font-Helvetica  grid place-items-center">
              <div className="font-Helvetica  rounded-full border-[12px] border-[#eb8a00] p-5">
                <img src={IMAGES.socialadreach} className="font-Helvetica   w-14 h-14 " alt="" />
              </div>
              <div className="font-Helvetica  border-l-[3px] border-dashed border-slate-400 h-20 mb-1 "></div>
              <div className="font-Helvetica   bg-[#001ba1] rounded-full w-5 h-5  border-2 border-black"></div>
            </div>
            <div className="font-Helvetica  text-center pt-4">
              <p className="font-Helvetica  text-xl text-[#001ba1] font-bold">06</p>
              <p className="font-Helvetica">
                <span className="font-Helvetica  font-bold">OTHER ADVERTISING </span>Print Media
                Ads : & SEO/SEM
              </p>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChannelSourcing;
