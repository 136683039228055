import React, { useEffect, useState } from "react";
import { MdSearch, MdAdd, MdRemove } from "react-icons/md";
import {
  getCities,
  getCompanyName,
  getDesignation,
  getExperience,
  getMasterEducation,
  getMasterSkills,
  getSalary,
} from "../../../config/api";
import { useNavigate } from "react-router-dom";

function AdvanceSearch() {
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const [postedJobFilter, setPostedJobFilter] = useState({
    companyName: "",
    designation: "",
    city: "",
    experience: "",
  });

  useEffect(() => {
    GetExperience(0);
  }, []);

  const [experienceData, setExperienceData] = useState([]);
  const GetExperience = async (id) => {
    await getExperience(id).then((res) => {
      console.log("get data experience---->", res.data);
      setExperienceData(res.data);
    });
  };

  useEffect(() => {
    GetDesignation(0);
  }, []);

  const [jobTitleData, setJobTitleData] = useState([]);
  const GetDesignation = async (id) => {
    await getDesignation(id).then((res) => {
      console.log("get data designation---->", res.data);
      setJobTitleData(res.data);
    });
  };

  useEffect(() => {
    GetMasterSkills(0);
  }, []);

  const [skillsData, setSkillsData] = useState([]);
  const GetMasterSkills = async (id) => {
    await getMasterSkills(id).then((res) => {
      console.log("get data skills---->", res.data);
      setSkillsData(res.data);
    });
  };

  useEffect(() => {
    GetSalary(0);
  }, []);

  const [salaryData, setSalaryData] = useState([]);
  const GetSalary = async (id) => {
    await getSalary(id).then((res) => {
      console.log("get data salary---->", res.data);
      setSalaryData(res.data);
    });
  };

  useEffect(() => {
    GetMasterEducation(0);
  }, []);

  const [educationData, setEducationData] = useState([]);
  const GetMasterEducation = async (id) => {
    await getMasterEducation(id)
      .then((res) => {
        console.log("get data education---->", res.data);
        setEducationData(res.data);
      })
      .catch((error) => {
        console.log("erroor-<", error);
      });
  };

  useEffect(() => {
    GetCompanyName(0);
  }, []);

  const [companyNameData, setCompanyNameData] = useState([]);
  const GetCompanyName = async (id) => {
    await getCompanyName(id).then((res) => {
      console.log("get data company name---->", res.data);
      setCompanyNameData(res.data);
    });
  };

  useEffect(() => {
    GetCityName(0);
  }, []);

  const [cityData, setCityData] = useState([]);
  const GetCityName = async (id) => {
    await getCities(id).then((res) => {
      console.log("get data company name---->", res.data);
      setCityData(res.data);
    });
  };

  const [checkboxValuesLocation, setCheckboxValuesLocation] = useState([]);
  const handleCheckboxChangeLocation = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesLocation([...checkboxValuesLocation, value]);
      showFilterData();
    } else {
      setCheckboxValuesLocation(
        checkboxValuesLocation.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesLocation);
    console.log("checkboxValuesLocation", checkboxValuesLocation);
  };

  const [checkboxValuesExpirence, setCheckboxValuesExpirence] = useState([]);
  const handleCheckboxChangeExpirence = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesExpirence([...checkboxValuesExpirence, value]);
    } else {
      setCheckboxValuesExpirence(
        checkboxValuesExpirence.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesExpirence);
    console.log("checkboxValuesLocation", checkboxValuesExpirence);
  };

  // use designation
  const [checkboxValuesDesignation, setCheckboxValuesDesignation] = useState(
    []
  );
  const handleCheckboxChangeDesignation = (event) => {
    debugger;
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesDesignation(event.target.value);
      showFilterData();
    } else {
      setCheckboxValuesDesignation(
        checkboxValuesDesignation.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesDesignation);
    console.log("checkboxValuesDesignation", checkboxValuesDesignation);
  };

  const jobtype = [
    {
      job: "Full Time",
    },
    {
      job: "Part Time",
    },
  ];

  const shifts = [
    {
      shift: "Day Shift",
    },
    {
      shift: "Night Shift",
    },
    {
      shift: "Hybrid",
    },
  ];

  const postedby = [
    {
      post: "Company Jobs",
      postcount: "43163",
    },
    {
      post: "Consultant Jobs",
      postcount: "11521",
    },
  ];

  const navigate = useNavigate();
  const showFilterData = () => {
    // <AdvanceJobSearch data={GetPostedFilter(searchValue)}/>
    navigate("/app/advance-job-search", {
      state: {
        companyName: "",
        designation: checkboxValuesDesignation,
        city: checkboxValuesLocation,
        experience: "",
        isState: true,
      },
    });
  };
  return (
    <>
      <div className="font-Helvetica  submenu-list divide-y">
        {/*Location*/}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(0)}
          >
            <p className="font-Helvetica  font-medium">Location</p>

            {subMenuOpen === 0 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 0 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {cityData?.map((l, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input
                      type="checkbox"
                      value={l.city}
                      onChange={handleCheckboxChangeLocation}
                    />
                    <p className="font-Helvetica  text-sm">{l.city}</p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">({l.count})</p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Experience */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(1)}
          >
            <p className="font-Helvetica  font-medium">Experience</p>

            {subMenuOpen === 1 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {experienceData?.map((e, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5" key={idx}>
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{e.experience}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Skills */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(2)}
          >
            <p className="font-Helvetica  font-medium">Role/Designation</p>

            {subMenuOpen === 2 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {jobTitleData?.map((r, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input
                      type="checkbox"
                      value={r.designation}
                      onChange={handleCheckboxChangeDesignation}
                    />
                    <p className="font-Helvetica  text-sm">{r.designation}</p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">({r.rolecount})</p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(12)}
          >
            <p className="font-Helvetica  font-medium">Skills</p>

            {subMenuOpen === 12 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 12 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {skillsData?.map((s, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{s.skill}</p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">({s.skillcount})</p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
        {/* Salary */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(3)}
          >
            <p className="font-Helvetica  font-medium">Salary</p>

            {subMenuOpen === 3 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {salaryData?.map((s, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{s.salary}</p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">({s.salarycount})</p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Company Type */}
        {/* <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(4)}
          >
            <p className="font-Helvetica  font-medium">Company Type</p>

            {subMenuOpen === 4 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 4 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {companyType?.map((c, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{c.cotype}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({c.cocount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div> */}

        {/* Education */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(5)}
          >
            <p className="font-Helvetica  font-medium">Qualification</p>

            {subMenuOpen === 5 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 5 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {educationData?.map((e, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2 items-start">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{e.education}</p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">({e.educount})</p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Companies */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(6)}
          >
            <p className="font-Helvetica  font-medium">Companies</p>

            {subMenuOpen === 6 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 6 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
              {companyNameData?.map((comp, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">
                      {comp.companyName}
                    </p>
                  </div>
                  <div>
                    {/* <p className="font-Helvetica  text-sm">
                      ({comp.compcount})
                    </p> */}
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Job Type */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(8)}
          >
            <p className="font-Helvetica  font-medium">Job Type</p>

            {subMenuOpen === 8 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 8 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {jobtype?.map((j, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5" key={idx}>
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{j.job}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Shift */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(9)}
          >
            <p className="font-Helvetica  font-medium">Shift</p>

            {subMenuOpen === 9 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 9 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {shifts?.map((sh, idx) => (
                <li className="font-Helvetica  menu-item pt-0.5" key={idx}>
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{sh.shift}</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Work Mode */}
        {/* <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(10)}
          >
            <p className="font-Helvetica  font-medium">Work Mode</p>

            {subMenuOpen === 10 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 10 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {work?.map((w, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{w.mode}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({w.modecount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div> */}

        {/* Posted by */}
        <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(11)}
          >
            <p className="font-Helvetica  font-medium">Posted by</p>

            {subMenuOpen === 11 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 11 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {postedby?.map((by, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{by.post}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({by.postcount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>

    </>
  );
}

export default AdvanceSearch;
