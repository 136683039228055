import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDesignation, getExperience } from "../config/api";
import Signup from "../pages/auth/Signup";

function AdvSearchSection() {
  const navigate = useNavigate();

  // const [postedJobFilter, setPostedJobFilter] = useState({
  //   companyName: '',
  //   designation: '',
  //   city: '',
  //   experience: ''
  // })

  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML =
      '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

  const [file, setFile] = useState(null);
  const [fileResume, setFileResume] = useState(null);

  const handleFileChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFileResume(uploadedFile);
  };
  // const handleDownload = () => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(fileResume);
  //   downloadLink.download = fileResume.name;
  //   downloadLink.click();
  // };
  const [jobTitleOption, setJobTitleOption] = useState([]);
  const [jobExperienceOption, setJobExperienceOption] = useState([]);

  const [searchValue, setSearchValue] = useState({
    designation: "",
    // city: "",
    experience: "",
  });
  const city = [
    { city: "Ahmadnagar" },
    { city: "Akola" },
    { city: "Amravati" },
    { city: "Aurangabad" },
    { city: "Bhandara" },
    { city: "Bhusawal" },
    { city: "Bid" },
    { city: "Buldhana" },
  ];

  useEffect(() => {
    GetDesignation(0);
  }, []);

  const GetDesignation = async (id) => {
    await getDesignation(id)
      .then((res) => {
        console.log("get designation->", res);
        setJobTitleOption(res.data);
      })
      .catch((error) => {
        console.log("error->", error);
      });
  };

  useEffect(() => {
    GetExperience(0);
  }, []);

  const GetExperience = async (id) => {
    await getExperience(id)
      .then((res) => {
        console.log("get experience->", res);
        setJobExperienceOption(res.data);
      })
      .catch((error) => {
        console.log("error->", error);
      });
  };

  const handleChange = (e) => {
    setSearchValue({ ...searchValue, [e.target.name]: e.target.value });
    console.log("state-->", searchValue);

    setSearchValue((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const showFilterData = () => {
    // <AdvanceJobSearch data={GetPostedFilter(searchValue)}/>
    navigate("/app/advance-job-search", {
      state: {
        companyName: "",
        designation: searchValue.designation,
        city: "",
        experience: searchValue.experience,
        isState: true,
      },
    });
  };

  return (
    <>
      <div className="font-Helvetica  relative pt-20">
        {/* <div className="font-Helvetica  w-full after:absolute relative after:inset-0 after:bg-black after:opacity-0">
          <img
            src={IMAGES.HomeImg}
            alt=""
            className="font-Helvetica  w-full lg:h-fit md:h-[600px] h-[700px]"
          />
        </div> */}

        {/* <div className="font-Helvetica absolute top-40 left-0 right-0 z-10"> */}
        <div className="font-Helvetica bg-[url('/src/assets/images/Bg-Img.jpg')] w-full lg:h-[500px] md:h-[500px] bg-cover">
          <div className="font-Helvetica  container mx-auto w-full lg:px-20 md:px-10 px-0 py-12">
            <p className="font-QuickStand pb-10 text-white lg:text-5xl md:text-3xl text-2xl font-bold text-center">
              Welcome to{" "}
              <span
                className="font-QuickStand txt-rotate text-legavacYellow"
                data-period="2000"
                data-rotate='[ "ITOne !", "ITOne !"]'
              ></span>
            </p>
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 md:gap-x-5 gap-y-10 lg:w-3/4">
              <div className="font-Helvetica  lg:pr-16 md:pr-0 pr-0">
                <div className="font-Helvetica  shadow-2xl shadow-black border rounded p-10 space-y-5">
                  <div className="font-Helvetica  relative z-10">
                    <div>
                      <select
                        className="font-Helvetica  bg-transparent py-2 text-sm text-white w-full border-b-[2px] border-white hover:outline-none focus:outline-none"
                        name="designation"
                        id="designation"
                        onChange={handleChange}
                        value={searchValue.designation}
                      >
                        <option className="font-Helvetica  text-sm text-legavacBlue ">
                          Select designation
                        </option>
                        {jobTitleOption?.map((j, idx) => (
                          <option
                            key={idx}
                            className="font-Helvetica text-legavacBlue  text-sm"
                          >
                            {j.designation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <select
                      className="font-Helvetica  bg-transparent py-2 text-sm text-white w-full border-b-[2px] border-white hover:outline-none focus:outline-none"
                      name="city"
                      id="city"
                      onChange={handleChange}
                      value={searchValue.city}
                    >
                      <option className="font-Helvetica  text-sm text-legavacBlue ">
                        Select city
                      </option>
                      {city?.map((c, idx) => (
                        <option
                          key={idx}
                          className="font-Helvetica text-legavacBlue  text-sm"
                        >
                          {c.city}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="font-Helvetica  relative z-0">
                    <select
                      className="font-Helvetica  bg-transparent py-2 text-sm text-white w-full border-b-[2px] border-white hover:outline-none focus:outline-none"
                      name="experience"
                      id="experience"
                      onChange={handleChange}
                      value={searchValue.experience}
                    >
                      <option className="font-Helvetica  text-sm text-legavacBlue ">
                        Select Experience
                      </option>
                      {jobExperienceOption?.map((e, idx) => (
                        <option
                          key={idx}
                          className="font-Helvetica text-legavacBlue  text-sm"
                        >
                          {e.experience}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="font-Helvetica  pt-2">
                    <button
                      type="button"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="font-Helvetica  bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-medium rounded w-full p-2 transition duration-150 ease-in-out"
                      onClick={showFilterData}
                      //onClick={() => navigate("/app/advance-job-search")}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="font-Helvetica grid place-items-center">
                <div className="lg:space-y-5 md:space-y-10 space-y-5 w-full grid place-items-center">
                  <button
                    className="font-Helvetica text-sm bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-medium rounded py-2 px-5 transition duration-150 ease-in-out"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={() => navigate("/app/candidates")}
                  >
                    Hire Candidate
                  </button>
                  <p
                    className="font-Helvetica  lg:text-lg md:text-lg text-sm text-white font-medium text-center relative
                          before:absolute before:top-1/2 lg:before:left-10 md:before:left-2 before:left-8 lg:before:w-24 md:before:w-28 before:w-20 before:h-0.5 before:bg-white
                          after:absolute after:top-1/2 lg:after:right-10 md:after:right-2 after:right-8 lg:after:w-24 md:after:w-28 after:w-20 after:h-0.5 after:bg-white"
                  >
                    OR
                  </p>
                  <div>
                    <label
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      className="font-Helvetica text-center text-sm cursor-pointer bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-medium rounded py-2 px-5 transition duration-150 ease-in-out"
                      // htmlFor="file_input"
                      onClick={() => navigate("/app/advance-job-search")}
                    >
                      Apply For Job
                    </label>
                  </div>
                  <div>
                    <input
                      className="w-full hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      id="file_input"
                      type="file"
                      onChange={handleFileUpload}
                    />
                    {fileResume && (
                      <div className="space-y-2 grid place-items-center">
                        <p className="text-white text-sm cursor-pointer">
                          {fileResume.name}
                        </p>
                        {/* <button
                        className="font-Helvetica text-sm flex space-x-2 justify-center text-white font-medium bg-green-600 px-5 py-1 rounded"
                        onClick={handleDownload}
                        disabled={!fileResume}
                      >
                        <p>Download</p><MdDownload size={20}/>
                      </button> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvSearchSection;
