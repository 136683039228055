import React, { useState } from "react";
import {
  MdOutlineVerified,
  MdClose,
  MdDownload,
  MdMailOutline,
  MdOutlinePhone,
} from "react-icons/md";
import { IMAGES } from "../stores";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CandidatesDetails = () => {
  const [show, setShow] = useState(false);
  const funShow = () => {
    setShow(true);
  };
  const funHide = () => {
    setShow(false);
  };

  const [value, onChange] = useState(new Date());
  return (
    <>
      <div className="container mx-auto px-20 pt-20">
        <div className="pointer-events-auto relative flex w-full flex-col  rounded-md bg-white bg-clip-padding text-current shadow-lg outline-none min-[0px]:h-full min-[0px]:rounded-none min-[0px]:border-0">
          <div className="flex justify-between items-center border-b p-5">
            <div>
              <p className="font-Helvetica  font-QuickStand font-semibold text-legavacBlue lg:text-3xl md:text-2xl text-2xl text-start">
                Candidate's Profile
              </p>
            </div>
            <button
              data-te-ripple-init
              data-te-ripple-color="light"
              className="font-Helvetica text-center flex space-x-2 items-center text-sm cursor-pointer bg-green-600 tracking-wider shadow shadow-slate-600 text-white font-medium rounded py-2 px-5 transition duration-150 ease-in-out"
              // htmlFor="file_input"
            >
              <MdDownload size={20} />
              <p className="font-Helvetica">Download Resume</p>
            </button>
          </div>
          <div className="grid grid-cols-6">
            <div className="col-span-2 bg-slate-50 p-10">
              {/* Basic Info */}
              <div>
                <div className="">
                  <div className="grid place-items-center">
                    <img src={IMAGES.Profile} alt="" className="w-52 h-52" />
                    <div className="flex items-center space-x-1 pt-3">
                      <img src={IMAGES.Batch} alt="" className="w-20 " />
                      <p className="text-green-600 font-medium text-sm">
                        Verified
                      </p>
                      <MdOutlineVerified
                        size={20}
                        className="text-green-600 "
                      />
                    </div>
                  </div>
                  <div className="pt-5 space-y-2">
                    <div className="flex justify-between items-center relative">
                      <p className="text-xl font-semibold text-legavacBlue">
                        Dipak Patil
                      </p>

                      <p
                        className="font-Helvetica text-legavacYellow  cursor-pointer hover:underline"
                        onClick={funShow}
                      >
                        Check Availability
                      </p>

                      {show ? (
                        <div className="absolute right-0 top-[30px]">
                          <MdClose
                            className="absolute right-0 text-legavacYellow"
                            onClick={funHide}
                          />
                          <Calendar onChange={onChange} value={value} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="flex space-x-2 items-center">
                        <MdOutlineManageAccounts
                          size={20}
                          className="text-slate-600"
                        />
                        <p className="text-xs font-medium">Designation</p>
                      </div>
                      <div className="flex space-x-2 items-center">
                        <VscBriefcase size={20} className="text-slate-600" />
                        <p className="text-xs font-medium">
                          Freshers / Experience
                        </p>
                      </div> */}
                    <div className="flex space-x-2 items-center">
                      <MdOutlinePhone size={20} className="text-slate-600" />
                      <p className="text-xs font-medium">+91 9876543219</p>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <MdMailOutline size={20} className="text-slate-600" />
                      <p className="text-xs font-medium">
                        dipakpatil@gmail.com
                      </p>
                    </div>
                    {/* <div className="flex items-start">
                        <div>
                          <MdOutlineLocationOn
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                        </div>
                        <div className="pl-2">
                          <p className="text-xs font-medium">
                            {viewdata[0].fullAddress}, {viewdata[0].city},{" "}
                            {viewdata[0].district}, {viewdata[0].state},{" "}
                            {viewdata[0].country}
                          </p>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
              {/* Contact Info */}
              <div className="py-5">
                <p className="text-xl font-semibold text-legavacBlue py-2">
                  Contact Information
                </p>
                <div className="space-y-2">
                  <div className="flex space-x-2 items-start">
                    <p className="text-sm text-slate-700 font-semibold">
                      Full Address:
                    </p>
                    <p className="text-xs">
                      Kalptaru Society, Pimple Gurav, Pune
                    </p>
                  </div>

                  <div className="flex space-x-2 items-center">
                    <p className="text-sm text-slate-700 font-semibold">
                      City:
                    </p>
                    <p className="text-xs">Pune</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <p className="text-sm text-slate-700 font-semibold">
                      District:
                    </p>
                    <p className="text-xs">Pune</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <p className="text-sm text-slate-700 font-semibold">
                      State:
                    </p>
                    <p className="text-xs">Maharashtra</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <p className="text-sm text-slate-700 font-semibold">
                      Country:
                    </p>
                    <p className="text-xs">India</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <p className="text-sm text-slate-700 font-semibold">
                      Pin Code:
                    </p>
                    <p className="text-xs">426262</p>
                  </div>
                </div>
              </div>
              {/* Skills */}
              <div className="pb-5">
                <p className="text-xl font-semibold text-legavacBlue">Skills</p>

                <table className="">
                  {/* <thead>
                      <th className="pr-5 py-1 text-left text-sm text-slate-700 font-semibold">
                        Skills
                      </th>
                      <th className="pr-5 py-1 text-left text-sm text-slate-700 font-semibold">
                        Levels
                      </th>
                    </thead> */}
                  <tbody>
                    <tr>
                      <td className="pr-5 py-1 text-xs">React</td>
                      <td className="pr-5 py-1 text-xs">Advance</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Social Links */}
              <div className="pb-5">
                <p className="text-xl font-semibold text-legavacBlue">
                  Social Links
                </p>

                <div className="">
                  {/* <thead>
                      <th className="pr-5 py-1 text-left text-sm text-slate-700 font-semibold">
                        Social Profile
                      </th>
                      <th className="pr-5 py-1 text-left text-sm text-slate-700 font-semibold">
                        Social Link
                      </th>
                    </thead> */}
                  <div>
                    <div className="py-2">
                      <p className="text-xs">Facebook</p>
                      <p className="text-xs text-blue-700 underline">
                        https://www.facebook.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4 pt-5 pb-10 px-10 divide-y">
              {/* Education Details */}
              <div className="py-2">
                <p className="text-xl font-semibold text-legavacBlue">
                  Education Details
                </p>

                <div className="py-1 grid grid-cols-5 items-start">
                  <div>
                    <p className="text-sm">2017</p>
                  </div>
                  <div className="col-span-4">
                    <p className="text-base font-semibold">
                      Bachelor of Engineer
                    </p>

                    <p className="text-sm">Savitribai Phule University, Pune</p>

                    <p className="text-sm">90%</p>
                  </div>
                </div>
              </div>
              {/* Work Experience */}
              <div className="py-2">
                <p className="text-xl font-semibold text-legavacBlue">
                  Work Experience
                </p>

                <div className="py-1 grid grid-cols-5 items-start">
                  <div className="">
                    <p className="text-xs">21/01/2020 -</p>
                    <p className="text-xs">21/01/2022</p>
                  </div>

                  <div className="col-span-4">
                    <p className="text-base font-semibold">Wipro</p>
                    <p className="text-xs">CAE Engineer</p>
                    <p className="text-sm text-gray-700">
                      Lorem Ispump Lorem Ispump Lorem Ispump Lorem Ispump Lorem
                      Ispump Lorem Ispump Lorem Ispump
                    </p>
                  </div>
                </div>
              </div>
              {/* Projects */}
              <div className="py-2">
                <p className="text-xl font-semibold text-legavacBlue">
                  Projects
                </p>

                <div className="py-1 grid grid-cols-5 items-start">
                  <div>
                    <p className="text-xs">21/01/2020 -</p>
                    <p className="text-xs">21/01/2022</p>
                  </div>
                  <div className="col-span-4">
                    <p className="text-base font-semibold">ITOne Infotech</p>
                    <p className="text-xs">Raj Malhotra</p>
                    <p className="text-sm text-gray-700">
                      Lorem Ispump Lorem Ispump Lorem Ispump Lorem Ispump Lorem
                      Ispump Lorem Ispump Lorem Ispump
                    </p>
                  </div>
                </div>
              </div>
              {/* Courses and Certificates */}
              <div className="py-2">
                <p className="text-xl font-semibold text-legavacBlue">
                  Courses and Certificates
                </p>

                <div className="py-1 grid grid-cols-5 items-start">
                  <div>
                    <p className="text-xs">21/01/2022</p>
                  </div>
                  <div className="col-span-4">
                    <p className="text-base font-semibold">CAE Engineer</p>
                    <p className="text-xs">ITOne Infotech</p>
                  </div>
                </div>
              </div>
              {/* Awards and Archivement */}
              <div className="py-2">
                <p className="text-xl font-semibold text-legavacBlue">
                  Awards and Archivement
                </p>

                <div className="py-1">
                  <div className="grid grid-cols-5">
                    <div>
                      <p className="text-xs">21/01/2022</p>
                    </div>
                    <div className="col-span-4">
                      <p className="text-base font-semibold">
                        Best Employee of the Year
                      </p>
                      <p className="text-sm text-gray-700">
                        Lorem Ispump Lorem Ispump Lorem Ispump Lorem Ispump
                        Lorem Ispump Lorem Ispump Lorem Ispump
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatesDetails;
