import React from "react";

function JobDesc() {
  return (
    <>
      <div className="font-Helvetica  space-y-3">
        <p className="font-Helvetica  text-black font-semibold">Job Description</p>
        <p className="font-Helvetica  text-sm">
          {/* {props.desc} */}

          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas semper orci vitae arcu tincidunt, sit amet elementum dolor tincidunt. Maecenas felis elit, pharetra quis nunc vitae, cursus vestibulum nisi. Quisque in ultricies libero. Sed gravida purus in facilisis faucibus. Maecenas mollis tortor nibh, at rhoncus nibh accumsan vel. Donec mollis tempus nisi, non tempus lorem. Nulla facilisi. Praesent et erat ullamcorper enim sollicitudin euismod. In hac habitasse platea dictumst. Vestibulum dapibus diam felis, non iaculis tellus suscipit at. Vivamus ac nulla in elit rhoncus faucibus. Maecenas ac purus sed massa congue dignissim id nec metus.

Aliquam ut leo pretium, lacinia odio et, semper justo. Suspendisse a lorem vehicula, sagittis neque eget, bibendum enim. Aliquam nec ullamcorper tellus. Suspendisse rhoncus risus ac scelerisque ultricies. Praesent tincidunt turpis eget dictum fermentum. Proin mi velit, ornare sit amet arcu elementum, interdum fermentum ex. Curabitur eget scelerisque dui. Aenean lorem odio, molestie sit amet nulla eget, efficitur bibendum metus. Phasellus euismod bibendum diam a mattis. Vestibulum ultrices nunc laoreet lacinia consectetur. Curabitur quis aliquam eros. Aenean quis mauris facilisis est sagittis fermentum id ut urna. Integer blandit pellentesque scelerisque. Pellentesque feugiat mauris non justo molestie iaculis.
        </p>
      </div>
    </>
  );
}

export default JobDesc;
