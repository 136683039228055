import React, { useState } from "react";
import {
  MdArrowDropDown,
  MdClose,
  MdMenu,
  MdMoreVert,
  MdOutlineLogout,
  MdOutlineSettings,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../stores";
import {
  IoMdInformationCircleOutline,
  IoMdNotificationsOutline,
} from "react-icons/io";
import Notifications from "../pages/sub/Notifications";
import { VscAccount, VscBriefcase, VscHeart, VscKey } from "react-icons/vsc";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiHome } from "react-icons/bi";

function Header() {
  // const role = JSON.parse(localStorage.getItem("role"));

  const [color, setColor] = useState(false);
  const [iconColor, setIconColor] = useState(false);

  const navigate = useNavigate();

  const seessionData = {
    isLogin: sessionStorage.getItem("isLogInCheck"),
  };

  console.log("seessionData", seessionData);
  const btnLogout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };

  const profile = JSON.parse(localStorage.getItem("profileData"));

  return (
    <>
      <div className="font-Helvetica  fixed top-0 z-50 w-full bg-white text-white p-10 transition-all ease-in-out duration-200 shadow-sm shadow-slate-200">
        <div className="font-Helvetica  absolute top-0 md:left-0 right-5 py-5 container w-full mx-auto flex justify-between items-center lg:px-20 md:px-10 px-3 text-white">
          <div className="font-Helvetica  flex items-center lg:space-x-10">
            <div className="font-Helvetica  ">
              <Link to="/">
                <img
                  src={IMAGES.itonelogo}
                  alt=""
                  className="font-Helvetica  w-32"
                />
              </Link>
            </div>

            <div className="font-Helvetica  font-quickStand lg:block md:hidden hidden">
              <ul className={color ? " color color-active" : "color"}>
                {/* <li className="font-Helvetica  font-semibold text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/" className="font-Helvetica">
                    Home
                  </a>
                </li> */}
                {/* {role === "candidate" ? ( */}
                <li className="font-Helvetica  font-medium text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/app/about-us" className="font-Helvetica">
                    {/* About Us */}Why ITOne
                  </a>
                </li>

                <li className="font-Helvetica  font-medium text-md hover:underline hover:underline-offset-4 focus:underline cursor-pointer transition ease-in duration-700">
                  <a href="/app/advance-job-search" className="font-Helvetica">
                    Jobs
                  </a>
                </li>

                <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-medium text-md">
                        Hire Top Talents
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="#"
                        >
                          CAE engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="#"
                        >
                          CFD simulation engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          BIW product design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          BIW fixture design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Verification and validation engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Lighting engineer ( Exterior trims)
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          System Engineer
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-medium text-md">
                        Projects
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="#"
                        >
                          CAE engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="#"
                        >
                          CFD simulation engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          BIW product design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          BIW fixture design engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Verification and validation engineer
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          Lighting engineer ( Exterior trims)
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="#"
                        >
                          System Engineer
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-medium text-md">
                        Resources
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="#"
                        >
                          How to Guides
                        </a>
                      </li>

                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/app/article"
                        >
                          Blogs
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="/app/ServiceWeOffer"
                        >
                          Articles
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-medium text-md">
                        Contact Us
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/app/pricing"
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/app/contact-us"
                        >
                          Contact Sales
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                {/* {role === "recruiter" ? ( */}
                {/* <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700 "
                      type="button"
                    >
                      <p className="font-Helvetica  font-semibold text-md">
                        More
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-2 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/app/article"
                        >
                          Article
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="/app/careeradvice"
                        >
                          Career Advice
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}

                {/* ) : (
                  ""
                )} */}
              </ul>
            </div>
          </div>

          <div
            className="font-Helvetica  inline-block space-x-1 items-center pt-2 lg:hidden md:block transition-all ease-in-out duration-700"
            aria-expanded="false"
            data-te-offcanvas-toggle
            type="button"
            data-te-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            <MdMenu
              size={30}
              className={
                iconColor ? "icon-color icon-color-active" : "icon-color"
              }
            />
          </div>

          <div className="font-Helvetica  space-x-3 lg:flex md:hidden hidden items-center">
            {seessionData.isLogin === "1" ? (
              <>
                <div className="font-Helvetica  dropdown inline-block relative">
                  <button className="font-Helvetica  flex space-x-2 items-center">
                    <img
                      src={IMAGES.Profile2}
                      alt=""
                      className="font-Helvetica  w-10 h-10 rounded-full"
                    />
                  </button>
                  <ul className="font-Helvetica  dropdown-menu rounded drop-shadow bg-white absolute -right-10 hidden w-60 text-gray-700 pt-1">
                    <li className="font-Helvetica  border-b">
                      <div className="font-Helvetica  flex space-x-3 py-2 px-4 whitespace-no-wrap">
                        <img
                          src={IMAGES.Profile2}
                          alt=""
                          className="font-Helvetica  w-10 h-10 rounded-full"
                        />
                        <div>
                          <p className="font-Helvetica  font-medium text-legavacBlue text">
                            {/* {Object.values(profile.firstName)}{" "}
                            {Object.values(profile.lastName)} */}
                            Dipak Patil
                          </p>
                          <p className="font-Helvetica  text-xs text-gray-400">
                            Web Developer
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="font-Helvetica  ">
                      <a
                        className="font-Helvetica  text-sm hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap cursor-pointer"
                        href="/profile/my-profile"
                      >
                        View Profile
                      </a>
                    </li>
                    <li className="font-Helvetica  ">
                      <a
                        className="font-Helvetica  text-sm hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap cursor-pointer"
                        href="/profile/create-job-alert"
                      >
                        Create Job Alert
                      </a>
                    </li>
                    <li className="font-Helvetica  ">
                      <a
                        className="font-Helvetica  text-sm hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap cursor-pointer"
                        onClick={btnLogout}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="font-Helvetica  relative" data-te-dropdown-ref>
                  <a
                    className="font-Helvetica  hidden-arrow flex items-center text-slate-500"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasNotification"
                    aria-controls="offcanvasNotification"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    <IoMdNotificationsOutline size={35} />
                    <span className="font-Helvetica  absolute -mt-3 ml-5 rounded-full bg-red-700 py-0 px-1 text-xs text-white">
                      1
                    </span>
                  </a>
                </div>
              </>
            ) : !seessionData.isLogin ? (
              <>
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica tracking-wider bg-legavacBlue px-5 py-2 rounded text-sm text-white font-medium transition duration-150 ease-in-out"
                >
                  Sign In
                </button>

                <li className="font-Helvetica  ">
                  <div className="font-Helvetica  group inline-block relative">
                    <button
                      className="font-Helvetica  inline-flex items-center cursor-pointer transition ease-in duration-700  "
                      type="button"
                      onClick={() => navigate("/signup")}
                    >
                      <p className="font-Helvetica  text-md bg-legavacYellow text-sm text-white font-medium px-5 py-2 rounded">
                        Sign Up
                      </p>
                      <svg
                        className="font-Helvetica  fill-current h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </button>
                    {/* <ul
                      className="font-Helvetica  max-w-max absolute bg-white z-50 float-right py-2 list-none text-left rounded drop-shadow mt-1 
                      hidden m-0 bg-clip-padding border-none hover:transition-all -translate-y-1 hover:ease-in-out
                      hover:duration-500 group-hover:block"
                    >
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap
                           bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white 
                           transition ease-in duration-500"
                          href="/app/article"
                        >
                          For Lookers
                        </a>
                      </li>
                      <li>
                        <a
                          className="font-Helvetica  dropdown-item text-sm py-2 pl-4 pr-10 font-normal block w-full whitespace-nowrap bg-transparent text-legavacBlue hover:bg-legavacYellow hover:text-white transition ease-in duration-500"
                          href="/app/careeradvice"
                        >
                          For Creators
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </li>

                {/* <button
                  type="button"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  data-te-ripple-init
                  className="font-Helvetica tracking-wider bg-legavacYellow px-5 py-2 rounded text-sm text-white font-medium transition duration-150 ease-in-out"
                  onClick={() => navigate("/signup")}
                >
                  sign Up
                </button> */}
              </>
            ) : (
              ""
            )}

            {/* {show ? (
              ""
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica  bg-legavacBlue px-5 py-2 rounded text-sm text-white font-semibold transition duration-150 ease-in-out"
                >
                  Login
                </button>
                <button
                  type="button"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  data-te-ripple-init
                  className="font-Helvetica  bg-legavacYellow px-5 py-2 rounded text-sm text-white font-semibold transition duration-150 ease-in-out"
                  onClick={() => navigate("/signup")}
                >
                  Register
                </button>
              </>
            )} */}
            {/* <button
              type="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="font-Helvetica text-legavacBlue underline underline-offset-4 font-semibold transition duration-150 ease-in-out"
              onClick={() => navigate("/recruiter-signup")}
            >
              Recruiter
            </button> */}
          </div>
        </div>
      </div>

      {/* Responsive Header on Mobile view */}
      <div
        className="font-Helvetica  invisible fixed bottom-0 z-[1045] p-5 flex flex-col md:w-80 w-80 bg-[#000322]
         bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 
         left-0 border-none max-w-full -translate-x-full [&[data-te-offcanvas-show]]:transform-none overflow-y-auto"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        data-te-offcanvas-init
      >
        <div className="font-Helvetica  flex justify-between items-center">
          <Link to="/">
            <img
              src={IMAGES.itonelogo}
              alt=""
              className="font-Helvetica  w-28 shadow shadow-white p-2 cursor-pointer"
            />
          </Link>
          <MdClose
            size={15}
            color="white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="font-Helvetica  py-5">
          <div className="flex space-x-5 p-2">
            <p className="text-white" onClick={() => navigate("/login")}>
              Login
            </p>
            <p className="text-white" onClick={() => navigate("/signup")}>
              Register
            </p>
          </div>
          <div className="font-Helvetica  border border-slate-700 rounded-lg p-2">
            <div className="font-Helvetica peer cursor-pointer transition ease-in duration-700">
              <div className="flex justify-between items-center">
                <div className=" flex space-x-5 items-center">
                  <img
                    src={IMAGES.Profile2}
                    alt=""
                    className="font-Helvetica  w-16 h-16 rounded-full"
                  />
                  <div>
                    <p className="font-Helvetica  text-white font-semibold ">
                      Visitor Name
                    </p>
                    <p className="font-Helvetica  text-white text-xs">
                      Web Developer
                    </p>
                  </div>
                </div>
                <div>
                  <MdArrowDropDown color="white" size={30} />
                </div>
              </div>
            </div>
            <div className="font-Helvetica  hidden peer-hover:flex hover:flex flex-col">
              <ul className="font-Helvetica  divide-y-[1px] divide-slate-700">
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/my-profile"
                    className="font-Helvetica  flex space-x-2 items-center"
                    data-te-target="#tabs-myProfileVertical"
                    role="tab"
                    data-te-nav-active
                    aria-controls="tabs-myProfileVertical"
                    aria-selected="true"
                  >
                    <VscAccount size={20} />
                    <p> View Profile</p>
                  </a>
                </li>
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/my-resume"
                    className="font-Helvetica  flex space-x-2 items-center"
                  >
                    <IoDocumentTextOutline size={20} />
                    <p>My Resume</p>
                  </a>
                </li>
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/saved-jobs"
                    className="font-Helvetica  flex space-x-2 items-center"
                  >
                    <VscHeart size={20} />
                    <p>Saved Jobs</p>
                  </a>
                </li>
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/applied-jobs"
                    className="font-Helvetica  flex space-x-2 items-center"
                  >
                    <VscBriefcase size={20} />
                    <p>Applied Jobs</p>
                  </a>
                </li>
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/create-job-alert"
                    className="font-Helvetica  flex space-x-2 items-center"
                  >
                    <IoMdNotificationsOutline size={25} />
                    <p>Create Job Alert</p>
                  </a>
                </li>
                <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
                  <a
                    href="/profile/change-password"
                    className="font-Helvetica  flex space-x-2 items-center"
                  >
                    <VscKey size={20} />
                    <p>Change Password</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex space-x-2 items-center text-sm text-white font-medium py-3 cursor-pointer"
                  onClick={btnLogout}
                >
                  <MdOutlineLogout size={20} />
                  <a>Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="font-Helvetica  ">
          <ul className="font-Helvetica  divide-y-[1px] divide-slate-700 px-2">
            <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
              <a
                href="/"
                className="font-Helvetica  flex space-x-2 items-center"
              >
                <BiHome size={20} />
                <p>Home</p>
              </a>
            </li>
            <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
              <a
                href="/app/about-us"
                className="font-Helvetica  flex space-x-2 items-center"
              >
                <IoMdInformationCircleOutline size={20} />
                <p>About Us</p>
              </a>
            </li>
            <li className="font-Helvetica  text-sm text-white font-medium py-3 cursor-pointer">
              <a
                href="/app/ServiceWeOffer"
                className="font-Helvetica  flex space-x-2 items-center"
              >
                <MdOutlineSettings size={20} />
                <p>Services</p>
              </a>
            </li>
            <li className="font-Helvetica  py-1.5">
              <div className="font-Helvetica  peer cursor-pointer flex justify-between">
                <div className="font-Helvetica  flex space-x-2 items-center text-sm text-white font-medium ">
                  <VscBriefcase size={20} />
                  <p>Jobs</p>
                </div>
                <MdArrowDropDown color="white" size={30} />
              </div>

              <div className="font-Helvetica  hidden peer-hover:flex hover:flex flex-col drop-shadow-lg pl-3">
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/advance-job-search"
                >
                  Job by Location
                </a>
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/advance-job-search"
                >
                  Job by Company
                </a>
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/advance-job-search"
                >
                  Job by Designation
                </a>
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/advance-job-search"
                >
                  Job by Skills
                </a>
              </div>
            </li>
            <li className="font-Helvetica  py-1.5">
              <div className="font-Helvetica  peer cursor-pointer flex justify-between transition ease-in duration-700">
                <div className="font-Helvetica  flex space-x-2 items-center text-sm text-white font-medium ">
                  <MdMoreVert size={20} />
                  <p>More</p>
                </div>
                <MdArrowDropDown color="white" size={30} />
              </div>

              <div className="font-Helvetica  hidden peer-hover:flex hover:flex flex-col drop-shadow-lg pl-3">
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/article"
                >
                  Article
                </a>
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/affilation"
                >
                  Affilation
                </a>
                <a
                  className="font-Helvetica  px-2 py-1 text-sm text-legavacYellow"
                  href="/app/careeradvice"
                >
                  Career Advice
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="font-Helvetica  invisible fixed bottom-0 right-5 z-[1045] flex h-4/5 w-1/4 max-h-full max-w-full translate-y-full
         flex-col border-none outline-none rounded transition duration-700 delay-75
         ease-in-out [&[data-te-offcanvas-show]]:transform-none"
        tabIndex="-1"
        id="offcanvasNotification"
        aria-labelledby="offcanvasNotificationLabel"
        data-te-offcanvas-init
      >
        <Notifications />
      </div>
    </>
  );
}

export default Header;
