import React, { useCallback, useEffect } from "react";
import { MdCheck, MdOutlineErrorOutline } from "react-icons/md";
import "../App.css";

function Toster(props) {
  console.log("toastList toaster.js", props);
  const { toastList, setList } = props;
  const closeToast = useCallback(
    (id) => {
      console.log("closeToast");
      const toastListItem = toastList.filter((e) => e.id !== id);
      setList(toastListItem);
    },
    [toastList, setList]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length) {
        closeToast(toastList[0].id);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, closeToast]);

  return (
    <>
      {/* <div className="notification-container top-right"> */}
      <div className="container mx-auto grid place-items-center fixed bottom-10 left-0 right-0 z-40">
        {toastList.map((toast, index) => (
          <div
            key={index}
            className="notification toast rounded border-l-4 border-white p-5 shadow-lg shadow-gray-200 transition-all ease-in-out duration-700 delay-75"
            style={{
              backgroundColor: toast.backgroundColor,
              borderColor: toast.borderColor,
            }}
          >
            {/* <div className="flex justify-end pr-5 pt-1">
              <button onClick={() => closeToast(toast.id)} className=" text-lg">
                X
              </button>
            </div> */}
            <div className="flex space-x-5 items-center">
              <div className="">
                {toast.icon === "MdOutlineErrorOutline" ? (
                  <MdOutlineErrorOutline
                    size={30}
                    style={{ borderColor: toast.borderColor,color: toast.color }}
                    className=" rounded-full"
                  />
                ) : (
                  <MdCheck
                    size={30}
                    style={{ borderColor: toast.borderColor,color: toast.color }}
                    className=" border-2 rounded-full"
                  />
                )}
              </div>
              <div>
                <p
                  className="font-semibold text-xl"
                  style={{ color: toast.color }}
                >
                  {toast.title}
                </p>
                  <p className="text-xs" style={{ color: toast.color }}>
                    {toast.description}
                  </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Toster;
