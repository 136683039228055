import React, { useEffect, useState } from "react";
import {
  MdClose,
  MdOutlineLocationOn,
  MdRemove,
  MdSearch,
  MdAdd,
  MdOutlineHomeWork,
  MdOutlineWatchLater,
} from "react-icons/md";
import { useLocation /* other hooks */ } from "react-router-dom";
import {
  getPostedJobsWithFilters,
  getCompanyName,
  getDesignation,
  getExperience,
  getMasterEducation,
  getMasterSkills,
  getSalary,
  getCities,
  getPagination,
  saveCandidateJob,
  getCandidateJobDetailsById,
  getCandidateJobDetails,
} from "../../config/api";
import { IMAGES } from "../../stores";
import { BiRupee } from "react-icons/bi";
import { VscBriefcase, VscLinkExternal } from "react-icons/vsc";
import { SiMinds } from "react-icons/si";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";

function HomeAdvanceSearch() {
  const [jobs, setJobs] = useState([]);

  const [state, setState] = useState({
    CandidateId: localStorage.getItem("userId"),
    IsSaved: "false",
    IsApplied: "true",
  });

  // console.log("Advance job Props------------->", data);
  const [showFilterdData, setShowFilterdData] = useState(true);

  // he function seleted filter data show kryla paije page vr mnun ahe (handleBtnClose ,handleFilterdData)

  // filter astil tr te kiva reccommended job disle paije

  const handleBtnClose = () => {
    setShowFilterdData(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const GetPostedFilter = async (num) => {
    await getPostedJobsWithFilters({
      // pageNo: num,
      // noOfRecords: 5,
      companyName: checkboxValuesComapny,
      designation: checkboxValuesDesignation,
      city: checkboxValuesLocation,
      experience: checkboxValuesExpirence,
      skill: checkboxValuesSkill,
      salary: checkboxValuesSalary,
      qualification: checkboxValuesEduaction,
      jobtype: checkboxValuesJobtype,
      shift: checkboxValuesShift,
      postedby: checkboxValuesPostedby
    }).then((res) => {
      console.log("res paginaton---->", res);
      if (res.data === "") {
        alert("No data available")
      } else {
        setJobs(res.data);
      }

    });
  };

  /*Advance Search left side*/
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  const [postedJobFilter, setPostedJobFilter] = useState({
    companyName: "",
    designation: "",
    city: "",
    experience: "",
    skill: "",
    salary: "",
    qualification: ""
  });

  useEffect(() => {
    GetExperience(0);
  }, []);

  const [experienceData, setExperienceData] = useState([]);
  const GetExperience = async (id) => {
    await getExperience(id).then((res) => {
      console.log("get data experience---->", res.data);
      setExperienceData(res.data);
    });
  };

  useEffect(() => {
    GetDesignation(0);
  }, []);

  const [jobTitleData, setJobTitleData] = useState([]);
  const GetDesignation = async (id) => {
    await getDesignation(id).then((res) => {
      console.log("get data designation---->", res.data);
      setJobTitleData(res.data);
    });
  };

  useEffect(() => {
    GetMasterSkills(0);
  }, []);

  const [skillsData, setSkillsData] = useState([]);
  const GetMasterSkills = async (id) => {
    await getMasterSkills(id).then((res) => {
      console.log("get data skills---->", res.data);
      setSkillsData(res.data);
    });
  };

  useEffect(() => {
    GetSalary(0);
  }, []);

  const [salaryData, setSalaryData] = useState([]);
  const GetSalary = async (id) => {
    await getSalary(id).then((res) => {
      console.log("get data salary---->", res.data);
      setSalaryData(res.data);
    });
  };

  useEffect(() => {
    GetMasterEducation(0);
  }, []);

  const [educationData, setEducationData] = useState([]);
  const GetMasterEducation = async (id) => {
    await getMasterEducation(id)
      .then((res) => {
        console.log("get data education---->", res.data);
        setEducationData(res.data);
      })
      .catch((error) => {
        console.log("erroor-<", error);
      });
  };

  useEffect(() => {
    GetCompanyName(0);
  }, []);

  const [companyNameData, setCompanyNameData] = useState([]);
  const GetCompanyName = async (id) => {
    await getCompanyName(id).then((res) => {
      console.log("get data company name---->", res.data);
      setCompanyNameData(res.data);
    });
  };

  useEffect(() => {
    GetCityName(0);
  }, []);

  const [cityData, setCityData] = useState([]);
  const GetCityName = async (id) => {
    await getCities(id).then((res) => {
      console.log("get data company name---->", res.data);
      setCityData(res.data);
    });
  };

  const [checkboxValuesLocation, setCheckboxValuesLocation] = useState([]);
  const handleCheckboxChangeLocation = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesLocation([...checkboxValuesLocation, value]);
    } else {
      setCheckboxValuesLocation(
        checkboxValuesLocation.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesLocation);
    console.log("checkboxValuesLocation", checkboxValuesLocation);
  };

  const [checkboxValuesExpirence, setCheckboxValuesExpirence] = useState([]);
  const handleCheckboxChangeExpirence = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesExpirence([...checkboxValuesExpirence, value]);
    } else {
      setCheckboxValuesExpirence(
        checkboxValuesExpirence.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesExpirence);
    console.log("checkboxValuesLocation", checkboxValuesExpirence);
  };

  const [checkboxValuesDesignation, setCheckboxValuesDesignation] = useState([]);
  const handleCheckboxChangeDesignation = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesDesignation([...checkboxValuesDesignation, value]);
    } else {
      setCheckboxValuesDesignation(
        checkboxValuesDesignation.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesDesignation);
    console.log("checkboxValuesDesignation", checkboxValuesDesignation);
  };

  const [checkboxValuesSkill, setCheckboxValuesSkill] = useState([]);
  const handleCheckboxChangeSkill = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesSkill([...checkboxValuesSkill, value]);
    } else {
      setCheckboxValuesSkill(
        checkboxValuesSkill.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesSkill);
    console.log("checkboxValuesSkill", checkboxValuesSkill);
  };

  const [checkboxValuesSalary, setCheckboxValuesSalary] = useState([]);
  const handleCheckboxChangeSalary = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesSalary([...checkboxValuesSalary, value]);
    } else {
      setCheckboxValuesSalary(
        checkboxValuesSalary.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesSalary);
    console.log("checkboxValuesSalary", checkboxValuesSalary);
  };


  const [checkboxValuesEduaction, setCheckboxValuesEducation] = useState([]);
  const handleCheckboxChangeEduaction = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesEducation([...checkboxValuesEduaction, value]);
    } else {
      setCheckboxValuesEducation(
        checkboxValuesEduaction.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesEduaction);
    console.log("checkboxValuesEduaction", checkboxValuesEduaction);
  };


  const [checkboxValuesComapny, setCheckboxValuesComapny] = useState([]);
  const handleCheckboxChangeComapny = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesComapny([...checkboxValuesComapny, value]);
    } else {
      setCheckboxValuesComapny(
        checkboxValuesComapny.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesComapny);
    console.log("checkboxValuesComapny", checkboxValuesComapny);
  };


  const [checkboxValuesJobtype, setCheckboxValuesJobtype] = useState([]);
  const handleCheckboxChangeJobtype = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesJobtype([...checkboxValuesJobtype, value]);
    } else {
      setCheckboxValuesJobtype(
        checkboxValuesJobtype.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesJobtype);
    console.log("checkboxValuesJobtype", checkboxValuesJobtype);
  };

  const [checkboxValuesShift, setCheckboxValuesShift] = useState([]);
  const handleCheckboxChangeShift = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesShift([...checkboxValuesShift, value]);
    } else {
      setCheckboxValuesShift(
        checkboxValuesShift.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesShift);
    console.log("checkboxValuesShift", checkboxValuesShift);
  };



  const [checkboxValuesPostedby, setCheckboxValuesPostedby] = useState([]);
  const handleCheckboxChangePostedby = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setCheckboxValuesPostedby([...checkboxValuesPostedby, value]);
    } else {
      setCheckboxValuesPostedby(
        checkboxValuesPostedby.filter((val) => val !== value)
      );
    }
    setPostedJobFilter(checkboxValuesPostedby);
    console.log("checkboxValuesPostedby", checkboxValuesPostedby);
  };


  const jobtype = [
    {
      job: "Full Time",
    },
    {
      job: "Part Time",
    },
  ];

  const shifts = [
    {
      shift: "Day Shift",
    },
    {
      shift: "Night Shift",
    },
    {
      shift: "Hybrid",
    },
  ];

  const postedby = [
    {
      post: "Company Jobs",
      postcount: "43163",
    },
    {
      post: "Consultant Jobs",
      postcount: "11521",
    },
  ];

 

  useEffect(() => {
    GetPostedFilter(postedJobFilter);
  }, []);

  
  const [daysCount, setDaysCount] = useState([]);

  const dateFormat = jobs;
  console.log("dateFormat", dateFormat);
  useEffect(() => {
    if (jobs.length > 0) {
      const currentDate = new Date();
      const targetDates = dateFormat;
      console.log("targetDates", targetDates);
      const daysDiffArray = jobs.map((targetDate) => {
        const dateDiff = currentDate - new Date(targetDate.createdDate);
        targetDate.dateDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));
      });
      setDaysCount(daysDiffArray);
    }
  }, [jobs.length]);

  let [num, setNum] = useState(1);
  let [cur, setCur] = useState(1);

  const pages = [
    { page: num },
    { page: num + 1 },
    { page: num + 2 },
    { page: num + 3 },
  ];
  function Next() {
    setNum(++num);
  }
  function back() {
    num > 1 && setNum(--num);
  }
  const [isLiked, setIsLiked] = useState(false);
  const handleClick = () => {
    // setIsLiked(!isLiked);
  };

  const [appliedJobs, setAppliedJobs] = useState([]);
  const [isAppliedStatus, setIsAppliedStatus] = useState([]);

  useEffect(() => {
    GetCandidateJobDetails(state);
  }, []);

  const GetCandidateJobDetails = async (state) => {
    await getCandidateJobDetails(state).then((res) => {
      setAppliedJobs(res.data);
    });
  };

  //give candidate job isapplied status
  const GetCandidateJobStatusDetailsById = async (id) => {
    await getCandidateJobDetailsById(id).then((res) => {
      console.log("isapplied job status details------>", res.data);
      setIsAppliedStatus(res.data[0].isApplied);
    });
  };

  const handleChangeApplyButton = async (value, index) => {
    debugger;
    alert(value);
    alert(index);
    console.log("applybutton", value, index);
    await saveCandidateJob({
      Id: index,
      JobId: value,
      CandidateId: localStorage.getItem("userId"),
      IsSaved: false,
      IsApplied: true,
      CreatedBy: localStorage.getItem("userId"),
    })
      .then((res) => {
        console.log("Job detalis res-->", res);
        GetCandidateJobDetails(state);
        GetCandidateJobStatusDetailsById(value);
      })
      .catch((error) => console.log(error));
  };

  const seessionData = {
    isLogin: sessionStorage.getItem("isLogInCheck"),
  };
  return (
    <>
      <div className="font-Helvetica  bg-slate-50">
        <div className="font-Helvetica  relative container mx-auto w-full lg:px-20 md:px-10 px-0 pt-28">
          <div className="font-Helvetica  lg:flex md:flex lg:items-center md:items-center md:space-x-2 bg-white lg:space-x-0 lg:space-y-0 md:space-y-0 space-y-2 justify-around border-b p-2 ">
            <div className="font-Helvetica  ">
              <p className="font-Helvetica  font-QuickStand font-semibold text-legavacBlue  lg:text-3xl md:text-2xl text-2xl text-center">
                Advance Job Search
              </p>
              <p className="font-Helvetica  text-slate-400 text-xs text-center">
                you can search your dream job here ... All the best ...!!
              </p>
            </div>

            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-3 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-2 pl-10 rounded border border-legavacYellow focus:border-legavacYellow focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica  lg:grid lg:grid-cols-6 md:grid md:grid-cols-4 grid-cols-2 gap-2 pt-5">
            {showFilterdData ? (
              <div className="font-Helvetica  border border-legavacYellow  rounded-full flex items-center justify-between px-5 ">
                <p className="font-Helvetica  text-slate-500">location</p>
                <MdClose
                  size={15}
                  className="font-Helvetica  text-slate-500"
                  aria-label="Close"
                  onClick={handleBtnClose}
                />
              </div>
            ) : null}
          </div>
          <div className="font-Helvetica   pt-5 pb-10">
            <div className="font-Helvetica  grid lg:grid-cols-7 gap-5 items-start">
              <div className="font-Helvetica  lg:block md:hidden hidden col-span-2 w-full bg-white border shadow shadow-gray-200 px-5">
                {/* <AdvanceSearch /> */}
                <div className="font-Helvetica  submenu-list divide-y">
                  {/*Location*/}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(0)}
                    >
                      <p className="font-Helvetica  font-medium">Location</p>

                      {subMenuOpen === 0 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 0 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
                        {cityData?.map((l, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input
                                type="checkbox"
                                value={l.city}
                                onChange={handleCheckboxChangeLocation}
                              />
                              <p className="font-Helvetica  text-sm">
                                {l.city}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">({l.count})</p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Experience */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(1)}
                    >
                      <p className="font-Helvetica  font-medium">Experience</p>

                      {subMenuOpen === 1 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  pr-5">
                        {experienceData?.map((e, idx) => (
                          <li
                            className="font-Helvetica  menu-item pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={e.experience} onChange={handleCheckboxChangeExpirence} />
                              <p className="font-Helvetica  text-sm">
                                {e.experience}
                              </p>
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Skills */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(2)}
                    >
                      <p className="font-Helvetica  font-medium">
                        Role/Designation
                      </p>

                      {subMenuOpen === 2 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
                        {jobTitleData?.map((r, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={r.designation}
                                onChange={handleCheckboxChangeDesignation} />
                              <p className="font-Helvetica  text-sm">
                                {r.designation}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">({r.rolecount})</p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(12)}
                    >
                      <p className="font-Helvetica  font-medium">Skills</p>

                      {subMenuOpen === 12 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 12 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
                        {skillsData?.map((s, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={s.skill}
                                onChange={handleCheckboxChangeSkill} />
                              <p className="font-Helvetica  text-sm">
                                {s.skill}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">({s.skillcount})</p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                  {/* Salary */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(3)}
                    >
                      <p className="font-Helvetica  font-medium">Salary</p>

                      {subMenuOpen === 3 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  pr-5">
                        {salaryData?.map((s, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={s.salary}
                                onChange={handleCheckboxChangeSalary} />
                              <p className="font-Helvetica  text-sm">
                                {s.salary}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">({s.salarycount})</p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Company Type */}
                  {/* <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(4)}
          >
            <p className="font-Helvetica  font-medium">Company Type</p>

            {subMenuOpen === 4 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 4 ? "is-open" : ""}`}>
            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
              />
            </div>
            <div className="font-Helvetica  pr-5">
              {companyType?.map((c, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{c.cotype}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({c.cocount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div> */}

                  {/* Education */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(5)}
                    >
                      <p className="font-Helvetica  font-medium">Qualification</p>

                      {subMenuOpen === 5 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 5 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
                        {educationData?.map((e, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={e.education}
                                onChange={handleCheckboxChangeEduaction} />
                              <p className="font-Helvetica  text-sm">
                                {e.education}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">({e.educount})</p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Companies */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(6)}
                    >
                      <p className="font-Helvetica  font-medium">Companies</p>

                      {subMenuOpen === 6 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 6 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  relative">
                        <MdSearch
                          size={20}
                          className="font-Helvetica  text-legavacBlue absolute top-2 z-10 left-3"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="font-Helvetica  w-full py-1 pl-10 rounded mb-5 border-[2px] border-legavacBlue focus:border-legavacBlue focus:outline-none hover:outline-none"
                        />
                      </div>
                      <div className="font-Helvetica  h-60 overflow-y-scroll pr-5">
                        {companyNameData?.map((comp, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={comp.companyName}
                                onChange={handleCheckboxChangeComapny} />
                              <p className="font-Helvetica  text-sm">
                                {comp.companyName}
                              </p>
                            </div>
                            <div>
                              {/* <p className="font-Helvetica  text-sm">
                      ({comp.compcount})
                    </p> */}
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Job Type */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(8)}
                    >
                      <p className="font-Helvetica  font-medium">Job Type</p>

                      {subMenuOpen === 8 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 8 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  pr-5">
                        {jobtype?.map((j, idx) => (
                          <li
                            className="font-Helvetica  menu-item pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={j.job}
                                onChange={handleCheckboxChangeJobtype} />
                              <p className="font-Helvetica  text-sm">{j.job}</p>
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Shift */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(9)}
                    >
                      <p className="font-Helvetica  font-medium">Shift</p>

                      {subMenuOpen === 9 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 9 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  pr-5">
                        {shifts?.map((sh, idx) => (
                          <li
                            className="font-Helvetica  menu-item pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={sh.shift}
                                onChange={handleCheckboxChangeShift} />
                              <p className="font-Helvetica  text-sm">
                                {sh.shift}
                              </p>
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {/* Work Mode */}
                  {/* <div className="font-Helvetica  space-y-3 py-5">
          <div
            className="font-Helvetica  flex justify-between items-center"
            onClick={() => toggleMenu(10)}
          >
            <p className="font-Helvetica  font-medium">Work Mode</p>

            {subMenuOpen === 10 ? (
              <div>
                <MdRemove size={20} color="black" />
              </div>
            ) : (
              <div>
                <MdAdd size={20} color="black" />
              </div>
            )}
          </div>

          <ul className={`sub-menu ${subMenuOpen === 10 ? "is-open" : ""}`}>
            <div className="font-Helvetica  pr-5">
              {work?.map((w, idx) => (
                <li
                  className="font-Helvetica  menu-item flex justify-between pt-0.5"
                  key={idx}
                >
                  <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-sm">{w.mode}</p>
                  </div>
                  <div>
                    <p className="font-Helvetica  text-sm">({w.modecount})</p>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div> */}

                  {/* Posted by */}
                  <div className="font-Helvetica  space-y-3 py-5">
                    <div
                      className="font-Helvetica  flex justify-between items-center"
                      onClick={() => toggleMenu(11)}
                    >
                      <p className="font-Helvetica  font-medium">Posted by</p>

                      {subMenuOpen === 11 ? (
                        <div>
                          <MdRemove size={20} color="black" />
                        </div>
                      ) : (
                        <div>
                          <MdAdd size={20} color="black" />
                        </div>
                      )}
                    </div>

                    <ul
                      className={`sub-menu ${subMenuOpen === 11 ? "is-open" : ""
                        }`}
                    >
                      <div className="font-Helvetica  pr-5">
                        {postedby?.map((by, idx) => (
                          <li
                            className="font-Helvetica  menu-item flex justify-between pt-0.5"
                            key={idx}
                          >
                            <div className="font-Helvetica  flex space-x-2">
                              <input type="checkbox"
                                value={by.post}
                                onChange={handleCheckboxChangePostedby} />
                              <p className="font-Helvetica  text-sm">
                                {by.post}
                              </p>
                            </div>
                            <div>
                              <p className="font-Helvetica  text-sm">
                                ({by.postcount})
                              </p>
                            </div>
                          </li>
                        ))}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="font-Helvetica  col-span-3">
                {/* <AdvanceJobSection /> */}
                <div className="font-Helvetica  space-y-4">
                  {jobs?.map((j, idx) => (
                    <div
                      className="font-Helvetica  bg-white border border-gray-200 hover:border-legavacBlue rounded-md shadow shadow-gray-200 p-4 space-y-2"
                      key={idx}
                    >
                      <div>
                        <div className="font-Helvetica  flex justify-between items-center">
                          <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
                            {j.jobTitle}
                          </p>
                          <div className="text-red-500" onClick={handleClick}>
                            {isLiked ? (
                              <IoMdHeart className="w-6 h-6 fill-current" />
                            ) : (
                              <IoMdHeartEmpty className="w-6 h-6 fill-current" />
                            )}
                          </div>
                        </div>
                        <div className="font-Helvetica  lg:flex md:flex block justify-between items-center lg:space-y-0 md:space-y-0 space-y-2">
                          <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                            {j.companyName}
                          </p>
                          <div className="font-Helvetica  flex space-x-2">
                            <VscLinkExternal
                              size={15}
                              className="font-Helvetica  text-slate-500"
                            />
                            <p
                              className="font-Helvetica  text-slate-500 text-xs cursor-pointer hover:text-legavacBlue"
                              data-te-offcanvas-toggle
                              data-te-target="#offcanvasBottom2"
                              aria-controls="offcanvasBottom2"
                            >
                              View Full Page
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="font-Helvetica  space-y-1">
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <MdOutlineHomeWork
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              {j.jobType},
                            </p>
                            {/* <p className="font-Helvetica  text-slate-500 text-xs">{workMode}</p> */}
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <MdOutlineLocationOn
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              {j.city},
                            </p>
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              {j.country}
                            </p>
                          </div>
                        </div>
                        <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1">
                          <div className="font-Helvetica  flex space-x-2 items-center">
                            <VscBriefcase
                              size={20}
                              className="font-Helvetica  text-slate-500"
                            />
                            <div className="font-Helvetica  flex space-x-1">
                              <p className="font-Helvetica  text-slate-500 text-xs">
                                {j.experience}
                              </p>
                            </div>
                          </div>
                          <div className="font-Helvetica  flex space-x-2 items-center">
                            <BiRupee
                              size={20}
                              className="font-Helvetica  text-slate-500"
                            />
                            <div className="font-Helvetica  flex space-x-1">
                              <p className="font-Helvetica  text-slate-500 text-xs">
                                {j.salary}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-start">
                          <SiMinds
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-3 gap-2">
                            {j.skill?.split(",").map((s, idx) => (
                              <p
                                className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
                                key={idx}
                              >
                                {s}
                              </p>
                            ))}
                            {/* <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
                    {skills}
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
                    {skills}
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
                    {skills}
                  </p> */}
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-start">
                          <SiMinds
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-3 gap-2">
                            {j.qualification?.split(",").map((q, idx) => (
                              <p
                                className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
                                key={idx}
                              >
                                {q}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="font-Helvetica  flex justify-between">
                        <div className="font-Helvetica  flex space-x-2 items-center pt-2">
                          <MdOutlineWatchLater
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <p className="font-Helvetica  text-slate-500 text-xs">
                            {/* {daysCount.map((daysDiff, index) => ( */}
                            <p> {`${j.dateDiff} days ago`}</p>
                            {/* ))} */}
                          </p>
                        </div>

                        {seessionData.isLogin === "1" ? (
                          <button
                            type="button"
                            data-te-ripple-init
                            data-te-ripple-color="light"
                            className="font-Helvetica  bg-legavacBlue text-white text-sm uppercase rounded px-5 py-1 transition duration-150 ease-in-out"
                            onClick={() => handleChangeApplyButton(j.id, idx + 1)}
                          >
                            Apply
                          </button>
                        ) : null}
                      </div>
                      {/* 
            <div
              className="font-Helvetica  fixed bottom-0 flex flex-col max-w-full lg:bg-gray-50
         md:bg-gray-50 bg-white invisible bg-clip-padding shadow-sm outline-none transition
          duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-full max-h-full z-[1045] 
          translate-y-full [&[data-te-offcanvas-show]]:transform-none"
              tabIndex="-1"
              id="offcanvasBottom2"
              aria-labelledby="offcanvasBottomLabel"
              data-te-offcanvas-init
            >
              <div className="font-Helvetica  offcanvas-header flex items-center justify-end p-4">
                <button
                  type="button"
                  className="font-Helvetica  btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-te-offcanvas-dismiss
                >
                  <MdClose />
                </button>
              </div>
              <div className="font-Helvetica  offcanvas-body flex-grow lg:p-4 overflow-y-auto small">
                <ViewFullPage />
              </div>
            </div> */}
                    </div>
                  ))}
                </div>
                <div className="flex justify-center space-x-2 pt-10">
                  <button
                    onClick={back}
                    // className="h-12 border-2 border-r-0 border-legavacBlue
                    //      px-4 rounded-l-lg hover:bg-legavacBlue hover:text-white"
                    className=" "
                  >
                    <svg
                      className="w-8 h-8 text-legavacBlue p-1.5 hover:p-1 hover:border-2 hover:border-legavacBlue hover:rounded-full fill-current"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  {pages.map((pg, i) => (
                    <button
                      key={i}
                      onClick={() => setCur(pg.page)}
                      className={`w-8 h-8  hover:border-2 hover:border-legavacBlue rounded-full hover:rounded-full
             ${cur === pg.page && "bg-legavacBlue text-white"}`}
                    >
                      {pg.page}
                    </button>
                  ))}
                  <button
                    onClick={Next}
                    // className="h-12 border-2  border-legavacBlue
                    //      px-4 rounded-r-lg hover:bg-legavacBlue hover:text-white"
                    className=" "
                  >
                    <svg
                      className="w-8 h-8 text-legavacBlue p-1.5 hover:p-1 hover:border-2 hover:border-legavacBlue hover:rounded-full fill-current"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="font-Helvetica  lg:block md:hidden hidden col-span-2 ">
                {/* <Advertisement /> */}
                <div className="font-Helvetica  space-y-5">
                  <div className="font-Helvetica  bg-white hover:bg-green-100 rounded border border-green-600 shadow shadow-gray-200 p-5">
                    <p className="font-Helvetica  text-green-600 text-center text-xl font-semibold">
                      We are Hiring
                    </p>
                    <div className="font-Helvetica  pt-5">
                      <div className="font-Helvetica  pb-3">
                        <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
                          Advocate
                        </p>
                        <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                          Legavac
                        </p>
                      </div>
                      <div className="font-Helvetica  space-y-2">
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <MdOutlineHomeWork
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              Permanant,
                            </p>
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              Work From Home
                            </p>
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <MdOutlineLocationOn
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              New Sangavi, Pune,
                            </p>
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              India
                            </p>
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <VscBriefcase
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              0 - 2 years
                            </p>
                          </div>
                        </div>
                        <div className="font-Helvetica  flex space-x-2 items-center">
                          <BiRupee
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                          <div className="font-Helvetica  flex space-x-1">
                            <p className="font-Helvetica  text-slate-500 text-xs">
                              1,00,000 - 3,00,000 LPA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="font-Helvetica  ">
                    <img
                      src={IMAGES.Advertisement}
                      alt=""
                      className="font-Helvetica  w-full h-60"
                    />
                  </div>
                  <div className="font-Helvetica  ">
                    <img
                      src={IMAGES.Advertisement}
                      alt=""
                      className="font-Helvetica  w-full h-60"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeAdvanceSearch;
