import React from "react";
import { MdOutlineHomeWork } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import {
  HiOutlineAcademicCap,
  HiOutlineBuildingOffice2,
} from "react-icons/hi2";
import { SiMinds } from "react-icons/si";

function MoreInfo() {
  // console.log("more info-->", props);
  //let skill = [];
  // skill = props.skill;
  // const { jobType, skill, qualification } = props;
  // console.log("more info skill-->", skill);

  return (
    <>
      <div className="font-Helvetica  space-y-3">
        <p className="font-Helvetica  text-black font-semibold">
          More Information
        </p>
        <div className="font-Helvetica  ">
          <div className="font-Helvetica  ">
            <div className="font-Helvetica  flex space-x-2 items-center">
              <RiUserSettingsLine />
              <p className="font-Helvetica  text-slate-500 text-xs uppercase">
                Job Type
              </p>
            </div>
            <div className="font-Helvetica  flex space-x-1">
              <p className="font-Helvetica  text-sm text-black pl-6">
                {/* {jobType}  */} WFO/WFH
              </p>
            </div>
          </div>
        </div>

        <div className="font-Helvetica  space-y-2 pt-2">
          <div className="font-Helvetica  flex space-x-2 items-center">
            <SiMinds />
            <p className="font-Helvetica  text-slate-500 text-xs uppercase">
              Skills
            </p>
          </div>
          <div className="font-Helvetica  flex space-x-2 items-start">
            <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2 pl-6">
              <p
                className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
               
              >
                {/* {s}  */} ReactJs,HTML,Css
              </p>
            </div>
          </div>
        </div>
        <div className="font-Helvetica  space-y-2 pt-2">
          <div className="font-Helvetica  flex space-x-2 items-center">
            <HiOutlineAcademicCap />
            <p className="font-Helvetica  text-slate-500 text-xs uppercase">
              Education
            </p>
          
          </div>

          <div className="font-Helvetica  flex space-x-2 items-start">
            <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2 pl-6">
           
                <p
                  className="font-Helvetica  text-slate-500 text-xs text-center bg-slate-100 rounded-full px-3 p-1"
                 
                >
                  {/* {edu}  */}  MCA,BE
                </p>
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// function MoreInfoBlog({ icon, title, name }) {
//   return (
//     <>
//       <div className="font-Helvetica  ">
//         <div className="font-Helvetica  flex space-x-2 items-center">
//           {icon}
//           <p className="font-Helvetica  text-slate-500 text-xs uppercase">
//             {title}jfrjerjhrbjh
//           </p>
//         </div>
//         <div className="font-Helvetica  flex space-x-1">
//           <p className="font-Helvetica  text-sm text-black pl-6">{name}</p>
//         </div>
//       </div>
//     </>
//   );
// }

export default MoreInfo;
