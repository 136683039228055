import React, { useEffect, useState } from "react";
import { IMAGES } from "../../stores";
import {
  getCompanyName,
  getDesignation,
  getMasterSkills,
} from "../../config/api";
import { useNavigate } from "react-router-dom";

function Job() {

  const navigate= useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    
    GetDesignation(0);
    // GetCompanyName(0);
    GetSkill(0);
  }, []);

  const [designation, setDesignation] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [skills, setSkills] = useState([]);

  //getDesignation
  let designationList = [];
  let skillList = [];

  const GetDesignation = async (id) => {
    await getDesignation(id).then((res) => {
      console.log("responce---->", res);
      for (let i = 0; i < res.data.length; i++) {
        designationList.push(res.data[i]);
        console.log(designationList);
        setDesignation(designationList);
      }
    });
  };
  const GetCompanyName = async (id) => {
    await getCompanyName(id).then((res) => {
      console.log("responce---->", res);
      setIndustry(res.data);
    });
  };

  const GetSkill = async (id) => {
    await getMasterSkills(id).then((res) => {
      console.log("get data salary---->", res.data);
      console.log("responce---->", res);
      for (let i = 0; i < res.data.length; i++) {
        skillList.push(res.data[i]);
        console.log(skillList);
        setSkills(skillList);
      }
    });
  };

  const location = [
    {
      locationImg: IMAGES.bangalore,
      location: "Bangalore",
    },
    {
      locationImg: IMAGES.delhi,
      location: "Delhi",
    },
    {
      locationImg: IMAGES.hydrabad,
      location: "Hydrabad",
    },
    {
      locationImg: IMAGES.mumbai,
      location: "Mumbai",
    },
    {
      locationImg: IMAGES.pune,
      location: "Pune",
    },
    
  ];
 
  const companyLogoName = [
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
    { companyLogo: IMAGES.CompanyLogo },
  ];
  return (
    <>
      <section className="font-Helvetica  section-1 col-span-6">
        <div className="font-Helvetica  bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60 bg-white/[.30]">
          <img
            src={IMAGES.HomeImg}
            alt=""
            className="font-Helvetica  w-full h-[500px] bg-cover"
          />
        </div>
        <div className="font-Helvetica  ">
          <div className="font-Helvetica absolute  left-0 right-0 lg:top-24 md:top-20 top-16 container mx-auto py-5 mt-20 lg:px-20 md:px-10 px-0">
            {/* <div className="font-Helvetica   relative bg-gray-100 opacity-20 rounded-md p-10 "></div> */}
            <div className="font-Helvetica   bg-white/[.30] p-5  divide-x grid lg:grid-cols-7 md:grid-cols-7 grid-cols-1 gap-0 lg:space-y-0 md:space-y-0 space-y-2 w-full rounded">
              <div className="font-Helvetica  bg-white col-span-3 rounded-l">
                <input
                  type="text"
                  id="searchBox-1"
                  className="font-Helvetica   bg-transparent p-2 w-full placeholder:text-sm text-sm pl-5 block outline:none focus:outline-none text-black"
                  placeholder="job title or company name"
                  name="searchBox-1"
                  required
                />
              </div>
              <div className="font-Helvetica  bg-white col-span-3">
                <input
                  type="text"
                  id="searchBox-1"
                  className="font-Helvetica   bg-transparent p-2 w-full placeholder:text-sm text-sm pl-5  block outline:none focus:outline-none text-black"
                  placeholder="job title or company name"
                  name="searchBox-1"
                  required
                />
              </div>
              <div>
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica  bg-legavacBlue font-semibold w-full rounded-r text-white p-2  cursor-pointer tracking-wider transition duration-150 ease-in-out"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="font-Helvetica lg:block md:block hidden relative rounded-md pt-5">
              <div className="font-Helvetica">
                <ul className="font-Helvetica  list-none text-white flex  flex-wrap">
                  <li className="font-Helvetica  job-categories-active py-2 px-5 bg-black/[.50]  border-l border-slate-100/[.50] ">
                    <a>All Jobs</a>
                  </li>
                  <li className="job-categories py-2 px-5 bg-black/[.50]  border-l border-slate-100/[.50] ">
                    <a href="#jobLocation">Jobs by location</a>
                  </li>

                  <li className="py-2 px-5 bg-black/[.50]  border-l border-slate-100/[.50] ">
                    <a href="#jobCompany">Jobs by Company</a>
                  </li>

                  <li className="py-2 px-5 bg-black/[.50]  border-l border-slate-100/[.50] ">
                    <a href="#jobDesignation">Jobs by Designation</a>
                  </li>

                  <li className="py-2 px-5 bg-black/[.50]  border-l border-slate-100/[.50] ">
                    <a href="#jobSkill">Jobs by skills</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="font-Helvetica  grid grid-cols-6 gap-10 container mx-auto lg:px-20 md:px-10 px-0 lg:pt-20 pt-10">
        <section
          className="font-Helvetica  section-2 col-span-6"
          id="jobLocation"
        >
          <div className="font-Helvetica  bg-white">
            <div className="font-Helvetica  container mx-auto w-full lg:px-12 md:px-10 px-5 py-10 bg-slate-50">
              <div className="font-Helvetica  pb-5 flex justify-between ">
                <p className="font-Helvetica  text-xl font-semibold ">
                  Job By Location
                </p>
                <p className="font-Helvetica  text-xl font-semibold text-legavacBlue">
                  <p onClick={()=>navigate("/app/advance-job-homeSearch")} className="font-Helvetica">
                    View All
                  </p>
                </p>
              </div>
              <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-5">
                {location?.map((l, idx) => (
                  <div key={idx}>
                    <div className="font-Helvetica  relative job-listing bg-white border hover:border-legavacBlue hover:shadow-2xl hover:shadow-slate-300"
                    onClick={()=>navigate("/app/advance-job-homeSearch")} >
                      <div className="font-Helvetica  after:bg-black  after:inset-0 after:absolute relative after:opacity-50">
                        <img src={l.locationImg} className="font-Helvetica" />
                      </div>
                      <div className="font-Helvetica  absolute top-0 text-white  p-2">
                        {l.location}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section
          className="font-Helvetica  section-3 col-span-6"
          id="jobDesignation"
        >
          <div className="font-Helvetica  bg-white container mx-auto ">
            <div className="font-Helvetica  grid lg:grid-cols-2  grid-cols-1 gap-10">
              <div className="font-Helvetica  lg:col-span-1 col-span-1 lg:px-12 md:px-5 px-5 py-10 bg-slate-50 ">
                <div className="font-Helvetica  pb-5 flex justify-between ">
                  <p className="font-Helvetica  text-lg font-semibold ">
                    Job By Designation
                  </p>
                  <p className="font-Helvetica  text-lg font-semibold text-legavacBlue">
                    <a
                      href="/app/advance-job-homeSearch"
                      className="font-Helvetica"
                    >
                      View All
                    </a>
                  </p>
                </div>

                <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1  grid-cols-1 space-y-2">
                  {designation?.map((d, idx) => (
                    <a
                      href="/app/advance-job-homeSearch"
                      className="text-sm text-legavacBlue"
                      key={idx}
                    >
                      <p className="font-Helvetica" >{d.designation} </p>
                    </a>
                  ))}
                </div>
              </div>
              <div className="font-Helvetica  container mx-auto lg:col-span-1 col-span-1 lg:px-12 md:px-10 px-5 py-10 bg-slate-50 ">
                <div className="font-Helvetica  pb-5 flex justify-between ">
                  <p className="font-Helvetica  text-lg font-semibold ">
                    Job By skills
                  </p>
                  <p className="font-Helvetica  text-lg font-semibold text-legavacBlue">
                    <a
                      href="/app/advance-job-homeSearch"
                      className="font-Helvetica"
                    >
                      View All
                    </a>
                  </p>
                </div>
                <div className="font-Helvetica  grid lg:grid-cols-2 grid-cols-1 space-y-2">
                  {skills?.map((s, idx) => (
                    <a
                      href="/app/advance-job-homeSearch"
                      className="text-sm text-legavacBlue"
                      key={idx}
                    >
                      <p className="font-Helvetica   text-sm ">{s.skill} </p>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="font-Helvetica  section-4 col-span-6"
          id="jobCompany"
        >
          <div className="font-Helvetica  bg-white">
            <div className="font-Helvetica  container mx-auto w-full lg:px-12 md:px-10 px-5 py-10 bg-slate-50">
              <div className="font-Helvetica  pb-5 flex justify-between ">
                <p className="font-Helvetica  text-lg font-semibold ">
                  Job By Company
                </p>
                <p className="font-Helvetica  text-lg font-semibold text-legavacBlue">
                  <a href="/app/advance-job-homeSearch" className="font-Helvetica">
                    View All
                  </a>
                </p>
              </div>
              <div className="font-Helvetica  grid lg:grid-cols-6 md:grid-cols-3 grid-cols-1">
                {companyLogoName?.map((logo, idx) => (
                  <a href="/app/advance-job-homeSearch" key={idx}>
                    <div className="font-Helvetica  relative job-listing border border-slate-100  hover:shadow-2xl hover:shadow-slate-300">
                      <img
                        src={logo.companyLogo}
                        alt=""
                        className="font-Helvetica  p-10 scale-90 hover:transition-all hover:ease-in-out hover:scale-100 hover:duration-700 hover:delay-25"
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Job;
