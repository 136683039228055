import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { MdEdit, MdDelete } from "react-icons/md";
import {
  deleteRecord,
  getCandidateSkillDetails,
  getMasterSkills,
  saveCandidateSkill,
} from "../../../../config/api";

function Skills() {
  const [addSkills, setAddSkills] = useState([]);
  const [skilldata, setSkill] = useState([]);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const funShow = () => {
    setShow(true);
  };

  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    skill: "",
    skillLevel: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  useEffect(() => {
    //getMasterSkills
    GetMasterSkills(0);
    GetCandidateSkillDetails(localStorage.getItem("userId"));
  }, [addSkills.id]);

  const GetCandidateSkillDetails = async (id) => {
    await getCandidateSkillDetails(id).then((res) => {
      console.log("get skill details=", res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(false);
        setAddSkills(res.data);
      } else {
        setHide(true);
        setAddSkills(res.data);
      }
    });
  };

  const GetMasterSkills = async (id) => {
    await getMasterSkills(id).then((res) => {
      console.log(res);
      setSkill(res.data);
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  let errors = {};
  const validate = () => {
    let IsValid = true;

    if (!state.skill) {
      IsValid = false;
      errors["skill"] = "This is a required";
    } else {
      errors["skill"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    debugger;
    if (validate()) {
      await saveCandidateSkill(state)
        .then((res) => {
          console.log("skill res--->", res);
          GetCandidateSkillDetails(localStorage.getItem("userId"));
          setShow(false);
          alert("done");
          setState({
            id: "0",
            CandidateId: state.CandidateId,
            skill: "",
            skillLevel: "",
            CreatedBy: state.CreatedBy,
          });
        })
        .catch((error) => alert(error));
    } else {
      alert("please enter all fields");
    }
  };
  const handleDelete = async (id) => {
    debugger;
    await deleteRecord({
      tableName: "CandidateSkills",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        alert("record deleted");
        GetCandidateSkillDetails(localStorage.getItem("userId"));
      }
    });
  };

  return (
    <>
      <div className="font-Helvetica">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between lg:space-y-0 space-y-2">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Skills
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-bold">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica space-y-5" id="showFormOnEdit">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Skills:
                  <span className="font-Helvetica  text-pink-600 text-xl pt-5">
                    *
                  </span>
                </p>
                <div>
                  <select
                    name="skill"
                    id="skill"
                    onChange={handleChange}
                    value={state.skill}
                    className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option>-- Select Institute --</option>
                    {skilldata?.map((i, idx) => (
                      <option key={idx} className="font-Helvetica  text-sm">
                        {i.skill}
                      </option>
                    ))}
                  </select>
                </div>
                {state.errors ? (
                  <p className="text-sm text-red-600">{state.errors.skill}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide pt-2">
                  Levels:
                </p>
                <div>
                  <select
                    type="text"
                    name="skillLevel"
                    id="skillLevel"
                    onChange={handleChange}
                    value={state.skillLevel}
                    className="font-Helvetica  border border-slate-200 drop-shadow text-sm rounded p-2.5 w-full focus:outline-none hover:outline-none"
                  >
                    <option selected>-- Select Level --</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermidiate">Intermidiate</option>
                    <option value="Advance">Advance</option>
                  </select>
                </div>
               
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="font-Helvetica  ">
          <div className="font-Helvetica  flex flex-col">
            <div className="font-Helvetica  overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="font-Helvetica  inline-block min-w-full py-2 sm:px-6 lg:px-8">
                {hide ? (
                  <div className="font-Helvetica  overflow-hidden">
                    <table className="font-Helvetica  min-w-full text-left text-sm font-light">
                      <thead className="font-Helvetica  ">
                        <tr>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Skills
                          </th>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Levels
                          </th>
                          <th
                            scope="col"
                            className="font-Helvetica  px-6 py-4 text-legavacBlue"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {addSkills &&
                          addSkills.map((s, idx) => (
                            <tr className="font-Helvetica" key={idx}>
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                                {s.skill}
                              </td>
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 text-slate-700">
                                {s.skillLevel}
                              </td>
                              <td className="font-Helvetica  whitespace-nowrap px-6 py-2 flex space-x-3">
                                <MdEdit
                                  className="font-Helvetica  text-green-600 cursor-pointer"
                                  size={20}
                                  onClick={() => {
                                    setShow(true);
                                    const editData = addSkills.filter(
                                      (data) => data.id === s.id
                                    );
                                    console.log(editData[0]);
                                    setState(editData[0]);
                                  }}
                                />
                                <MdDelete
                                  className="font-Helvetica  text-red-500 cursor-pointer"
                                  size={20}
                                  onClick={() => {
                                    const DeleteData = addSkills.filter(
                                      (data) => data.id === s.id
                                    );
                                    handleDelete(DeleteData[0].id);
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
