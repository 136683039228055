import axios from "axios";

import React from "react";
import { useLocation } from "react-router-dom";

import { resolve } from "./resolve.js";
export default () => axios.get("/");

export const API_URL = "https://localhost:7166/api"; // .net admin save data(post)

// export const API_URL = "https://localhost:7166/api/AdminGet"; // .net admin get data

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

//GetMasterEducation
export async function getMasterEducation(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetMasterEducation?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetMasterSkills
export async function getMasterSkills(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetMasterSkills?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetInstituteName
export async function getInstituteName(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetInstituteName?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetCompanyName
export async function getCompanyName(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetCompanyName?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetDesignation
export async function getDesignation(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetDesignation?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetSalary
export async function getSalary(id) {
  return await resolve(
    axios.get(`${API_URL}/AdminGet/GetSalary?Id=${id}`).then((res) => res.data)
  );
}

//GetExperience
export async function getExperience(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetExperience?Id=${id}`)
      .then((res) => res.data)
  );
}

//CandidateGet/GetCandidateDetails
export async function getCandidate(id) {
  return await resolve(
    axios
      .post(`${API_URL}/CandidateGet/GetCandidateDetails?Id=${id}`)
      .then((res) => res.data)
  );
}

//  https://localhost:7166/api/CandidateGet/GetCandidateJobDetails?CandidateId=0&IsSaved=false&IsApplied=true

export async function getCandidateJobDetails(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateJobDetails?CandidateId=${state.CandidateId}&IsSaved=${state.IsSaved}&IsApplied=${state.IsApplied}`
      )
      .then((res) => res.data)
  );
}

export async function getPagination(state) {
  return await resolve(
    axios
      .get(
        `${API_URL}/Pagination/Getpagination?_pageNo=${state.pageNo}&_NoOfRecord=${state.noOfRecords}&CompanyName=${state.companyName}&Designation=${state.designation}&City=${state.city}&Experience=${state.experience}&skill=${state.skill}&salary=${state.salary}&qualification=${state.qualification}`
      )
      .then((res) => res.data)
  );
}

//GetCities
export async function getCities(id) {
  return await resolve(
    axios.get(`${API_URL}/AdminGet/GetCity?Id=${id}`).then((res) => res.data)
  );
}

//GetArticles
export async function getArticles(id) {
  return await resolve(
    axios.get(`${API_URL}/AdminGet/GetArticle?Id=${id}`).then((res) => res.data)
  );
}

//GetAffiliation
export async function getAffiliation(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetAffiliation?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetTestimonial
export async function getTestimonials(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetTestimonials?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetPostedJobs
export async function getPostedJobs(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetPostedJobs?Id=${id}`)
      .then((res) => res.data)
  );
}

//GetCareerAdvice
export async function getCareerAdvice(id) {
  return await resolve(
    axios
      .get(`${API_URL}/AdminGet/GetCareerAdvice?Id=${id}`)
      .then((res) => res.data)
  );
}

// CandidateGet/GetCandidateEducationDetails?Id=1

export async function getCandidateEducationDetails(id) {
  return await resolve(
    axios
      .post(`${API_URL}/CandidateGet/GetCandidateEducationDetails?Id=${id}`)
      .then((res) => res.data)
  );
}

// CandidateGet/GetCandidateProjectDetails?CandidateId=2
export async function getCandidateProjectDetails(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateProjectDetails?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

//GetCandidateSkillDetails
export async function getCandidateSkillDetails(Id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateSkillDetails?CandidateId=${Id}`
      )
      .then((res) => res.data)
  );
}
//GetCandidateSocialDetails
export async function getCandidateSocialDetails(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateSocialDetails?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

// GetCandidateCourseAnCertificateDetails
export async function getCandidateCourse(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateCourseAnCertificateDetails?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

//GetCandidateWorkExperienceDetails

export async function getCandidateWorkExperience(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateWorkExperienceDetails?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

//GetCandidateAwardAndAchievement
export async function getCandidateAward(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateAwardAndAchievement?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

/// DeleteRecord/DeleteRecord?TableName=MasterEducation&Id=11
export async function deleteRecord(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/DeleteRecord/DeleteRecord?TableName=${state.tableName}&Id=${state.id}`
      )
      .then((res) => res.data)
  );
}

export async function savecandidate(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/Savecandidate?Role=${state.Role}&FirstName=${state.FirstName}&LastName=${state.LastName}&Email=${state.Email}&Password=${state.Password}&MobileNumber=${state.MobileNumber}&WhatsppAlert=${state.WhatsppAlert}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

export async function updateCandidateDetails(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/UpdateCandidateDetails?Id=${state.id}&Role=${state.role}&FirstName=${state.firstName}&LastName=${state.lastName}&Email=${state.email}&MobileNumber=${state.mobileNumber}&WhatsppAlert=${state.whatsppAlert}&DOB=${state.dob}&Description=${state.description}&Country=${state.country}&State=${state.state}&District=${state.district}&City=${state.city}&Postlpin=${state.postalPin}&FullAddress=${state.fullAddress}&CreatedBy=${state.createdBy}`
      )
      .then((res) => res.data)
  );
}
//SaveCandidateSkill?CandidateId=31&Skill=.net&SkillLevel=beginer&CreatedBy=31

export async function saveCandidateSkill(state) {
  if (state.id === "0") {
    state.skill = state.skill;
    state.skillLevel = state.skillLevel;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.skill = state.skill;
    state.skillLevel = state.skillLevel;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateSkill?Id=${state.id}&CandidateId=${state.CandidateId}&Skill=${state.skill}&SkillLevel=${state.skillLevel}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

// SaveCandidateSocial?Id=0&CandidateId=33&SocialProfile=www.google.com&SocialLink=www.google.com&CreatedBy=33
export async function saveCandidateSocial(state) {
  if (state.id === "0") {
    state.socialProfile = state.socialProfile;
    state.SocialLink = state.socialLink;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.socialProfile = state.socialProfile;
    state.SocialLink = state.socialLink;
    state.CreatedBy = state.createdBy;
  }

  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateSocial?Id=${state.id}&CandidateId=${state.CandidateId}&SocialProfile=${state.socialProfile}&SocialLink=${state.socialLink}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

// SaveCandidateCourseAndCertificate?Id=0&CandidateId=33&Certificate=networking&IssuedBy=xyz&ValidDate=2023-01-9&LifeTime=true&CreatedBy=33
export async function saveCandidateCourseAndCertificate(state) {
  if (state.id === "0") {
    state.Certificate = state.certificate;
    state.IssuedBy = state.issuedBy;
    state.ValidDate = state.validDate; //LifeTime
    state.LifeTime = state.lifeTime;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.Certificate = state.certificate;
    state.IssuedBy = state.issuedBy;
    state.ValidDate = state.validDate; //LifeTime
    state.LifeTime = state.lifeTime;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateCourseAndCertificate?Id=${state.id}&CandidateId=${state.CandidateId}&Certificate=${state.certificate}&IssuedBy=${state.issuedBy}&ValidDate=${state.validDate}&LifeTime=${state.lifeTime}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}
//SaveCandidateAwardAndAchievement?CandidateId=33&AwardName=mitali&DateOfAward=2023-01-9&Description=hfdf%20%20hdsgfhf%20%20hjfgshf%20ghjfg%20%20jhdfhjg%20djffjgsdh%20djfjh&CreatedBy=33
export async function saveCandidateAwards(state) {
  if (state.id === "0") {
    state.awardName = state.awardName;
    state.dateOfAward = state.dateOfAward;
    state.description = state.description;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.awardName = state.awardName;
    state.dateOfAward = state.dateOfAward;
    state.description = state.description;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateAwardAndAchievement?Id=${state.id}&CandidateId=${state.CandidateId}&AwardName=${state.awardName}&DateOfAward=${state.dateOfAward}&Description=${state.description}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

//CandidateSave/SaveCandidateWorkExperience?Id=0&CandidateId=4&Organization=Amazon&Designation=Tea%20lead&CurrentlyWorking=yes&CWYesFromDate=2023-09-1&CWTillDate=present&CreatedBy=4
export async function saveCandidateWorkExperience(state) {
  if (state.id === "0") {
    state.organization = state.organization;
    state.designation = state.designation;
    state.currentlyWorking = state.currentlyWorking;
    state.fromDate = state.fromDate;
    state.toDate = state.toDate;
    state.noticePeriod = state.noticePeriod;
    state.descriptionWork = state.descriptionWork;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.organization = state.organization;
    state.designation = state.designation;
    state.currentlyWorking = state.currentlyWorking;
    state.fromDate = state.fromDate;
    state.toDate = state.toDate;
    state.noticePeriod = state.noticePeriod;
    state.descriptionWork = state.descriptionWork;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateWorkExperience?Id=${state.id}&CandidateId=${state.CandidateId}&Organization=${state.organization}&Designation=${state.designation}&CurrentlyWorking=${state.currentlyWorking}&FromDate=${state.fromDate}&ToDate=${state.toDate}&NoticePeriod=${state.noticePeriod}&DescriptionWork=${state.descriptionWork}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}
//CandidateSave/SaveCandidateEducation?Id=0&CandidateId=4&Qualification=fkjfhsdjk&Institute=jkgkgkgk&PassingYear=kjkkgjkgj&Percentage=jkggjkgkj&CreatedBy=gjkgjk
export async function saveCandidateEducation(state) {
  if (state.id === "0") {
    state.qualification = state.qualification;
    state.institute = state.institute;
    state.passingYear = state.passingYear;
    state.percentage = state.percentage;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.qualification = state.qualification;
    state.institute = state.institute;
    state.passingYear = state.passingYear;
    state.percentage = state.percentage;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateEducation?Id=${state.id}&CandidateId=${state.CandidateId}&Qualification=${state.qualification}&Institute=${state.institute}&PassingYear=${state.passingYear}&Percentage=${state.percentage}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

export async function getJobAlert(id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateJobAlertDetails?CandidateId=${id}`
      )
      .then((res) => res.data)
  );
}

//SaveCandidateJobAlert?Id=0&CandidateId=35&Keyword=angular&Location=mumbai&Experience=2&ExpectedSalary=120000&EmailId=p%40gmail.com&CreatedBy=35'
export async function saveJobAlert(state) {
  // if (state.id == "0") {
  //   state.id = state.id;
  //   state.keyword = state.keyword;
  //   state.emailId = state.emailId;
  //   state.expectedSalary = state.expectedSalary;
  //   state.experience=state.experience;
  //   state.location=state.location;
  // } else {
  //   state.id = state.id;
  //   state.CandidateId = state.candidateId;
  //   state.keyword = state.keyword;
  //   state.emailId = state.emailId;
  //   state.expectedSalary = state.expectedSalary;
  //   state.experience=state.experience;
  //   state.location=state.location;
  //   state.CreatedBy = state.createdBy;
  // }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateJobAlert?Id=${state.id}&CandidateId=${state.CandidateId}&Keyword=${state.keyword}&Location=${state.location}&Experience=${state.experience}&ExpectedSalary=${state.expectedSalary}&EmailId=${state.emailId}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

///SaveCandidateProject?CandidateId=35&ProjectTitle=mjhgfhj&ProjectClient=jjhgfhjk&ProjectStatus=ghjk&StartDate=2023-09-09&EndDate=2023-09-09
//&DescriptionProject=2023-09-02&CreatedBy=ghj
export async function saveCandidateProject(state) {
  if (state.id === "0") {
    state.CandidateId = state.CandidateId;

    state.projectTitle = state.projectTitle;
    state.projectClient = state.projectClient;
    state.projectStatus = state.projectStatus;
    state.startDate = state.startDate;
    state.endDate = state.endDate;
    state.descriptionProject = state.descriptionProject;
    state.CreatedBy = state.CreatedBy;
  } else {
    state.id = state.id;
    state.CandidateId = state.candidateId;
    state.projectTitle = state.projectTitle;
    state.projectClient = state.projectClient;
    state.projectStatus = state.projectStatus;
    state.startDate = state.startDate;
    state.endDate = state.endDate;
    state.descriptionProject = state.descriptionProject;
    state.CreatedBy = state.createdBy;
  }
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateProject?Id=${state.id}&CandidateId=${state.CandidateId}&ProjectTitle=${state.projectTitle}&ProjectClient=${state.projectClient}&ProjectStatus=${state.projectStatus}&StartDate=${state.startDate}&EndDate=${state.endDate}&DescriptionProject=${state.descriptionProject}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

// changes password
// CandidateSave/ChangePassword?Id=1037&Password=snnn&CreatedBy=1037
export async function changePassword(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/ChangePassword?Id=${state.id}&Password=${state.confirmPassword}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

// https://localhost:7166/api/AdminGet/GetPostedJobsWithFilters?CompanyName=dthrill&Designation=a&City=a&Experience=a

export async function getPostedJobsWithFilters(state) {
  return await resolve(
    axios
      .get(
        `${API_URL}/AdminGet/GetPostedJobsWithFilters?CompanyName=${state.companyName}&Designation=${state.designation}&City=${state.city}&Experience=${state.experience}`
      )
      .then((res) => res.data)
  );
}

//SendOTP/SaveOTPAsync?Email=mitali14%40gmail.com

export async function getOtp(email) {
  return await resolve(
    axios
      .get(`${API_URL}/SendOTP/SaveOTPAsync?Email=${email}`)
      .then((res) => res.data)
  );
}

// verify otp
//
//https://localhost:7166/api/SendOTP/ValidateOTPAsync?Email=mitali14%40gmail.com&OTP=831638

export async function verifyOtp(state) {
  return await resolve(
    axios
      .get(
        `${API_URL}/SendOTP/ValidateOTPAsync?Email=${state.email}&OTP=${state.otp}`
      )
      .then((res) => res.data)
  );
}

// https://localhost:7166/api/SendOTP/IsEmailVerified?Email=vidya.dthrill%40gmail.com

export async function IsEmailVerified(email) {
  return await resolve(
    axios
      .get(`${API_URL}/SendOTP/IsEmailVerified?Email=${email}`)
      .then((res) => res.data)
  );
}

// Save Candidate Job
export async function saveCandidateJob(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateJob?jobId=${state.Id}&CandidateId=${state.CandidateId}&issaved=${state.IsSaved}&isapplied=${state.IsApplied}&CreatedBy=${state.CreatedBy}`
      )
      .then((res) => res.data)
  );
}

// profile percentage https://localhost:7166/api/CandidateGet/GetProfilePercentage?CandidateId=1

export async function profilePercentage(CandidateId) {
  return await resolve(
    axios
      .post(`${API_URL}/CandidateSave/GetProfilePercentage?Id=${CandidateId}`)
      .then((res) => res.data)
  );
}

//AdminGet/GetDashboardCount

export async function getDashboardCount() {
  return await resolve(
    axios.get(`${API_URL}/AdminGet/GetDashboardCount`).then((res) => res.data)
  );
}

//https://localhost:7166/api/CandidateSave/SaveCandidateInterest?CandidateId=1&Interest=Block%20chain&CreatedBy=1

export async function saveCandidateInterest(state) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateSave/SaveCandidateInterest?Id=${state.id}&CandidateId=${state.candidateId}&Interest=${state.interest}&CreatedBy=${state.createdBy}`
      )
      .then((res) => res.data)
  );
}

//https://localhost:7166/api/CandidateGet/GetCandidateInterest?CandidateId=0

export async function getCandidateInterest(CandidateId) {
  return await resolve(
    axios
      .post(`${API_URL}/CandidateGet/GetCandidateInterest?CandidateId=${CandidateId}`)
      .then((res) => res.data)
  );
}

//give candidate job isapplied status
export async function getCandidateJobDetailsById(Id) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateJobDetailsById?Id=${Id}`
      )
      .then((res) => res.data)
  );
}

// https://localhost:7166/api/CandidateGet/GetCandidateDetailsbyEmail?email=mitalinarkhede14%40gmail.com

export async function getCandidateDetailsbyEmail(email) {
  return await resolve(
    axios
      .post(
        `${API_URL}/CandidateGet/GetCandidateDetailsbyEmail?email=${email}`
      )
      .then((res) => res.data)
  );
}