import React from "react";

function PopularSearch() {
  const search = [
    {
      list: "CAE engineer",
    },
    {
      list: "Design engineer",
    },
    {
      list: "CFD simulation engineer",
    },
    {
      list: "Biw product design engineer",
    },
    {
      list: "Biw fixture design engineer",
    },
    {
      list: "System Engineer",
    },
    {
      list: "Lighting engineer ( Exterior trims)",
    },
    {
      list: "Verification and validation engineer",
    },
  ];

  return (
    <>
      <div className="font-Helvetica  relative">
        <div className="font-Helvetica  bg-[url('/src/assets/images/popular-search-bg.jpg')] w-full lg:h-80 md:h-96 h-[500px] p-10 bg-no-repeat bg-cover after:opacity-70 after:inset-0 after:absolute relative after:bg-white"></div>
        <div className="font-Helvetica  absolute top-5 container mx-auto right-0 left-0 w-full lg:px-10 md:px-10 px-0 py-5">
          <div className="font-Helvetica  pb-10">
            <p className="font-Helvetica  text-3xl font-semibold text-center">
              Popular Search
            </p>
          </div>

          <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-5">
            {search?.map((l, idx) => (
              <div
                className="font-Helvetica bg-white rounded-full drop-shadow-2xl p-3 border border-gray-200 hover:border-legavacBlue"
                key={idx}
              >
                <p className="font-Helvetica  text-center text-legavacBlue">{l.list}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}


export default PopularSearch;
