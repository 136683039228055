import React, { useEffect, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import AdvanceJobSection from "./advanceSearch/AdvanceJobSection";
import AdvanceSearch from "./advanceSearch/AdvanceSearch";
import Advertisement from "./advanceSearch/Advertisement";
import { useLocation, /* other hooks */ } from 'react-router-dom';
import { getPostedJobsWithFilters } from "../../config/api";

function AdvanceJobSearch() {
debugger
// const location = useLocation();
// const {data} = location.state;
const [jobs, setJobs] = useState([]);
  
  // console.log("Advance job Props------------->",data)
  const [showFilterdData, setShowFilterdData] = useState(true);

  // he function seleted filter data show kryla paije page vr mnun ahe (handleBtnClose ,handleFilterdData)

  // filter astil tr te kiva reccommended job disle paije
 
  const handleBtnClose = () => {
    setShowFilterdData(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  const GetPostedFilter = async (data) => {
    debugger
       await getPostedJobsWithFilters(data)
        .then((res) => {
          console.log("get Posted filter Jobs--->", res);
          setJobs(res.data);
        })
        .catch((error) => {
          alert(error)
          console.log("error--->", error);
        });
  };

  return (
    <>
      <div className="font-Helvetica  bg-slate-50">
        <div className="font-Helvetica  relative container mx-auto w-full lg:px-20 md:px-10 px-0 pt-28">
          <div className="font-Helvetica  lg:flex md:flex lg:items-center md:items-center md:space-x-2 bg-white lg:space-x-0 lg:space-y-0 md:space-y-0 space-y-2 justify-around border-b p-2 ">
            <div className="font-Helvetica  ">
              <p className="font-Helvetica  font-QuickStand font-semibold text-legavacBlue  lg:text-3xl md:text-2xl text-2xl text-center">
                Advance Job Search
              </p>
              <p className="font-Helvetica  text-slate-400 text-xs text-center">
                you can search your dream job here ... All the best ...!!
              </p>
            </div>

            <div className="font-Helvetica  relative">
              <MdSearch
                size={20}
                className="font-Helvetica  text-legavacBlue absolute top-3 z-10 left-3"
              />
              <input
                type="text"
                placeholder="Search"
                className="font-Helvetica  w-full py-2 pl-10 rounded border border-legavacYellow focus:border-legavacYellow focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          {/* <div className="font-Helvetica  lg:grid lg:grid-cols-6 md:grid md:grid-cols-4 grid-cols-2 gap-2 pt-5">
            {showFilterdData ? (
              <div className="font-Helvetica  border border-legavacYellow  rounded-full flex items-center justify-between px-5 ">
                <p className="font-Helvetica  text-slate-500">location</p>
                <MdClose
                  size={15}
                  className="font-Helvetica  text-slate-500"
                  aria-label="Close"
                  onClick={handleBtnClose}
                />
              </div>
            ) : null}
          </div> */}
          <div className="font-Helvetica   pt-5 pb-10">
            <div className="font-Helvetica  grid lg:grid-cols-7 gap-5 items-start">
              <div className="font-Helvetica  lg:block md:hidden hidden col-span-2 w-full bg-white border shadow shadow-gray-200 px-5">
                <AdvanceSearch />
              </div>
              <div className="font-Helvetica  col-span-3">
                <AdvanceJobSection />
              </div>
              <div className="font-Helvetica  lg:block md:hidden hidden col-span-2 ">
                <Advertisement />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvanceJobSearch;
