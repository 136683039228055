import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateCourse,
  saveCandidateCourseAndCertificate,
} from "../../../../config/api";

function CourseCrtfct() {
  const [course, setCourse] = useState([]);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    certificate: "",
    issuedBy: "",
    validDate: "",
    lifeTime: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  const funShow = () => {
    setShow(true);
  };
  useEffect(() => {
    // getCandidateCourse
    GetCandidateCourse(localStorage.getItem("userId"));
  }, [course.id]);

  const GetCandidateCourse = async (id) => {
    await getCandidateCourse(id)
      .then((res) => {
        console.log("get social link data---.", res);
        if (res.data.length === 0) {
          setShow(true);
          setHide(false);
          setCourse(res.data);
        } else {
          setHide(true);
          setCourse(res.data);
        }
      })
      .catch((error) => {
        alert("get" + error);
      });
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const handleCheckChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  
  // let errors = {};

  const handleSubmit = async () => {
    console.log("handle submit----->", state);
    await saveCandidateCourseAndCertificate(state)
      .then((res) => {
        console.log("course->", res);
        GetCandidateCourse(localStorage.getItem("userId"));
        setShow(false);
        setState({
          id: "0",
          CandidateId: state.CandidateId,
          certificate: "",
          issuedBy: "",
          lifeTime: "",
          CreatedBy: state.CreatedBy,
        });
      })
      .catch((error) => {
        alert("submit" + error);
      });
  };
  const handleDelete = async (id) => {
    
    await deleteRecord({
      tableName: "CoursesAndCertificate",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        GetCandidateCourse(localStorage.getItem("userId"));
        alert("record deleted");
      }
    });
  };
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Courses & Certificates
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>

        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Certificate:
                </p>
                <input
                  type="text"
                  name="certificate"
                  id="certificate"
                  onChange={handleChange}
                  value={state.certificate}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Issued by:
                </p>
                <input
                  type="text"
                  name="issuedBy"
                  id="issuedBy"
                  onChange={handleChange}
                  value={state.issuedBy}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Validity Date:
                </p>
                <input
                  type="date"
                  name="validDate"
                  id="validDate"
                  onChange={handleChange}
                  value={state.validDate}
                  className="font-Helvetica  border text-sm border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />

                <div className="font-Helvetica  flex space-x-1 items-center">
                  <input
                    type="checkbox"
                    name="lifeTime"
                    id="lifeTime"
                    onChange={handleCheckChange}
                    // checked={state.lifeTime.check ? "true" :  "false"}
                    // value={state.lifeTime = 'true' : 'false'}
                    value={state.lifeTime}
                  />
                  <p className="font-Helvetica  text-sm text-slate-700 tracking-wide">
                    Life time
                  </p>
                </div>
              </div>
            </div>
            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {course.length &&
              course?.map((c, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div>
                        <div className="font-Helvetica  flex justify-between">
                          <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                            {c.certificate}
                          </p>
                        </div>
                        <div className="font-Helvetica  flex space-x-1">
                          <p className="font-Helvetica  text-sm text-slate-500">
                            Issued by:
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-700">
                            {c.issuedBy}
                          </p>
                        </div>
                        <div className="font-Helvetica  flex space-x-1">
                          <p className="font-Helvetica  text-sm text-slate-500">
                            Validity:
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-700">
                            {c.validDate.split("T")[0]}
                          </p>
                        </div>
                        <div className="font-Helvetica  flex space-x-1">
                          <p className="font-Helvetica  text-sm text-slate-500">
                            Life Time:
                          </p>
                          <p className="font-Helvetica  text-sm text-slate-700">
                            {c.lifeTime}
                          </p>
                        </div>
                       
                      </div>
                      <div className="font-Helvetica  flex space-x-2">
                          <MdEdit
                            size={20}
                            className="font-Helvetica  text-green-600 cursor-pointer"
                            onClick={() => {
                              setShow(true);
                              const editData = course.filter(
                                (data) => data.id === c.id
                              );
                              console.log(editData[0]);
                              setState(editData[0]);
                            }}
                          />
                          <MdDelete
                            size={20}
                            className="font-Helvetica  text-red-600 cursor-pointer"
                            onClick={() => {
                              const DeleteData = course.filter(
                                (data) => data.id === c.id
                              );
                              handleDelete(DeleteData[0].id);
                            }}
                          />
                        </div>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CourseCrtfct;
