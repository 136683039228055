import React from "react";
import "./App.css";
import Home from "./pages/main/Home";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./pages/main/ContactUs";
import CareerAdvice from "./pages/main/CareerAdvice";
import Job from "./pages/main/Job";
import About from "./pages/main/About";
import AdvanceJobSearch from "./pages/sub/AdvanceJobSearch";
import ServiceWeOffer from "./pages/main/ServiceWeOffer";
import Article from "./pages/main/Article";
import Affilations from "./pages/main/Affilations";
import AppLayoutCandidate from "./components/AppLayoutCandidate";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import RecruiterSignup from "./pages/auth/RecruiterSignup";
import ProfileLayout from "./components/ProfileLayout";
import MyProfile from "./pages/sub/profile/MyProfile";
import MyResume from "./pages/sub/profile/MyResume";
import SavedJobs from "./pages/sub/profile/SavedJobs";
import AppliedJobs from "./pages/sub/profile/AppliedJobs";
import ChangePassword from "./pages/sub/profile/ChangePassword";
import HomeAdvanceSearch from "./pages/sub/HomeAdvanceSearch";
import CreateJobALert from "./pages/sub/profile/CreateJobALert";
import PostProject from "./components/PostProject";
import Candidates from "./components/Candidates";
import CandidatesDetails from "./components/CandidatesDetails";
import Pricing from "./pages/main/Pricing";

function App() {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/recruiter-signup" element={<RecruiterSignup />} />

        <Route path="/app" element={<AppLayoutCandidate />}>
          <Route path="about-us" element={<About />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="careeradvice" element={<CareerAdvice />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          <Route path="jobs" element={<Job />} />
          <Route path="pricing" element={<Pricing />} />

          <Route path="post_project" element={<PostProject />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="candidates_details" element={<CandidatesDetails />} />

          <Route path="advance-job-search" element={<AdvanceJobSearch />} />
          <Route
            path="advance-job-homeSearch"
            element={<HomeAdvanceSearch />}
          />
          <Route path="ServiceWeOffer" element={<ServiceWeOffer />} />
          {/* <Route path="article" element={<Article />} /> */}
          <Route path="affilation" element={<Affilations />} />
        </Route>
        <Route path="/profile" element={<ProfileLayout />}>
          <Route path="my-profile" element={<MyProfile />} />
          <Route path="my-resume" element={<MyResume />} />
          <Route path="saved-jobs" element={<SavedJobs />} />
          <Route path="applied-jobs" element={<AppliedJobs />} />
          <Route path="create-job-alert" element={<CreateJobALert />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
}
export default App;
