import React from "react";
import ChannelSourcing from "../sub/services/ChannelSourcing";
import RecruitingWorkflow from "../sub/services/RecruitingWorkflow";
import RecruitmentPlatform from "../sub/services/RecruitmentPlatform";
import VendorManagement from "../sub/services/VendorManagement";
import WorkFlowSolution from "../sub/services/WorkFlowSolution";

function ServiceWeOffer() {
  

  return (
    <>
      <div>
        <WorkFlowSolution />
        <ChannelSourcing />
        <RecruitmentPlatform />
        <VendorManagement />
        <RecruitingWorkflow />
      </div>
    </>
  );
}

export default ServiceWeOffer;
