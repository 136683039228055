import React, { useEffect, useState } from "react";
import { IMAGES } from "../stores";
import {
  MdOutlineWatchLater,
  MdOutlineLocationOn,
  MdClose,
  MdOutlineDescription,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Toster from "../stores/Toster";
import { getPostedJobs } from "../config/api";
import ViewFullPage from "../pages/main/ViewFullPage";
import { SiMinds } from "react-icons/si";

const JobListing = () => {

  const [list, setList] = useState([]);
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "success") {
      ToastProperties = {
        id: list.length + 1,
        title: "Successfull",
        icon: "MdCheck",
        description: "You have succesfully applied for Job",
        // backgroundColor: "#cefad0",
        backgroundColor: "#fff",
        borderColor: "#00A300",
        color: "#00A300",
      };
      setList([...list, ToastProperties]);
    }
  };
  

  const companyWisejobs = [
    {
      companyName: "Wipro",
      jobTitle: "CAE engineer",
      city: "Pune",
      qualification: "BE,MCA",
      description:
        "Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content",
      createdDate: "17 Apr 2023",
    },
    {
      companyName: "Tata Consultancy Service",
      jobTitle: "Design engineer",
      city: "Pune,mumbai",
      qualification: "BE,MCA,MSC,Mtech",
      description:
        "Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content",
      createdDate: "20 Apr 2023",
    },
    {
      companyName: "Tech Mahindra",
      jobTitle: "CFD simulation engineer",
      city: "Pune",
      qualification: "BE,MCA",
      description:
        "Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content",
      createdDate: "26 May 2023",
    },
    {
      companyName: "Accenture",
      jobTitle: "Biw product design engineer",
      city: "Banglore",
      qualification: "BE,MCA",
      description:
        "Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content",
      createdDate: "27 may 2023",
    },
    {
      companyName: "Amazon",
      jobTitle: "Biw fixture design engineer",
      city: "Pune,Mumbai",
      qualification: "BE,MCA",
      description:
        "Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in place of English to emphasise design elements over content",
      createdDate: "17 Apr 2023",
    },
   
  ];
  return (
    <>
      <div className="font-Helvetica  bg-slate-50 ">
        <div className="font-Helvetica  container mx-auto w-full lg:px-20 md:px-10 px-0 py-10 ">
          <div className="font-Helvetica  pb-10">
            <p className="font-Helvetica  text-3xl font-semibold text-center">
              Job Listing
            </p>
          </div>
          <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
            {companyWisejobs?.map((l, idx) => (
              <div
                className="font-Helvetica  job-listing bg-white border hover:border-legavacBlue hover:shadow-2xl hover:shadow-slate-300 rounded"
                key={idx}
              >
                <div className="relative">
                  <div className=" p-5">
                    <div className="flex md:space-x-7 space-x-5 items-center">
                      <div className="company-logo grid placeitems-center space-y-1">
                        <img
                          src={IMAGES.CompanyLogo}
                          alt=""
                          className=" w-12 h-12"
                        />
                      </div>
                      <div className="font-Helvetica  space-y-1">
                        <p className="font-Helvetica  text-xl text-legavacBlue font-semibold truncate">
                          {`${l.companyName.substring(0, 25)}`}
                        </p>
                        <p className="font-Helvetica  text-sm font-medium text-slate-700">
                          {l.jobTitle}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="font-Helvetica space-x-2 p-5 items-center border-t">
                    <div className="font-Helvetica space-y-1">
                      <div className="flex space-x-2 items-center">
                        <MdOutlineLocationOn
                          size={20}
                          className="font-Helvetica  text-slate-500"
                        />
                        <p className="font-Helvetica  text-sm text-slate-600">
                          {l.city}
                        </p>
                      </div>
                      <div className="flex space-x-2 items-center">
                        <SiMinds
                          size={20}
                          className="font-Helvetica  text-slate-500"
                        />
                        <p className="font-Helvetica  text-gray-500 text-sm">
                          {l.qualification}
                        </p>
                      </div>
                      <div className="flex">
                        <div>
                          <MdOutlineDescription
                            size={20}
                            className="font-Helvetica  text-slate-500"
                          />
                        </div>
                        <p className="font-Helvetica pl-2 text-xs font-medium text-slate-700">
                          {`${l.description.substring(0, 100)}`}...
                        </p>
                      </div>
                      {/* <p className="font-Helvetica  text-gray-500 text-sm">                       
                        {l.skill}
                      </p> */}
                    </div>

                    <div className="font-Helvetica flex justify-between items-center pt-3">
                      <div className="flex">
                        <MdOutlineWatchLater size={15} color="gray" />
                        <p className="font-Helvetica pl-1 text-xs text-gray-500">
                          {l.createdDate}
                        </p>
                      </div>
                      {/* <div>
                        <div
                          className="text-red-500"
                          onClick={handleClick}
                        >
                          {isLiked ? (
                            <IoMdHeart className="w-6 h-6 fill-current" />
                          ) : (
                            <IoMdHeartEmpty className="w-6 h-6 fill-current" />
                          )}
                        </div>
                      </div> */}
                      {/* <div>
                        <Button
                          type="button"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          className="bg-legavacBlue py-1 px-5 text-white font-medium text-sm rounded transition duration-150 ease-in-out"
                          handleClick={() => showToaster("success")}
                        >
                          Apply
                        </Button>
                      </div> */}
                      <div>
                        <p
                          className="font-Helvetica  rounded bg-legavacBlue px-2 py-2  text-white text-sm cursor-pointer text-center"
                          data-te-offcanvas-toggle
                          data-te-target="#offcanvasBottom2"
                          aria-controls="offcanvasBottom2"
                          onClick={() => {
                            const editData = companyWisejobs.filter(
                              (data) => data.id === l.id
                            );
                            // setState(editData[0]);
                          }}
                        >
                          View Job
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Toster toastList={list} setList={setList} />
          {/* <div className="font-Helvetica  pt-10 flex justify-center">
            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              className="font-Helvetica  bg-legavacYellow text-white font-semibold tracking-wide px-5 py-2 rounded transition duration-150 ease-in-out"
              onClick={() =>
                navigate("/app/advance-job-homeSearch", {
                  state: companyWisejobs,
                })
              }
            >
              More Listing
            </button>
          </div> */}
        </div>
      </div>

      <div
        className="font-Helvetica  fixed bottom-0 flex flex-col max-w-full lg:bg-gray-50
         md:bg-gray-50 bg-white invisible bg-clip-padding shadow-sm outline-none transition
          duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-full max-h-full z-[1045] 
          translate-y-full [&[data-te-offcanvas-show]]:transform-none"
        tabIndex="-1"
        id="offcanvasBottom2"
        aria-labelledby="offcanvasBottomLabel"
        data-te-offcanvas-init
      >
        <div className="font-Helvetica  offcanvas-header flex items-center justify-end p-5 px-20">
          <button
            type="button"
            className="font-Helvetica  btn-close box-content w-9 h-9 p-2 -my-5 -mr-2 text-white border-none rounded-none focus:shadow-none focus:outline-none hover:opacity-75 hover:no-underline"
            data-te-offcanvas-dismiss
          >
            <MdClose size={30} className="bg-red-500" />
          </button>
        </div>
        <div className="font-Helvetica  offcanvas-body flex-grow lg:p-4 overflow-y-auto small">
          <ViewFullPage state={[companyWisejobs]} />
        </div>
      </div>
    </>
  );
};
export default JobListing;
