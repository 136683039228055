import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../../stores";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import Toster from "../../stores/Toster";
import {
  IsEmailVerified,
  getCandidate,
  getCandidateDetailsbyEmail,
  getOtp,
} from "../../config/api";
import VerificationEmail from "./VerificationEmail";

function Login() {
  const navigate = useNavigate();

  // const storedUser = JSON.parse(localStorage.getItem("userState"));
  const [storedUser, setStoredUser] = useState({});

  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML =
      '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

  // useEffect(() => {
  //   GetOldPassWord(localStorage.getItem("userId"));
  // }, []);

  const [matchPwd, setMatchPwd] = useState();
  // const GetOldPassWord = async (id) => {
  //   debugger;
  //   await getCandidate(id).then((res) => {
  //     setMatchPwd(res.data[0].password);
  //     console.log("get password-->", res.data[0].password);
  //     // storedUser.Password = matchPwd;
  //   });
  // };

  const [state, setState] = useState({
    email: "",
    password: "",
    errors: [],
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: [e.target.value] });
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(true);
  };

  const closedModal = (e) => {
    setShowModal(false);
  };

  const chkFiles = () => {
    const errors = {};
    let IsValid = true;
    //const regex_email = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!state.email) {
      IsValid = false;
      errors["email"] = "Email is required";
    } else if (!state.email.match(regex_email)) {
      IsValid = false;
      errors["email"] = "please enter valid email id";
    } else {
      errors["email"] = "";
    }

    if (!state.password) {
      IsValid = false;
      errors["password"] = "please enter password";
    } else {
      errors["password"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  const validate = () => {
    debugger;
    const errors = {};
    let IsValid = true;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!state.email) {
      IsValid = false;
      errors["email"] = "Email is required";
    } else if (!state.email.match(regex_email)) {
      IsValid = false;
      errors["email"] = "please enter valid email id";
    } else if (state.email !== storedUser && storedUser.email) {
      console.log("value storedUser email-------->", storedUser.email);
      IsValid = false;
      errors["email"] = "Please enter register email id";
    } else {
      errors["email"] = "";
    }

    if (!state.password) {
      IsValid = false;
      errors["password"] = "please enter password";
    } else if (state.password !== storedUser && storedUser.password) {
      console.log("value storedUser password-------->", storedUser.password);
      IsValid = false;
      errors["password"] = "Enter wrong password";
    } else {
      errors["password"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async () => {
    debugger;
    
      navigate("/profile/my-profile");
      sessionStorage.setItem("isLogInCheck", "1");
  };
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 overflow-y-auto">
        <div className="font-Helvetica  col-span-2 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60">
          <img
            src={IMAGES.Login}
            alt=""
            className="font-Helvetica  w-full md:min-h-screen"
          />
          <img
            src={IMAGES.itonelogo}
            alt=""
            className="font-Helvetica  w-36 z-10 absolute top-10 left-10 shadow-md shadow-white p-3"
          />

          <div className="font-Helvetica  absolute z-10 lg:top-1/2 md:top-10 top-40 left-0 right-0 mx-auto space-y-5">
            <p className="font-QuickStand text-white md:text-5xl text-3xl  font-bold text-center">
              Welcome to{" "}
              <span
                className="font-QuickStand txt-rotate text-legavacYellow"
                data-period="2000"
                data-rotate='[ "ITOne !", "ITOne !"]'
              ></span>
            </p>
          </div>
        </div>
        <div className="font-Helvetica  bg-white">
          {/* <div
            className="font-Helvetica  flex items-center space-x-2 cursor-pointer p-5"
          >
            <div className="font-Helvetica  rounded-full border border-blue-800 p-1">
              <MdOutlineArrowBackIosNew color="blue" size="10" />
            </div>
            <div>
              <Link to="/">
                <p
                  className="font-Helvetica  text-blue-800 text-xs"
                  data-te-modal-dismiss
                >
                  Back to home
                </p>
              </Link>
            </div>
          </div> */}
          <div className="font-Helvetica  lg:px-14 md:px-10 px-5 py-5">
            <div className="font-Helvetica  ">
              <p className="font-Helvetica  text-3xl font-semibold text-left py-5 uppercase underline underline-offset-8 decoration-legavacYellow">
                Login
              </p>
              <div className="font-Helvetica  py-5 space-y-2">
                <div className="font-Helvetica  space-y-1">
                  <p className="font-Helvetica  text-slate-400 text-xs">
                    Email ID
                  </p>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email id"
                    className="font-Helvetica  w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                    onChange={handleChange}
                    value={state.email}
                  />
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="font-Helvetica relative space-y-1">
                  <p className="font-Helvetica  text-slate-400 text-xs">
                    Password
                  </p>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    onChange={handleChange}
                    value={state.password}
                    className="font-Helvetica  w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                  />
                  <button
                    className="absolute inset-y-0 right-0 top-2 px-3 py-2 text-gray-500 transition duration-300 ease-in-out"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VscEyeClosed size={15} />
                    ) : (
                      <VscEye size={15} />
                    )}
                  </button>
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs pt-1">
                      {state.errors.password}
                    </p>
                  ) : null}
                </div>

                <div className="font-Helvetica  flex justify-end">
                  {/* <div className="font-Helvetica  flex space-x-2">
                    <input type="checkbox" />
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Keep me signed in
                    </p>
                  </div> */}
                  <div>
                    <p className="font-Helvetica  text-slate-400 text-xs hover:text-legavacBlue hover:underline">
                      Forgot Password ?
                    </p>
                  </div>
                </div>
              </div>

              <div className="font-Helvetica  grid place-items-center">
                <button
                  type="button"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  className="font-Helvetica  bg-legavacBlue font-semibold rounded text-white py-1 px-5 uppercase cursor-pointer"
                  onClick={handleSubmit}
                >
                  Log In
                </button>
              </div>

              <p className="font-Helvetica  text-xs text-center pt-5 text-slate-400">
                Don't have an account ?{" "}
                <a
                  href="/signup"
                  className="font-Helvetica  text-legavacBlue hover:underline cursor-pointer"
                >
                  Register
                </a>
              </p>
            </div>
            <div>
              <p
                className="font-Helvetica  text-center relative py-5 text-slate-500
                          before:absolute before:top-1/2 lg:before:left-5 md:before:left-20 before:left-7 lg:before:w-32 before:w-28 before:h-[1px] before:bg-slate-400
                          after:absolute after:top-1/2 lg:after:right-5 md:after:right-20 after:right-7 lg:after:w-32 after:w-28 after:h-[1px] after:bg-slate-400"
              >
                or
              </p>
              <div className="font-Helvetica  space-y-3">
                <p className="font-Helvetica  text-center text-xs">
                  with your social network
                </p>
                <div className="font-Helvetica  flex space-x-3 justify-center ">
                  <a
                    href="#"
                    className="font-Helvetica  border border-slate-200 hover:bg-slate-200 rounded-full p-1"
                  >
                    <img
                      src={IMAGES.Gooogle}
                      alt=""
                      className="font-Helvetica  w-5 h-5"
                    />
                  </a>
                  <a
                    href="#"
                    className="font-Helvetica  border border-slate-200 hover:bg-slate-200 rounded-full p-1"
                  >
                    <img
                      src={IMAGES.Linkedin}
                      alt=""
                      className="font-Helvetica  w-5 h-5"
                    />
                  </a>
                  <a
                    href="#"
                    className="font-Helvetica  border border-slate-200 hover:bg-slate-200 rounded-full p-1"
                  >
                    <img
                      src={IMAGES.Facebook}
                      alt=""
                      className="font-Helvetica  w-5 h-5"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <VerificationEmail
          isOpen={showModal}
          email={state.email}
          onClose={() => closedModal()}
        />
      )}
    </>
  );
}

export default Login;
