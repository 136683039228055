import React, { useEffect, useState } from "react";
import { deleteRecord, getJobAlert, saveJobAlert } from "../../../config/api";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";

function CreateJobALert() {
  const [alertState, setAlert] = useState([]);
  const [state, setState] = useState({
    id: "0",
    CandidateId: localStorage.getItem("userId"),
    keyword: "",
    location: "",
    expectedSalary: "",
    experience: "",
    emailId: "",
    CreatedBy: localStorage.getItem("userId"),
    errors: [],
  });

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const funShow = () => {
    setShow(true);
  };

  useEffect(() => {
    //getJobAlert
    GetJobAlert(localStorage.getItem("userId"));
  }, [alertState.id]);

  const GetJobAlert = async (id) => {
    await getJobAlert(id).then((res) => {
      console.log(res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(false);
        setAlert(res.data)
      } else {
        setHide(true);
        setAlert(res.data);
      }
    });
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const [list, setList] = useState([]);
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "Success") {
      ToastProperties = {
        id: list.length + 1,
        title: "Error",
        description: "Job Alert Created",
        icon: "MdOutlineErrorOutline",
        backgroundColor: "#fef2f2",
        borderColor: "#dc2626",
        color: "#dc2626",
      };
      setList([...list, ToastProperties]);
    }
  };
  const handleSubmit = async () => {
    
    console.log(state);
    await saveJobAlert(state)
      .then((res) => {
        console.log("alert -->", res);
        if (res.data[0].id === "Not Exist") {
          alert("please enter your register email");
        } else {
          GetJobAlert(localStorage.getItem("userId"));
          setShow(false);
          alert("done");
          setState({
            id: "0",
            CandidateId: state.CandidateId,
            keyword: "",
            location: "",
            expectedSalary: "",
            experience: "",
            emailId: "",
            CreatedBy: state.CreatedBy
          })
        }
      })
      .catch((error) => alert(error));
  };

  const handleDelete = async (id) => {
    
    await deleteRecord({
      tableName: "createJobAlert",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        GetJobAlert(localStorage.getItem("userId"));
        alert("record deleted");
      }
    });
  };

  return (
    <>
      <div>
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Create Job Alert
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {/* <p className="font-Helvetica  text-sm px-3 mb-3">Alerts Keep you up to date on newly added jobs ...!!</p> */}
        {show ? (
          <div>
            <div className="font-Helvetica  pt-8 grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Keyword :
                </p>
                <input
                  type="text"
                  placeholder="Enter keyword"
                  onChange={handleChange}
                  value={state.keyword}
                  name="keyword"
                  id="keyword"
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Location :
                </p>
                <input
                  type="text"
                  placeholder="Enter location"
                  onChange={handleChange}
                  value={state.location}
                  name="location"
                  id="location"
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Experience :
                </p>
                <input
                  type="text"
                  placeholder="Enter experience"
                  onChange={handleChange}
                  value={state.experience}
                  name="experience"
                  id="experience"
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Expected Salary :
                </p>
                <input
                  type="text"
                  placeholder="Enter expected salary"
                  name="expectedSalary"
                  id="expectedSalary"
                  onChange={handleChange}
                  value={state.expectedSalary}
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Email ID :
                </p>
                <input
                  type="email"
                  placeholder="Enter email ID"
                  onChange={handleChange}
                  name="emailId"
                  id="emailId"
                  value={state.emailId}
                  className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />
              </div>
            </div>
            <div className="font-Helvetica  pt-10">
              <button
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                className="font-Helvetica  py-2 px-5 text-sm rounded bg-legavacBlue text-white uppercase"
                onClick={handleSubmit}
              >
                Create Job Alert
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {hide ? (
        <ol className="font-Helvetica pt-5 border-l-2 border-info-100">
          {alertState &&
            alertState?.map((a, idx) => (
              <li key={idx}>
                <div className="font-Helvetica flex-start md:flex">
                  <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                    <HiOutlineAcademicCap />
                  </div>
                  <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                   
                   <div> <div className="font-Helvetica  flex justify-between">
                      <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                        {a.keyword}
                      </p>
                    </div>
                    <p className="font-Helvetica  text-sm">{a.location}</p>
                    <p className="font-Helvetica  text-sm text-slate-500">
                      {a.emailId}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500">
                      {a.expectedSalary}
                    </p>
                    </div>
                    <div className="font-Helvetica  flex space-x-2">
                  {/* <MdEdit
                    size={20}
                    className="font-Helvetica  text-green-600 cursor-pointer"
                    onClick={() => {
                      setShow(true);
                      const editData = alertState.filter((data) => data.id === a.id);
                      setState(editData[0]);
                    }}
                  /> */}
                  <MdDelete
                    size={20}
                    className="font-Helvetica  text-red-600 cursor-pointer"
                    onClick={() => {
                      const DeleteData = alertState.filter((data) => data.id === a.id);
                      handleDelete(DeleteData[0].id);
                    }}
                  />
                </div>
                  </div>
                </div>
              
              </li>
            ))}
        </ol>
      ) : (
        ""
      )}
    </>
  );
}

export default CreateJobALert;
