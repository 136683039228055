import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../../stores";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import VerificationEmail from "./VerificationEmail";
import Toster from "../../stores/Toster";

function RecruiterSignup() {
    // const verifiedEmail = props.verify;
  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML =
      '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const companySignUpDetails = {
    companyName: "",
    email: "",
    password: "",
    mobile: "",
    check: "",
    role: "recruiter",
    errors: [],
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    // const regex_name = /^[A-Za-z\s]+$/;
    const regex_email =
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    const mobilenumber = /^[0-9]/;

    if (!state.companyName) {
      IsValid = false;
      errors["companyName"] = "Company name is required";
    } else {
      errors["companyName"] = "";
    }

    if (!state.mobile) {
      IsValid = false;
      errors["mobile"] = "mobile number is required";
    } else if (state.mobile.length < 10 || state.mobile.length > 10) {
      IsValid = false;
      errors["mobile"] = "mobile numbet must have 10 digits";
    } else if (!state.mobile.match(mobilenumber)) {
      IsValid = false;
      errors["mobile"] = "please enter valid mobile number";
    } else {
      errors["mobile"] = "";
    }

    if (!state.email) {
      IsValid = false;
      errors["email"] = "Email is required";
    } else if (!state.email.match(regex_email)) {
      IsValid = false;
      errors["email"] = "please enter valid company email id";
    } else {
      errors["email"] = "";
    }

    if (!state.password) {
      IsValid = false;
      errors["password"] = "please enter password";
    } else {
      errors["password"] = "";
    }
   
    if (!state.check) {
      IsValid = false;
      errors["check"] = "please agree above terms";
    } else {
      errors["check"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const [state, setState] = useState(companySignUpDetails);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: [e.target.value] });
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };
  const clearState = () => {
    setState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
    });
  };
  const [list, setList] = useState([]);
  let ToastProperties = null;
  const showToaster = (type) => {
    if (type === "danger") {
      ToastProperties = {
        id: list.length + 1,
        title: "Error",
        description: "Please enter all fields",
        icon: "MdOutlineErrorOutline",
        backgroundColor: "#e60000",
      };
      setList([...list, ToastProperties]);
    }
  };
  const handleSubmit = () => {
    localStorage.setItem("role", JSON.stringify(companySignUpDetails.role));

    if (validate()) {
      alert("done");
      console.log("state--------->", state);
      navigate("/careeradvice");
      clearState();
    } else {
      alert("please fill all the fileds");
      navigate("/");
    }
  };
  return (
    <>
      <div className="font-Helvetica w-full grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 overflow-y-auto">
        <div className="font-Helvetica lg:col-span-3 lg:fixed lg:top-0 lg:left-0 lg:right-0 lg:w-3/5 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60">
          <img
            src={IMAGES.Login}
            alt=""
            className="font-Helvetica  w-full md:min-h-screen"
          />
          <Link to="/">
            <img
              src={IMAGES.MainLogo}
              alt=""
              className="font-Helvetica  w-36 z-10 absolute top-10 left-10 shadow-md shadow-white p-3"
            />
          </Link>

          <div className="font-Helvetica  absolute z-10 lg:top-1/2 md:top-80 top-40 left-5 md:left-60 space-y-5">
            <p className="font-Helvetica  font-QuickStand text-white md:text-5xl text-3xl  font-bold text-center">
              Welcome to{" "}
              <span
                className="font-QuickStand  txt-rotate text-legavacYellow"
                data-period="2000"
                data-rotate='[ "LegaVac !", "LegaVac !"]'
              ></span>
            </p>
          </div>
        </div>

        <div className="font-Helvetica lg:w-full bg-white col-span-2 lg:grid lg:place-items-end">
          <div
            className="font-Helvetica  p-5 flex space-x-2 items-center cursor-pointer"
            data-te-offcanvas-dismiss
          >
            <div className="font-Helvetica  rounded-full border border-blue-800 p-1">
              <MdOutlineArrowBackIosNew color="blue" size="10" />
            </div>
            <div>
              <Link to="/">
                <p className="font-Helvetica  text-blue-800 text-xs">
                  Back to home
                </p>
              </Link>
            </div>
          </div>

          <div className="font-Helvetica  lg:px-10 md:px-10 px-5 lg:py-0 md:py-5 py-5">
            <div className="font-Helvetica  pb-5">
              <p className="font-Helvetica  text-3xl font-semibold text-left uppercase underline underline-offset-8 decoration-legavacYellow">
                Recruiter Register
              </p>
              <div>
                <div className="font-Helvetica  space-y-3 py-3">
                  <p className="font-Helvetica  text-start text-xs">
                    It only takes a couple of minutes to get started
                  </p>
                </div>
              </div>

              <div>
                <div className="font-Helvetica py-5 space-y-2">
                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Company Name
                    </p>
                    <input
                      type="text"
                      placeholder="Enter your company name"
                      name="companyName"
                      id="companyName"
                      onChange={handleChange}
                      value={state.companyName}
                      className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.companyName}
                      </p>
                    ) : null}
                  </div>
                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Email ID
                    </p>
                    <input
                      type="text"
                      placeholder="Enter email id"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={state.email}
                      className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.email}
                      </p>
                    ) : null}
                  </div>

                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Password
                    </p>
                    <input
                      type="password"
                      placeholder="Enter password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      value={state.password}
                      className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.password}
                      </p>
                    ) : null}
                  </div>
                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Mobile Number
                    </p>
                    <div className="font-Helvetica  flex space-x-2">
                      <select className="font-Helvetica  border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs">
                        <option>+91</option>
                        <option>+92</option>
                        <option>+93</option>
                      </select>
                      <input
                        type="number"
                        placeholder="Enter mobile number"
                        name="mobile"
                        id="mobile"
                        maxLength={10}
                        onChange={handleChange}
                        value={state.mobile}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                    </div>
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.mobile}
                      </p>
                    ) : null}
                  </div>

                  <div className="font-Helvetica  flex  lg:items-center md:items-center space-x-2">
                    <div>
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        name="check"
                        id="check"
                      />
                    </div>
                    <p className="font-Helvetica  text-xs text-slate-400">
                      By signing up, you agree to our{" "}
                      <span className="font-Helvetica  text-legavacBlue">
                        <a href="/">Terms and conditions</a> and{" "}
                        <a href="/">Privacy Policy</a>
                      </span>
                    </p>
                  </div>
                  {state.errors ? (
                    <p className="font-Helvetica  text-red-500 text-xs">
                      {state.errors.check}
                    </p>
                  ) : null}
                </div>
                <div className="font-Helvetica  grid place-items-center">
                  <button
                    type="submit"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="font-Helvetica  bg-legavacBlue font-semibold rounded text-white py-1 px-5 uppercase cursor-pointer"
                    onClick={handleSubmit}
                  >
                    Register
                  </button>
                </div>
              </div>

              <p className="font-Helvetica  text-xs text-center pt-5 text-slate-400">
                Already have an account ?{" "}
                <Link to="/login">
                  <span
                    className="font-Helvetica  text-legavacBlue hover:underline cursor-pointer"
                    data-te-offcanvas-toggle
                    data-te-target="#offcanvasExample2"
                    aria-controls="offcanvasExample2"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  >
                    Login
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Toster toastList={list} setList={setList} />

      <div
        data-te-modal-init
        className="font-Helvetica  fixed top-0 left-0 z-[1055] hidden h-full w-full bg-black/[0.50] overflow-y-auto overflow-x-hidden outline-none"
        id="exampleModalCenteredScrollable"
        tabIndex="-1"
        aria-labelledby="exampleModalCenteredScrollable"
        aria-modal="true"
        role="dialog"
      >
        <div
          data-te-modal-dialog-ref
          className="font-Helvetica  pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px]
           items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto 
           min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
        >
          <div className="font-Helvetica  pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
            <VerificationEmail email={state.email} />
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterSignup;
