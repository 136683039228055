import React, { useEffect, useState } from "react";
import { IMAGES } from "../../stores";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineWatchLater,
  MdPerson,
} from "react-icons/md";
function CareerAdvice() {
  const blogData = [
    {
      title: "Do You Recognize The Value Of These 'Little Companies'?",
      img: IMAGES.contactUsBg,
      name: "vidya jhdghsdhghg",
      designation: "designer",
      date: "20/02/2023",
      blog: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      title: "Do You Recognize The Value Of These 'Little Companies'?",
      img: IMAGES.contactUsBg,
      name: "vidya",
      date: "20/02/2023",
      blog: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      title: "Do You Recognize The Value Of These 'Little Companies'?",
      img: IMAGES.contactUsBg,
      designation: "designer",
      name: "vidya",
      date: "20/02/2023",
      blog: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      title: "Do You Recognize The Value Of These 'Little Companies'?",
      img: IMAGES.contactUsBg,
      name: "vidya",
      date: "20/02/2023",
      blog: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      title: "Do You Recognize The Value Of These 'Little Companies'?",
      img: IMAGES.contactUsBg,
      name: "vidya",
      date: "20/02/2023",
      blog: "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  return (
    <>
      <div className="font-Helvetica  bg-neutral-50 pt-20">
        <div className="font-Helvetica  container w-full mx-auto lg:px-20 ">
          <div className="font-Helvetica  border-b p-3 mb-5">
            <p className="font-Helvetica  text-3xl text-center tracking-wide  text-legavacBlue font-semibold">
              Career Advice
            </p>
          </div>
          <div className="font-Helvetica  grid lg:grid-cols-7 md:grid-cols-7 grid-cols-1 lg:gap-10">
            <div className="font-Helvetica  lg:col-span-5 md:col-span-5  py-5">
              {blogData?.map((blog, id) => (
                <div>
                  <ShowBlog {...blog} />
                </div>
              ))}
            </div>
            <div className="font-Helvetica  recenet col-span-2 lg:pt-20 md:pt-16 lg:px-0 md:px-5 px-0">
              <div className="font-Helvetica  ">
                <p className="font-Helvetica  text-xl font-semibold mb-5">
                  Recent Post
                </p>

                <ul className="font-Helvetica  ">
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    <p className="font-Helvetica">
                      {" "}
                      <span className="font-Helvetica  font-medium">
                        {" "}
                        Employment Summit:{" "}
                      </span>
                      Guiding Syrian Refugees in finding Menaningful Employment{" "}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                      March 16, 2016 Nunc libero
                    </p>
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    <p className="font-Helvetica">
                      {" "}
                      <span className="font-Helvetica  font-medium">
                        {" "}
                        Employment Summit:{" "}
                      </span>
                      Guiding Syrian Refugees in finding Menaningful Employment{" "}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                      March 16, 2016 Nunc libero
                    </p>
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    <p className="font-Helvetica">
                      {" "}
                      <span className="font-Helvetica  font-medium">
                        {" "}
                        Employment Summit:{" "}
                      </span>
                      Guiding Syrian Refugees in finding Menaningful Employment{" "}
                    </p>
                    <p className="font-Helvetica  text-sm text-slate-500 pt-2">
                      March 16, 2016 Nunc libero
                    </p>
                  </li>
                </ul>
              </div>
              <div className="font-Helvetica  pt-20">
                <p className="font-Helvetica  text-xl font-semibold mb-2">
                  categories
                </p>
                <ul>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    News
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    News
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    News
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    News
                  </li>
                  <li className="font-Helvetica  py-5 text-sm hover:bg-slate-100 border-b border-legavacYellow  hover:border-r-4 hover:border-r-legavacBlue">
                    News
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// add read more button and use card type design
function ShowBlog({ img, name, date, blog, designation, title }) {
  useEffect(() => {
    const callback = function (entries) {
      entries.forEach((entry) => {
        // Is the element in the viewport?
        if (entry.isIntersecting) {
          // Add the fadeIn class:
          entry.target.classList.add("motion-safe:animate-fadeIn");
        } else {
          // Otherwise remove the fadein class
          entry.target.classList.remove("motion-safe:animate-fadeIn");
        }
      });
    };
    const targets = document.querySelectorAll(".js-show-on-scroll");
    const observer = new IntersectionObserver(callback);
    targets.forEach(function (target) {
      target.classList.add("opacity-0");
      observer.observe(target);
    });
  });
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div>
        <div className="font-Helvetica  grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div className="font-Helvetica  lg:flex lg:justify-center">
            <div className="font-Helvetica  lg:block   items-center space-x-5">
              <div className="lg:block flex lg:spac-x-0 space-x-5">
                <div className="font-Helvetica  pb-5 grid lg:place-items-center">
                  <MdPerson
                    size={72}
                    className="font-Helvetica  border border-legavacBlue bg-slate-50  text-gray-400 rounded-full p-1"
                  />
                </div>
                <div className="font-Helvetica  ">
                  <p className="font-Helvetica  lg:text-center md:text-left opacity-50 text-sm">
                    written by{" "}
                  </p>

                  <p className="font-Helvetica pb-1 text-legavacBlue ">
                    {name}
                  </p>
                  <div className="font-Helvetica  flex space-x-2 items-center">
                    <div className="font-Helvetica  ">
                      <MdOutlineWatchLater
                        size={18}
                        className="font-Helvetica  text-legavacYellow"
                      />
                    </div>
                    <div className="font-Helvetica  text-sm">{date}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="font-Helvetica  col-span-2 mb-20 js-show-on-scroll">
            <div>
              <p className="font-Helvetica  text-xl font-[600] capitalize mb-5">
                {title}
              </p>
              <img src={img} alt="" />
            </div>
            <div className="font-Helvetica  text-justify pt-5 text-md">
              <p className="font-Helvetica">
                {showMore ? blog : `${blog.substring(0, 100)}`}
              </p>
              <p
                className="font-Helvetica  text-legavacBlue  font-bold text-sm cursor-pointer transition duration-150 ease-in-out"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? (
                  <div className="font-Helvetica  flex items-center space-x-2">
                    <p className="font-Helvetica">Show less</p>
                    <MdOutlineKeyboardArrowUp size={20} />
                  </div>
                ) : (
                  <div className="font-Helvetica  flex items-center space-x-2">
                    <p className="font-Helvetica">Show more</p>
                    <MdOutlineKeyboardArrowDown size={20} />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        data-te-modal-init
        className="font-Helvetica  fixed top-0 left-0 right-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none container mx-auto p-5"
        id="rightTopModal"
        tabIndex="-1"
        aria-labelledby="rightTopModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="font-Helvetica  pointer-events-none absolute right-0 left-0 h-auto w-full translate-x-[100%] opacity-0 transition-all duration-300 ease-in-out"
        >
          <div className="font-Helvetica  min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="font-Helvetica  flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <h5
                className="font-Helvetica  text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalLabel"
              >
                {title}
              </h5>
              <button
                type="button"
                className="font-Helvetica  box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="font-Helvetica  h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              className="font-Helvetica  relative flex-auto p-4"
              data-te-modal-body-ref
            >
              {blog}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// modal

export default CareerAdvice;
