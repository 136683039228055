import React, { useEffect, useState } from "react";
import { VscAccount, VscBriefcase, VscHeart, VscKey } from "react-icons/vsc";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  MdMailOutline,
  MdOutlineFileUpload,
  MdOutlineLogout,
  MdOutlinePhone,
  MdDownload,
  MdPhotoCamera,
  MdUpload,
} from "react-icons/md";
import { Outlet, useNavigate } from "react-router-dom";
import { HiOutlineLink } from "react-icons/hi2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { profilePercentage } from "../config/api";
import { getCandidateSocialDetails } from "../config/api";

function ProfileLayout() {
  let navigate = useNavigate();
  const btnLogout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };

  const profile = JSON.parse(localStorage.getItem("profileData"));
  //const percentage = 33;

  //const value = 0.33;
  const [percentage, setPercentage] = useState();

  const [value, setValue] = useState();

  <CircularProgressbar value={percentage} text={`${percentage}%`} />;

  const [file, setFile] = useState(null);
  const [fileResume, setFileResume] = useState(null);

  useEffect(() => {
    ProfilePercentage(localStorage.getItem("userId"));
  });

  const ProfilePercentage = async (CandidateId) => {
    await profilePercentage(CandidateId).then((res) => {
      console.log("profile-->", res);

      // setPercentage(res.data[0].percentage);
      //   setValue(percentage / 100);
    });
  };

  const handleFileChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFileResume(uploadedFile);
  };

  // const handleDownload = () => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(fileResume);
  //   downloadLink.download = fileResume.name;
  //   downloadLink.click();
  // };

  const data = JSON.parse(localStorage.getItem("userState"));
  console.log("profile layout----->", data);

  const [socialLinkData, setSocialLinkData] = useState([]);

  useEffect(() => {
    GetSocialLink(localStorage.getItem("userId"));
  }, []);

  const GetSocialLink = async (id) => {
    await getCandidateSocialDetails(id).then((res) => {
      console.log("get social ---->", res.data);
      setSocialLinkData(res.data[0].socialLink);
    });
  };

  return (
    <>
      <Header />

      <div className="font-Helvetica  pt-20 relative">
        <div className="font-Helvetica  lg:block md:block hidden w-full">
          <div className="font-Helvetica  bg-[#000322] pt-10 pb-14">
            <div className="font-Helvetica  container mx-auto lg:px-28 md:px-0 w-full">
              <div className="font-Helvetica  flex justify-between items-center lg:p-10 md:py-10">
                <div className="font-Helvetica  flex space-x-10 items-start">
                  <div className="font-Helvetica  relative">
                    <div className="font-Helvetica  border rounded border-white w-40 h-40 p-1">
                      {file && (
                        <div id="selected-image">
                          <img
                            src={file}
                            alt="SelectedImage"
                            className="w-[160px] h-[150px] rounded"
                          />
                        </div>
                      )}
                    </div>
                    <label
                      className="font-Helvetica  absolute -bottom-3 -right-3 rounded-full bg-white p-1 border border-legavacYellow"
                      // type="file"
                      htmlFor="file_icon"
                    >
                      <MdPhotoCamera
                        size={25}
                        className="font-Helvetica  text-legavacBlue"
                      />
                      <input
                        type="file"
                        id="file_icon"
                        onChange={handleFileChange}
                        className="text-sm hidden"
                      />
                    </label>
                  </div>
                  <div className="font-Helvetica  space-y-3">
                    <div>
                      <p className="font-Helvetica  text-white text-2xl font-semibold">
                        {/* {Object.values(profile.firstName)}{" "}
                        {Object.values(profile.lastName)} */}
                        Dipak Patil
                      </p>
                      {/* <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdOutlineManageAccounts className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium">
                          Advocate
                        </p>
                      </div> */}
                    </div>
                    <div>
                      {/* <div className="font-Helvetica  flex space-x-2  items-center">
                        <VscBriefcase className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium ">
                          0 yrs Experience
                        </p>
                      </div> */}
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdOutlinePhone className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium ">
                          {/* {Object.values(profile.mobileNumber)} */}{" "}
                          9090909090
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2  items-center">
                        <MdMailOutline className="font-Helvetica  text-white " />
                        <p className="font-Helvetica  text-white text-sm font-medium">
                          {/* {Object.values(profile.email)} */}diapk@gmail.com
                        </p>
                      </div>
                      <div className="font-Helvetica  flex space-x-2 items-center">
                        <HiOutlineLink className="font-Helvetica  text-white " />
                        <a className="font-Helvetica  text-white text-sm font-medium cursor-pointer">
                          {/* {socialLinkData} */}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="font-Helvetica  flex space-x-10 items-center">
                  <CircularProgressbar
                    className="font-Helvetica  CircularProgressbar"
                    value={value}
                    maxValue={1}
                    text={`${60}%`}
                    // text={`${value * 100}%`}
                  />
                  <div className="font-Helvetica ">
                    <p className="font-Helvetica  text-lg text-white font-medium text-center">
                      Profile is completed in 60%
                      {/* {`${value * 100}%`} */}
                    </p>

                    <div className="font-Helvetica   grid place-items-center pt-3">
                      <label
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        className="font-Helvetica text-center flex space-x-2 items-center text-sm cursor-pointer bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-medium rounded py-2 px-5 transition duration-150 ease-in-out"
                        // htmlFor="file_input"
                      >
                        <MdUpload size={20} />{" "}
                        <p className="font-Helvetica">Upload Resume</p>
                      </label>
                      <input
                        className="w-full hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        id="file_input"
                        type="file"
                        onChange={handleFileUpload}
                      />
                      {fileResume && (
                        <div className="py-5 space-y-2 grid place-items-center">
                          <p className="text-white text-sm cursor-pointer">
                            {fileResume.name}
                          </p>
                          {/* <button
                        className="font-Helvetica text-sm flex space-x-2 justify-center text-white font-medium bg-green-600 px-5 py-1 rounded"
                        onClick={handleDownload}
                        disabled={!fileResume}
                      >
                        <p>Download</p><MdDownload size={20}/>
                      </button> */}
                        </div>
                      )}
                      {/* <input className="font-Helvetica  text-lg text-white font-semibold text-center" type="file" onChange={handleFileUpload} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="font-Helvetica relative lg:container lg:mx-auto lg:px-20 md:px-10 lg:pt-12 md:pt-12 pt-5 w-full">
          <div className="font-Helvetica  grid lg:grid-cols-4 md:grid-cols-1 grid-cols-1 lg:gap-7 md:gap-y-7 gap-y-7 items-start w-full">
            <div className="font-Helvetica md:grid hidden lg:grid place-items-center bg-white rounded shadow-lg shadow-slate-300 border border-slate-100">
              <ul className="font-Helvetica flex flex-col divide-y w-full flex-wrap list-none border-b-0 pl-0">
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="/profile/my-profile"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm active 
                        border-l-[3px] border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100
                     hover:bg-gray-100 focus:border-transparent transition-all duration-200 hover:transition-all hover:duration-700 hover:delay-25
                      "
                  >
                    <VscAccount size={20} />
                    <p className="font-Helvetica"> My Profile</p>
                  </a>
                </li>
                <li
                  className="font-Helvetica  flex-grow text-left"
                  role="presentation"
                >
                  <a
                    href="/profile/my-resume"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                  >
                    <IoDocumentTextOutline size={20} />
                    <p className="font-Helvetica">My Resume</p>
                  </a>
                </li>
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="/profile/saved-jobs"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                  >
                    <VscHeart size={20} />
                    <p className="font-Helvetica">Saved Jobs</p>
                  </a>
                </li>
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="/profile/applied-jobs"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                  >
                    <VscBriefcase size={20} />
                    <p className="font-Helvetica">Applied Jobs</p>
                  </a>
                </li>
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="/profile/create-job-alert"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                  >
                    <IoMdNotificationsOutline size={25} />
                    <p className="font-Helvetica">Create Job Alert</p>
                  </a>
                </li>
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="/profile/change-password"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                  >
                    <VscKey size={20} />
                    <p className="font-Helvetica">Change Password</p>
                  </a>
                </li>
                <li className="font-Helvetica  flex-grow text-left">
                  <a
                    href="#tabs-logoutVertical"
                    className="font-Helvetica  flex space-x-2 items-center p-3 nav-link font-medium text-sm border-l-[3px] 
                  border-transparent hover:border-blue-500 focus:border-blue-500 focus:bg-gray-100 hover:bg-gray-100"
                    onClick={btnLogout}
                  >
                    <MdOutlineLogout size={20} />
                    <p className="font-Helvetica">Logout</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="font-Helvetica  col-span-3 bg-white lg:rounded lg:shadow-lg lg:shadow-slate-300 lg:border lg:border-slate-100 p-7">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProfileLayout;
