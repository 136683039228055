import React, { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { MdDelete, MdEdit } from "react-icons/md";
import {
  deleteRecord,
  getCandidateInterest,
  saveCandidateInterest,
} from "../../../../config/api";
const Interest = () => {
  const [InterestData, setInterestData] = useState([]);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  const [state, setState] = useState({
    id: "0",
    candidateId: localStorage.getItem("userId"),
    interest: "",
    createdBy: localStorage.getItem("userId"),
  });

  const funShow = () => {
    setShow(true);
  };

  useEffect(() => {
    //getCandidateAward
    GetCandidateInterest(localStorage.getItem("userId"));
  }, [InterestData.id]);

  const GetCandidateInterest = async (id) => {
    await getCandidateInterest(id).then((res) => {
      console.log("get getCandidateInterest---.", res);
      if (res.data.length === 0) {
        setShow(true);
        setHide(hide);
        setInterestData(res.data);
      } else {
        setHide(true);
        setInterestData(res.data);
      }
    });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log("state-->", state);
  };

  const handleSubmit = async () => {
    await saveCandidateInterest(state)
      .then((res) => {
        console.log("res", res);
        GetCandidateInterest(localStorage.getItem("userId"));
        setShow(false);
        setState({
          id: "0",
          candidateId: state.candidateId,
          interest: "",
          createdBy: state.createdBy,
        });
      })
      .catch((error) => {
        alert("submit" + error);
      });
  };
  const handleDelete = async (id) => {
    await deleteRecord({
      tableName: "AwardsAndAchievement",
      id: id,
    }).then((res) => {
      console.log("res delete education---->", res);
      if (res.data[0].result === "Y") {
        alert("record deleted");
        GetCandidateInterest(localStorage.getItem("userId"));
      }
    });
  };
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <div className="font-Helvetica  border-b pb-5">
          <div className="font-Helvetica  flex justify-between">
            <p className="font-Helvetica lg:text-xl md:text-xl text-base font-semibold tracking-wide uppercase">
              Interest
            </p>
            <div className="font-Helvetica  flex space-x-2 items-center font-medium">
              <BsPlusCircle
                size={20}
                className="font-Helvetica  text-blue-500"
                onClick={funShow}
              />
              <p
                className="font-Helvetica lg:block md:block hidden text-blue-500 text-sm cursor-pointer"
                onClick={funShow}
              >
                Add More
              </p>
            </div>
          </div>
        </div>
        {show ? (
          <div className="font-Helvetica  space-y-5">
            <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-5 ">
              <div className="font-Helvetica  space-y-2">
                <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
                  Interest:
                </p>
                <input
                  type="text"
                  name="interest"
                  id="interest"
                  onChange={handleChange}
                  value={state.interest}
                  className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
                />{" "}
              </div>
            </div>

            <div className="font-Helvetica  flex justify-between">
              <button
                type="submit"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="font-Helvetica  bg-legavacBlue py-2 px-5 rounded text-sm text-white uppercase"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {hide ? (
          <ol className="font-Helvetica  border-l-2 border-info-100">
            {InterestData &&
              InterestData?.map((a, idx) => (
                <li key={idx}>
                  <div className="font-Helvetica  flex-start md:flex">
                    <div className="font-Helvetica  -ml-[13px] flex h-[25px] w-[25px] items-center justify-center rounded-full bg-info-100 text-info-700">
                      <HiOutlineAcademicCap />
                    </div>
                    <div className="font-Helvetica  ml-6 mb-5 flex justify-between items-start w-full rounded-lg bg-slate-50 p-5 shadow-md shadow-black/5 dark:bg-neutral-700 dark:shadow-black/10">
                      <div>
                        <div className="font-Helvetica  flex justify-between">
                          <p className="font-Helvetica  transition font-semibold duration-150 ease-in-out text-legavacBlue">
                            {a.interest}
                          </p>
                        </div>
                       
                      </div>
                      <div className="font-Helvetica  flex space-x-2">
                        <MdEdit
                          size={20}
                          className="font-Helvetica  text-green-600 cursor-pointer"
                          onClick={() => {
                            setShow(true);
                            const editData = InterestData.filter(
                              (data) => data.id === a.id
                            );
                            console.log(editData[0]);
                            setState(editData[0]);
                          }}
                        />
                        <MdDelete
                          size={20}
                          className="font-Helvetica  text-red-600 cursor-pointer"
                          onClick={() => {
                            const DeleteData = InterestData.filter(
                              (data) => data.id === a.id
                            );
                            handleDelete(DeleteData[0].id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ol>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Interest;
