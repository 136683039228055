import React from "react";
import { MdAdd } from "react-icons/md";

const PostProject = () => {
  const category = [
    { text: "CAE engineer" },
    { text: "Design engineer" },
    { text: "CFD simulation engineer" },
    { text: "BIW product design engineer" },
    { text: "BIW fixture design engineer" },
    { text: "Verification and validation engineer" },
    { text: "Lighting engineer ( Exterior trims)" },
    { text: "System Engineer" },
  ];
  return (
    <>
      <div className="font-Helvetica  container mx-auto w-full lg:px-20 md:px-10 px-0 pt-28 pb-20">
        <p className="text-2xl text-legavacBlue font-semibold border-b-2 border-legavacBlue w-fit">
          Post A Project
        </p>

        <div className="py-10 space-y-5">
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Project Name:
            </p>
            <div>
              <input
                type="text"
                name="percentage"
                id="percentage"
                placeholder="Enter Project Name"
                className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Category:
            </p>
            <select
              name="institute"
              id="institute"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
            >
              <option>-- Select --</option>
              {category?.map((i, idx) => (
                <option key={idx} className="font-Helvetica  text-sm">
                  {i.text}
                </option>
              ))}
            </select>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Pricing Type:
            </p>
            <select
              name="institute"
              id="institute"
              className="font-Helvetica  placeholder:text-sm text-sm border border-slate-200 drop-shadow rounded p-2.5 w-full focus:outline-none hover:outline-none"
            >
              <option>-- Select --</option>
              <option className="font-Helvetica  text-sm">
                Fixed Budget Price
              </option>
              <option className="font-Helvetica  text-sm">
                Hourly Pricing
              </option>
              <option className="font-Helvetica  text-sm">Biding Price</option>
            </select>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Desired areas of expertise:
            </p>
            <div>
              <input
                type="text"
                name="percentage"
                id="percentage"
                className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Period of Project:
            </p>
            <div className="pl-5">
              <div className="font-Helvetica  ">
                <input
                  className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04] "
                  type="radio"
                  name="projectStatus"
                  id="radioDefault01"
                />
                <label
                  className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                  htmlFor="radioDefault01"
                >
                  Start immediately after the candidate is selected
                </label>
              </div>
              <div className="font-Helvetica  ">
                <input
                  className="font-Helvetica  relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 
                    border-solid border-[rgba(0,0,0,0.25)] bg-white before:pointer-events-none before:absolute before:h-4
                     before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0
                      before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] 
                      after:block after:h-4 after:w-4 after:rounded-full after:bg-white after:content-['']
                       checked:border-legavacBlue checked:bg-white checked:before:opacity-[0.16] checked:after:absolute 
                       checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem]
                        checked:after:rounded-full checked:after:border-legavacBlue checked:after:bg-legavacBlue
                         checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] 
                         hover:cursor-pointer hover:before:opacity-[0.04]"
                  type="radio"
                  name="projectStatus"
                  id="radioDefault02"
                />
                <label
                  className="font-Helvetica  mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-sm"
                  htmlFor="radioDefault02"
                >
                  Job will Start On
                </label>
              </div>
            </div>
            <div>
              <input
                type="date"
                placeholder="Enter Project Name"
                className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Add Documents:
            </p>
            <div>
              <input
                type="file"
                placeholder="Enter Project Name"
                className="font-Helvetica  text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
            </div>
          </div>
          <div className="font-Helvetica space-y-2">
            <p className="font-Helvetica  text-sm text-slate-700 font-semibold tracking-wide">
              Add Links:
            </p>
            <div className="grid grid-cols-12 gap-5">
              <input
                type="text"
                className="col-span-11 font-Helvetica text-sm border border-slate-200 drop-shadow rounded p-2 w-full focus:outline-none hover:outline-none"
              />
              <button className="bg-legavacYellow w-full rounded flex justify-center items-center">
                <MdAdd size={30} className="text-white" />
              </button>
            </div>
          </div>
          <div className="font-Helvetica space-y-2">
            <button className="bg-legavacBlue text-white py-2 px-5 rounded flex justify-center items-center">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostProject;
