import React, { useState } from "react";
import {
  MdClose,
  MdOutlineHomeWork,
  MdOutlineLocationOn,
  MdOutlineWatchLater,
} from "react-icons/md";
import { VscBriefcase, VscLinkExternal } from "react-icons/vsc";
import { BiRupee } from "react-icons/bi";
import { SiMinds } from "react-icons/si";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import ViewFullPage from "../../main/ViewFullPage";

function AdvanceJobSection() {
  const jobs = [
    {
      jobPosition: "CAE engineer",
      companyName: "Wipro",
      jobType: "Permanent",
      workMode: "Work From Office",
      city: "Pune",
      country: "India",
      experience: "3 - 4 years",
      salary: "3,00,000 - 5,00,000 LPA",
      skills: "React",
      date: "3 days ago",
    },
    {
      jobPosition: "Tata Consultancy Service",
      companyName: "Design engineer",
      jobType: "Permanent",
      workMode: "Work From Office",
      city: "Pune",
      country: "India",
      experience: "0 - 2 years",
      salary: "1,00,000 - 5,00,000 LPA",
      skills: "Photoshop",
      date: "5 days ago",
    },
    {
      jobPosition: "Tech Mahindra",
      companyName: "CFD simulation engineer",
      jobType: "Permanent",
      workMode: "Work From Office",
      city: "Pune",
      country: "India",
      experience: "3 - 4 years",
      salary: "3,00,000 - 5,00,000 LPA",
      skills: "React",
      date: "3 days ago",
    },
    {
      jobPosition: "Accenture",
      companyName: "Biw product design engineer ",
      jobType: "Permanent",
      workMode: "Work From Office",
      city: "Pune",
      country: "India",
      experience: "0 - 2 years",
      salary: "1,00,000 - 5,00,000 LPA",
      skills: "nodeJs",
      date: "5 days ago",
    },
  ];
  return (
    <>
      <div className="font-Helvetica  space-y-4">
        {jobs?.map((j, idx) => (
          <div
            className="font-Helvetica  bg-white border border-gray-200 hover:border-legavacBlue rounded-md shadow shadow-gray-200 p-4 space-y-2"
            key={idx}
          >
            <JobSectionBlog {...j} />
          </div>
        ))}
      </div>
    </>
  );
}

function JobSectionBlog({
  jobPosition,
  companyName,
  jobType,
  workMode,
  city,
  country,
  experience,
  salary,
  skills,
  date,
}) {
  const [isLiked, setIsLiked] = useState(false);

  const handleClick = () => {
    setIsLiked(!isLiked);
  };
  return (
    <>
      <div>
        <div className="font-Helvetica  flex justify-between items-center">
          <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
            {jobPosition}
          </p>
          <div className="text-red-500" onClick={handleClick}>
            {isLiked ? (
              <IoMdHeart className="w-6 h-6 fill-current" />
            ) : (
              <IoMdHeartEmpty className="w-6 h-6 fill-current" />
            )}
          </div>
        </div>
        <div className="font-Helvetica  lg:flex md:flex block justify-between items-center lg:space-y-0 md:space-y-0 space-y-2">
          <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
            {companyName}
          </p>
          <div className="font-Helvetica  flex space-x-2">
            <VscLinkExternal
              size={15}
              className="font-Helvetica  text-slate-500"
            />
            <p
              className="font-Helvetica  text-slate-500 text-xs cursor-pointer hover:text-legavacBlue"
              data-te-offcanvas-toggle
              data-te-target="#offcanvasBottom2"
              aria-controls="offcanvasBottom2"
            >
              View Full Page
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="font-Helvetica  space-y-1">
        <div className="font-Helvetica  flex space-x-2 items-center">
          <MdOutlineHomeWork
            size={20}
            className="font-Helvetica  text-slate-500"
          />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">{jobType},</p>
            <p className="font-Helvetica  text-slate-500 text-xs">{workMode}</p>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-center">
          <MdOutlineLocationOn
            size={20}
            className="font-Helvetica  text-slate-500"
          />
          <div className="font-Helvetica  flex space-x-1">
            <p className="font-Helvetica  text-slate-500 text-xs">{city},</p>
            <p className="font-Helvetica  text-slate-500 text-xs">{country}</p>
          </div>
        </div>
        <div className="font-Helvetica  grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1">
          <div className="font-Helvetica  flex space-x-2 items-center">
            <VscBriefcase
              size={20}
              className="font-Helvetica  text-slate-500"
            />
            <div className="font-Helvetica  flex space-x-1">
              <p className="font-Helvetica  text-slate-500 text-xs">
                {experience}
              </p>
            </div>
          </div>
          <div className="font-Helvetica  flex space-x-2 items-center">
            <BiRupee size={20} className="font-Helvetica  text-slate-500" />
            <div className="font-Helvetica  flex space-x-1">
              <p className="font-Helvetica  text-slate-500 text-xs">{salary}</p>
            </div>
          </div>
        </div>
        <div className="font-Helvetica  flex space-x-2 items-start">
          <SiMinds size={20} className="font-Helvetica  text-slate-500" />
          <div className="font-Helvetica  grid lg:grid-cols-5 md:grid-cols-5 grid-cols-3 gap-2">
            <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
              {skills}
            </p>
            <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
              {skills}
            </p>
            <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
              {skills}
            </p>
            <p className="font-Helvetica  text-slate-500 text-xs bg-slate-100 rounded-full px-3 p-1">
              {skills}
            </p>
          </div>
        </div>
      </div>
      <div className="font-Helvetica  flex justify-between">
        <div className="font-Helvetica  flex space-x-2 items-center pt-2">
          <MdOutlineWatchLater
            size={20}
            className="font-Helvetica  text-slate-500"
          />
          <p className="font-Helvetica  text-slate-500 text-xs">{date}</p>
        </div>

        <button
          type="button"
          data-te-ripple-init
          data-te-ripple-color="light"
          className="font-Helvetica  bg-legavacBlue text-white text-sm uppercase rounded px-5 py-1 transition duration-150 ease-in-out"
        >
          Apply
        </button>
      </div>

      <div
        className="font-Helvetica  fixed bottom-0 flex flex-col max-w-full lg:bg-gray-50
         md:bg-gray-50 bg-white invisible bg-clip-padding shadow-sm outline-none transition
          duration-300 ease-in-out text-gray-700 left-0 right-0 border-none h-full max-h-full z-[1045] 
          translate-y-full [&[data-te-offcanvas-show]]:transform-none"
        tabIndex="-1"
        id="offcanvasBottom2"
        aria-labelledby="offcanvasBottomLabel"
        data-te-offcanvas-init
      >
        <div className="font-Helvetica  offcanvas-header flex items-center justify-end p-4">
          <button
            type="button"
            className="font-Helvetica  btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-te-offcanvas-dismiss
          >
            <MdClose />
          </button>
        </div>
        <div className="font-Helvetica  offcanvas-body flex-grow lg:p-4 overflow-y-auto small">
          <ViewFullPage />
        </div>
      </div>
    </>
  );
}

export default AdvanceJobSection;
