import React from "react";
import AwardsArchvmnt from "./myResume/AwardsArchvmnt";
import CourseCrtfct from "./myResume/CourseCrtfct";
import EducationDetails from "./myResume/EducationDetails";
import Projects from "./myResume/Projects";
import Skills from "./myResume/Skills";
import SocialLinks from "./myResume/SocialLinks";
import WorkExp from "./myResume/WorkExp";
import Interest from "./myResume/Interest";

function MyResume() {
  return (
    <>
      <div className="font-Helvetica  space-y-10">
        <EducationDetails />
        <Skills />
        <WorkExp/>
        <Projects/>
        <SocialLinks/>
        <Interest />
        <CourseCrtfct/>
        <AwardsArchvmnt/>
      </div>
    </>
  );
}

export default MyResume;
