import React from "react";
import {
  MdMailOutline,
  MdOutlineLocationOn,
  MdOutlineMail,
  MdOutlinePhone,
} from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { IMAGES } from "../stores";
import { Link, useNavigate } from "react-router-dom";
import Signup from "../pages/auth/Signup";

function Footer() {
  const navigate = useNavigate();

  // const findJobs = () => {
  //   navigate("/app/jobs");
  // };
  return (
    <>
      <div className="font-Helvetica  mt-40">
        <div className="font-Helvetica  relative bg-[#000322] w-full ">
          <div className="font-Helvetica  absolute -top-20 w-full container mx-auto left-0 right-0 lg:px-20 md:px-10 px-0">
            <div className="font-Helvetica  relative bg-white shadow-2xl shadow-black rounded-lg p-10 lg:pb-10 md:pb-[300px] pb-[300px]">
              <div className="font-Helvetica  space-y-5 lg:w-3/4 text-justify">
                <div className="font-Helvetica   font-semibold text-xl md:text-3xl">
                  Looking for a career change? Browse our job listings now!
                </div>
                <div className="font-Helvetica  opacity-60 ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </div>
                {/* <div className="font-Helvetica  flex justify-center lg:justify-start w-full">
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={findJobs}
                    className="font-Helvetica  bg-legavacYellow text-white font-semibold uppercase text-xs py-2 px-5 rounded transition duration-150 ease-in-out"
                  >
                    Apply Now
                  </button>
                </div> */}
              </div>
              <div className="font-Helvetica  absolute lg:-top-16 md:bottom-0 bottom-0 lg:-right-10 md:right-48 right-4">
                <img
                  src={IMAGES.FooterMan}
                  alt=""
                  className="font-Helvetica  lg:w-auto lg:h-[300px] md:w-auto md:h-[300px] h-[300px]"
                />
              </div>
            </div>
          </div>
          <div className="font-Helvetica  lg:px-20 md:px-20 px-0 pb-10 lg:pt-60 md:pt-[550px] pt-[650px] mx-auto container">
            <div className="font-Helvetica  grid grid-cols-1 lg:grid-cols-5 md:grid-cols-1 gap-5 pb-10 ">
              <div className="font-Helvetica  grid lg:place-items-start md:place-items-center place-items-center">
                <div className="font-Helvetica  space-y-10">
                  <Link to="/">
                    <img
                      src={IMAGES.itonelogo}
                      alt=""
                      className="font-Helvetica  w-32 cursor-pointer"
                    />
                  </Link>

                  <div className="font-Helvetica  flex space-x-3 lg:justify-start md:justify-center justify-center">
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaFacebookF
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaLinkedinIn
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaInstagram
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="font-Helvetica  lg:flex lg:justify-center">
                <div className="font-Helvetica  space-y-5">
                  <div className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                    Quick Links
                    <div className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-28 transition-all duration-500 pt-1"></div>
                  </div>
                  <ul className="font-Helvetica  space-y-2">
                    <li className="font-Helvetica   text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/"> Home</a>
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/about-us"> About Us</a>
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/contact-us"> Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="font-Helvetica  grid lg:place-items-center text-white space-y-5">
                <div className="font-Helvetica  font-QuickStand text-white lg:text-center text-2xl font-medium">
                  Get In Touch
                  <div className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-24 transition-all duration-500 pt-1"></div>
                </div>
                <ul className="font-Helvetica  space-y-2">
                  <li className="font-Helvetica  flex space-x-2 opacity-80">
                    <div className="font-Helvetica  ">
                      <MdOutlineLocationOn
                        color="white"
                        size={25}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div>
                      <div className="font-Helvetica  text-sm">
                        <span className="font-Helvetica  text-md font-medium tracking-[1px]">
                          Head Office -
                        </span>{" "}
                        Kundan Bhavan, Kundan Nagar, Mumbai-Pune Road, Dapodi,
                        Pune 411012 Maharashtra (IN)
                      </div>
                    </div>
                  </li>

                  <li className="font-Helvetica  flex space-x-2 opacity-80 hover:opacity-100 items-center">
                    <div>
                      <MdOutlinePhone color="white" size={25} />
                    </div>
                    <div>
                      <div className="font-Helvetica  text-sm ">
                        +91-7020338633
                      </div>
                    </div>
                  </li>
                  <li className="font-Helvetica  flex space-x-2 opacity-80 hover:opacity-100 items-center">
                    <div>
                      <MdMailOutline color="white" size={25} />
                    </div>
                    <div>
                      <div className="font-Helvetica  text-sm cursor-pointer">
                        info@itoneinfotech.com
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="font-Helvetica  lg:flex lg:justify-center">
                <div className="font-Helvetica  space-y-5">
                  <div className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                    Other Links
                    <div className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-28 transition-all duration-500 pt-1"></div>
                  </div>
                  <ul className="font-Helvetica  space-y-2">
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      Privacy & Policy
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      Terms of Services
                    </li>
                  </ul>
                </div>
              </div>
              <div className="font-Helvetica  text-white space-y-5">
                <div className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                  Newsletter
                  <div className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-32 transition-all duration-500 pt-1"></div>
                </div>
                <div className="font-Helvetica  space-y-2">
                  <div className="font-Helvetica  text-sm opacity-80">
                    Get exclusive deals by signing up to our Newsletter.
                  </div>
                  <input
                    type="text"
                    placeholder="Email"
                    className="font-Helvetica  w-full p-2 rounded-sm placeholder:text-sm text-sm text-black focus:outline-none hover:outline-none border border-transparent focus:border-legavacYellow hover:border-legavacYellow"
                  />
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="font-Helvetica  flex justify-center cursor-pointer items-center space-x-2 bg-legavacYellow w-full p-2 rounded-sm text-sm text-white font-semibold transition duration-150 ease-in-out"
                    onClick={() => navigate("/signup")}
                  >
                    <MdOutlineMail />
                    <div>Sign Up</div>
                  </button>
                </div>
              </div>
            </div>

            <hr className="font-Helvetica  opacity-30" />

            <div className="font-Helvetica  md:flex md:justify-between grid place-items-center lg:space-y-0 md:space-y-0 space-y-5 pt-10">
              <div className="font-Helvetica   text-white text-sm opacity-80 md:text-left text-center">
                © {new Date().getFullYear()}{" "}
                <span className="font-Helvetica  underline cursor-pointer text-white text-md px-2">
                  ITOne Infotech{" "}
                </span>{" "}
                All rights reserved.{" "}
              </div>
              <div className="font-Helvetica  text-sm text-white opacity-80 hover:opacity-100 cursor-pointer">
                Cookie Policy
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
