import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../../stores";
import VerificationEmail from "./VerificationEmail";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { getOtp, savecandidate } from "../../config/api";

function Signup() {
  const [loader, setLoader] = useState(false);
  // const verifiedEmail = props.verify;
  var TxtRotate = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = "";
    this.tick();
    this.isDeleting = false;
  };

  TxtRotate.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML =
      '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) {
      delta /= 2;
    }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }

    setTimeout(function () {
      that.tick();
    }, delta);
  };

  window.onload = function () {
    var elements = document.getElementsByClassName("txt-rotate");
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute("data-rotate");
      var period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const signUpDetails = {
    //Id: "1",
    Role: "candidate",
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    MobileNumber: "",
    WhatsppAlert: "yes",
    CreatedBy: "0",
    errors: [],
  };

  const [state, setState] = useState(signUpDetails);

  const handleChange = (e) => {
    console.log("handle chnge->", e);

    setState({ ...state, [e.target.name]: e.target.value });
    // if (e.target.name === "check") {
    //   setIsChecked(!isChecked);
    // }
    if (e.target.name === "WhatsppAlert") {
      setState({ ...state, [e.target.name]: [e.target.checked] });
      setState({ ...state, [e.target.name]: e.target.value });
    }

    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      };
    });
  };

  const validateCandidate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    // const regex_email = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const MobileNumbernumber = /^[7-9][0-9]/;

    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First name is required";
    } else if (state.FirstName.length < 2) {
      IsValid = false;
      errors["FirstName"] = "Must be 15 characters ";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "please enter valid name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state.LastName) {
      IsValid = false;
      errors["LastName"] = "Last name is required";
    } else if (state.LastName.length < 2) {
      IsValid = false;
      errors["LastName"] = "please enter valid name";
    } else {
      errors["LastName"] = "";
    }

    if (!state.MobileNumber) {
      IsValid = false;
      errors["MobileNumber"] = "MobileNumber number is required";
    } else if (
      state.MobileNumber.length < 10 ||
      state.MobileNumber.length > 10
    ) {
      IsValid = false;
      errors["MobileNumber"] = "MobileNumber number must have 10 digits";
    } else if (!state.MobileNumber.match(MobileNumbernumber)) {
      IsValid = false;
      errors["MobileNumber"] = "please enter valid MobileNumber number";
    } else {
      errors["MobileNumber"] = "";
    }

    if (!state.Email) {
      IsValid = false;
      errors["Email"] = "Email is required";
    } else if (!state.Email.match(regex_email)) {
      IsValid = false;
      errors["Email"] = "please enter valid Email id";
    } else {
      errors["Email"] = "";
    }

    if (!state.Password) {
      IsValid = false;
      // errors["Password"] = "Password contain minimum 8 chars & at least 1 upper,lower(case) letter,1 digit,1 special char";
      errors["Password"] = "Password contain minimum 8 character";
    } else if (!state.Password.match(uppercaseRegExp)) {
      IsValid = false;
      errors["Password"] = "At least one Uppercase";
    } else if (!state.Password.match(lowercaseRegExp)) {
      IsValid = false;
      errors["Password"] = "At least one Lowercase";
    } else if (!state.Password.match(digitsRegExp)) {
      IsValid = false;
      errors["Password"] = "At least one digit";
    } else if (!state.Password.match(specialCharRegExp)) {
      IsValid = false;
      errors["Password"] = "At least one Special Characters";
    } else if (!state.Password.match(minLengthRegExp)) {
      IsValid = false;
      errors["Password"] = "At least minumum 8 characters";
    } else {
      errors["Password"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };
  // const clearState = () => {
  //   setState({
  //     FirstName: "",
  //     LastName: "",
  //     Email: "",
  //     Password: "",
  //     MobileNumber: "",
  //   });
  // };
  const closedModal = (e) => {
    console.log("test", showModal);
    setShowModal(false);
  };

  const handleCandidateSubmit = async () => {
    setTimeout(() => {
      setLoader(true);
    }, 5000);

    localStorage.setItem("role", JSON.stringify(signUpDetails.Role));

    console.log("handleCandidateSubmit--->", state);
    if (validateCandidate()) {
      //api otp  api success api of otp (check for Email is already exist or not if existe thenn error else sent otp)
      //handleSuccess();

      await savecandidate(state).then((res) => {
        console.log("savecandidate", res);
        if (res.data[0].id === "E") {
          alert("this email is already exist");
        } else if (res.data.length > 0) {
          localStorage.setItem("userId", res.data[0].id);
          localStorage.setItem("userState", JSON.stringify(state));
          sessionStorage.setItem("userId", res.data[0].id);
          getOtp(state.Email).then((res) => {
            console.log("get otp--->", res);
            handleSuccess();
          });
        }
      });
      setLoader(false);
      // clearState();
    } else {
      setLoader(false);
      alert("Please fill all the fields");
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleSuccess = () => {
    setShowModal(true);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="font-Helvetica lg:flex space-x-6 overflow-y-auto">
        <div className="lg:sticky lg:w-3/5">
          <div className="font-Helvetica lg:fixed lg:w-3/5 lg:z-10 lg:top-0 lg:left-0 lg:right-0 bg-center bg-cover relative after:bg-black after:absolute after:inset-0 after:opacity-60">
            <img
              src={IMAGES.Login}
              alt=""
              className="font-Helvetica  w-full md:min-h-screen"
            />
            <img
              src={IMAGES.itonelogo}
              alt=""
              className="font-Helvetica  w-36 z-10 absolute top-10 left-10 shadow-md shadow-white p-3"
            />

            <div className="font-Helvetica  absolute z-10 lg:top-1/2 md:top-80 top-40 left-0 right-0 mx-auto space-y-5">
              <p className="font-Helvetica  font-QuickStand text-white md:text-5xl text-3xl  font-bold text-center">
                Welcome to
                <span
                  className="font-QuickStand  txt-rotate text-legavacYellow"
                  data-period="2000"
                  data-rotate='[ "ITOne !", "ITOne !"]'
                ></span>
              </p>
            </div>
          </div>
        </div>

        <div className="font-Helvetica lg:w-2/5 bg-white h-fit">
          {/* <div
            className="font-Helvetica  p-5 flex space-x-2 items-center cursor-pointer"
          >
            <div className="font-Helvetica  rounded-full border border-blue-800 p-1">
              <MdOutlineArrowBackIosNew color="blue" size="10" />
            </div>
            <div>
              <Link to="/">
                <p className="font-Helvetica  text-blue-800 text-xs">
                  Back to home
                </p>
              </Link>
            </div>
          </div> */}
          <div className="font-Helvetica  lg:px-10 md:px-10 px-5 py-5">
            <div className="font-Helvetica  pb-5">
              <p className="font-Helvetica pt-5 text-3xl font-semibold text-left uppercase underline underline-offset-8 decoration-legavacYellow">
                Register
              </p>
              <div>
                <div className="font-Helvetica  space-y-3 py-3">
                  <p className="font-Helvetica  text-start text-xs">
                    It only takes a couple of minutes to get started
                  </p>
                </div>
              </div>

              <div>
                <div className="font-Helvetica py-5 space-y-2">
                  <div className="font-Helvetica grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-2">
                    <div className="">
                      <button className="bg-transparent hover:bg-legavacYellow hover:text-white border rounded border-legavacYellow shadow-md shadow-gray-200 w-full p-5 text-gray-600 font-semibold transition-all duration-700 delay-50">
                        Company
                      </button>
                    </div>
                    <div className="">
                      <button className="bg-transparent hover:bg-legavacYellow hover:text-white border rounded border-legavacYellow shadow-md shadow-gray-200 w-full p-5 text-gray-600 font-semibold transition-all duration-700 delay-50">
                        Candidate
                      </button>
                    </div>
                    <div className="font-Helvetica  space-y-1">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        First Name
                      </p>
                      <input
                        type="text"
                        placeholder="Enter first name"
                        value={state.FirstName}
                        name="FirstName"
                        id="FirstName"
                        onChange={handleChange}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                      {state.errors ? (
                        <p className="font-Helvetica  text-red-500 text-xs pt-1">
                          {state.errors.FirstName}
                        </p>
                      ) : null}
                    </div>
                    <div className="font-Helvetica  space-y-1">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        Last Name
                      </p>
                      <input
                        type="text"
                        placeholder="Enter last name"
                        name="LastName"
                        id="LastName"
                        onChange={handleChange}
                        value={state.LastName}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                      {state.errors ? (
                        <p className="font-Helvetica  text-red-500 text-xs pt-1">
                          {state.errors.LastName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Email ID
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Email id"
                      value={state.Email}
                      name="Email"
                      id="Email"
                      onChange={handleChange}
                      className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                    />
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.Email}
                      </p>
                    ) : null}
                  </div>
                  <div className="font-Helvetica space-y-1">
                    <div className="relative">
                      <p className="font-Helvetica  text-slate-400 text-xs">
                        Password
                      </p>
                      <input
                        type={showPassword ? "text" : "Password"}
                        placeholder="Enter Password"
                        name="Password"
                        id="Password"
                        onChange={handleChange}
                        value={state.Password}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                      <button
                        className="absolute inset-y-0 right-0 top-3 px-3 py-2 text-gray-500 transition duration-300 ease-in-out"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VscEyeClosed size={15} />
                        ) : (
                          <VscEye size={15} />
                        )}
                      </button>
                    </div>
                    {/* validation tracker */}

                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.Password}
                      </p>
                    ) : null}
                  </div>
                  <div className="font-Helvetica space-y-1">
                    <p className="font-Helvetica  text-slate-400 text-xs">
                      Mobile Number
                    </p>
                    <div className="font-Helvetica  flex space-x-2">
                      <select className="font-Helvetica  border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs">
                        <option>+91</option>
                        <option>+92</option>
                        <option>+93</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Enter Mobile number"
                        name="MobileNumber"
                        id="MobileNumber"
                        maxLength={10}
                        onChange={handleChange}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={state.MobileNumber}
                        className="font-Helvetica w-full p-2 border border-transparent border-slate-400 focus:outline-none hover:outline-none focus:border-legavacYellow hover:border-legavacYellow rounded placeholder:text-xs text-xs"
                      />
                    </div>
                    {state.errors ? (
                      <p className="font-Helvetica  text-red-500 text-xs pt-1">
                        {state.errors.MobileNumber}
                      </p>
                    ) : null}
                  </div>

                  <div className="space-y-2">
                    {/* <div className="flex space-x-1 items-center">
                      <input
                        type="checkbox"
                        value={state.WhatsppAlert}
                        onChange={handleChange}
                        name="WhatsppAlert"
                        id="WhatsppAlert"
                        defaultChecked={true}
                        // onClick={handleCheckChange}
                      />
                      <p className="font-Helvetica flex items-center text-xs text-slate-400">
                        Send me updates 
                        <img src={IMAGES.Whatsapp} alt="" className="w-5" />{" "}
                        Whatsapp 
                      </p>
                    </div> */}
                    <p className="font-Helvetica  text-xs text-slate-400">
                      By clicking register, you agree to our{" "}
                      <span className="font-Helvetica ">
                        <a href="/" className="text-legavacBlue">
                          Terms and conditions
                        </a>{" "}
                        and{" "}
                        <a href="/" className="text-legavacBlue">
                          Privacy Policy
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                {/* {loader ? (
                      <Loader/>
                    ) : null} */}

                <div className="font-Helvetica  grid place-items-center">
                  <button
                    type="submit"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="font-Helvetica  bg-legavacBlue font-medium rounded text-white py-1 px-5 uppercase cursor-pointer"
                    onClick={handleCandidateSubmit}
                    style={{
                      cursor:
                        state.FirstName &&
                        state.LastName &&
                        state.Email &&
                        state.Password &&
                        state.MobileNumber
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>

              <p className="font-Helvetica  text-xs text-center pt-5 text-slate-400">
                Already have an account ?{" "}
                <a
                  href="/login"
                  className="font-Helvetica  text-legavacBlue hover:underline cursor-pointer"
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Toster toastList={list} setList={setList} /> */}
      {showModal && (
        <VerificationEmail
          isOpen={showModal}
          email={state.Email}
          onClose={() => closedModal()}
        />
      )}
    </>
  );
}

export default Signup;

// function SuccessModal(props) {

// }
