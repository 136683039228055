import React from "react";
import Advertisement from "../sub/advanceSearch/Advertisement";
import Highlights from "../sub/viewFullPage/Highlights";
import JobDesc from "../sub/viewFullPage/JobDesc";
import MoreInfo from "../sub/viewFullPage/MoreInfo";
import { useNavigate } from "react-router-dom";

function ViewFullPage() {
  // console.log("view full page", props);
  // const jobdetails = props.state;
  // console.log("view full page", jobdetails);
  const navigate = useNavigate();
  const handleApply = () => {
    alert("you have applied job successfully");
    navigate("/");
  };
  return (
    <>
      <div
        className="font-Helvetica  container mx-auto lg:px-10 md:px-10 px-0"
        
      >
        <div className="font-Helvetica  relative grid lg:grid-cols-4 gap-5">
          <div className="font-Helvetica  col-span-3 relative bg-white rounded-md lg:shadow-md md:shadow-md lg:shadow-slate-300 md:shadow-slate-300">
            <div className="font-Helvetica  sticky lg:-top-10 md:-top-10 -top-10 z-10 bg-white pt-5">
              <div className="font-Helvetica  lg:flex md:flex lg:justify-between md:justify-between lg:space-y-0 md:space-y-3 space-y-3 p-5">
                <div>
                  <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
                    {/* {job.jobTitle} */} CAE engineer
                  </p>
                  <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                    {/* {job.companyName} */} Wipro
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="font-Helvetica  bg-legavacBlue px-5 py-2 text-sm font-semibold text-white uppercase rounded transition duration-150 ease-in-out"
                    onClick={handleApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="font-Helvetica  py-3 border-y">
                <ul className="font-Helvetica  flex space-x-5 px-5 ">
                  <a
                    href="#section-1"
                    className="font-Helvetica  text-sm active text-slate-500 hover:underline hover:decoration-2 hover:underline-offset-[15px] focus:underline hover:text-legavacBlue font-[600] cursor-pointer hover:transitiona-all hover:ease-in hover:duration-500 hover:delay-25"
                  >
                    Hightlights
                  </a>
                  <a
                    href="#section-2"
                    className="font-Helvetica  text-sm text-slate-500 hover:underline hover:decoration-2 hover:underline-offset-[15px] focus:underline hover:text-legavacBlue font-[600] cursor-pointer hover:transitiona-all hover:ease-in hover:duration-500 hover:delay-25"
                  >
                    Job Description
                  </a>
                  <a
                    href="#section-3"
                    className="font-Helvetica  text-sm text-slate-500 hover:underline hover:decoration-2 hover:underline-offset-[15px] focus:underline hover:text-legavacBlue font-[600] cursor-pointer hover:transitiona-all hover:ease-in hover:duration-500 hover:delay-25"
                  >
                    More Information
                  </a>
                  {/* <a
                      href="#section-4"
                      className="font-Helvetica  text-sm text-slate-500 hover:underline hover:decoration-2 hover:underline-offset-[15px] focus:underline hover:text-legavacBlue font-[600] cursor-pointer hover:transitiona-all hover:ease-in hover:duration-500 hover:delay-25"
                    >
                      Recruiter Information
                    </a> */}
                </ul>
              </div>
            </div>
            <div className="font-Helvetica  py-5">
              <section id="section-1" className="font-Helvetica  p-5">
                <Highlights />
              </section>
              <section id="section-2" className="font-Helvetica  p-5">
                <JobDesc />
              </section>
              <section id="section-3" className="font-Helvetica  p-5">
                <MoreInfo />
              </section>
              {/* <section id="section-4" className="font-Helvetica  p-5">
                  <RecruiterInfo  companyName={job.companyName}/>
                </section> */}
            </div>
          </div>
          <div className="font-Helvetica  lg:block md:hidden hidden">
            <Advertisement />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewFullPage;
