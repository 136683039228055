import React from "react";
import { BiRupee } from "react-icons/bi";
import { MdOutlineHomeWork, MdOutlineLocationOn } from "react-icons/md";
import { VscBriefcase } from "react-icons/vsc";
import { IMAGES } from "../../../stores";

function Advertisement() {
  return (
    <>
      <div className="font-Helvetica  space-y-5">
       
          <img src={IMAGES.Advertisement} alt="" className="w-full"/>

        <div className="font-Helvetica  bg-white hover:bg-green-100 rounded border border-green-600 shadow shadow-gray-200 p-5">
          <p className="font-Helvetica  text-green-600 text-center text-xl font-semibold">
            We are Hiring
          </p>
          <div className="font-Helvetica  pt-5">
            <div className="font-Helvetica  pb-3">
              <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
                Business Analyst
              </p>
              <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                Jp Morgan
              </p>
            </div>
            <div className="font-Helvetica  space-y-2">
              <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineHomeWork
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Permanant,
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Hybrid
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineLocationOn
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Banglore
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    India
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <VscBriefcase
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    0 - 2 years
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <BiRupee size={20} className="font-Helvetica  text-slate-500" />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    10,00,000 - 20,00,000 LPA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="font-Helvetica  bg-white hover:bg-green-100 rounded border border-green-600 shadow shadow-gray-200 p-5">
          <p className="font-Helvetica  text-green-600 text-center text-xl font-semibold">
            We are Hiring
          </p>
          <div className="font-Helvetica  pt-5">
            <div className="font-Helvetica  pb-3">
              <p className="font-Helvetica  text-slate-800 text-xl font-semibold">
                Business Analyst
              </p>
              <p className="font-Helvetica  text-legavacBlue text-sm font-[600]">
                Jp Morgan
              </p>
            </div>
            <div className="font-Helvetica  space-y-2">
              <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineHomeWork
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Permanant,
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Hybrid
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <MdOutlineLocationOn
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    Banglore
                  </p>
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    India
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <VscBriefcase
                  size={20}
                  className="font-Helvetica  text-slate-500"
                />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    0 - 2 years
                  </p>
                </div>
              </div>
              <div className="font-Helvetica  flex space-x-2 items-center">
                <BiRupee size={20} className="font-Helvetica  text-slate-500" />
                <div className="font-Helvetica  flex space-x-1">
                  <p className="font-Helvetica  text-slate-500 text-xs">
                    10,00,000 - 20,00,000 LPA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="font-Helvetica  ">
          <img src={IMAGES.Advertisement} alt="" className="font-Helvetica  w-full h-60"/>
        </div>
        <div className="font-Helvetica  ">
          <img src={IMAGES.Advertisement} alt="" className="font-Helvetica  w-full h-60"/>
        </div> */}
      </div>
    </>
  );
}

export default Advertisement;
